import { createSlice } from '@reduxjs/toolkit';
import { isEmpty, without } from 'lodash';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import {
  LOCAL_STORAGE_CASH_BANK,
  LOCAL_STORAGE_CASH_BANK_ACCOUNT_TRANSACTION,
  LOCAL_STORAGE_CASH_BANK_DETAIL_ACCOUNT_TRANSACTION,
  LOCAL_STORAGE_CASH_TRANSFER,
  LOCAL_STORAGE_PAYMENT_CASH,
  LOCAL_STORAGE_RECEIPT_CASH,
} from '../../utils/constants/storage.constant';
import {
  NAME_STORE,
  SLICE_NAME_DETAIL_CASH_BANK_CASH_PAYMENT,
  SLICE_NAME_DETAIL_CASH_BANK_CASH_RECEIPT,
  SLICE_NAME_DETAIL_CASH_BANK_CASH_TRANSFER,
  SLICE_NAME_TEMP_UPDATE_CASH_PAYMENT,
  SLICE_NAME_TEMP_UPDATE_CASH_RECEIPT,
  SLICE_NAME_TEMP_UPDATE_CASH_TRANSFER,
  STATUS_REQUEST_ADD_CASH_TRANSFER_FAILED,
  STATUS_REQUEST_ADD_CASH_TRANSFER_PENDING,
  STATUS_REQUEST_ADD_CASH_TRANSFER_SUCCESS,
  STATUS_REQUEST_ADD_PAYMENT_CASH_FAILED,
  STATUS_REQUEST_ADD_PAYMENT_CASH_PENDING,
  STATUS_REQUEST_ADD_PAYMENT_CASH_SUCCESS,
  STATUS_REQUEST_ADD_RECEIPT_CASH_FAILED,
  STATUS_REQUEST_ADD_RECEIPT_CASH_PENDING,
  STATUS_REQUEST_ADD_RECEIPT_CASH_SUCCESS,
  STATUS_REQUEST_BASE_IDDLE,
  STATUS_REQUEST_CALCULATE_CASH_BANK_FAILED,
  STATUS_REQUEST_CALCULATE_CASH_BANK_PENDING,
  STATUS_REQUEST_CALCULATE_CASH_BANK_SUCCESS,
  STATUS_REQUEST_DELETE_CASH_TRANSFER_FAILED,
  STATUS_REQUEST_DELETE_CASH_TRANSFER_PENDING,
  STATUS_REQUEST_DELETE_CASH_TRANSFER_SUCCESS,
  STATUS_REQUEST_DELETE_PAYMENT_CASH_FAILED,
  STATUS_REQUEST_DELETE_PAYMENT_CASH_PENDING,
  STATUS_REQUEST_DELETE_PAYMENT_CASH_SUCCESS,
  STATUS_REQUEST_DELETE_RECEIPT_CASH_FAILED,
  STATUS_REQUEST_DELETE_RECEIPT_CASH_PENDING,
  STATUS_REQUEST_DELETE_RECEIPT_CASH_SUCCESS,
  STATUS_REQUEST_DETAIL_CASH_BANK_ACCOUNT_TRANSACTION_FAILED,
  STATUS_REQUEST_DETAIL_CASH_BANK_ACCOUNT_TRANSACTION_PENDING,
  STATUS_REQUEST_DETAIL_CASH_BANK_ACCOUNT_TRANSACTION_SUCCESS,
  STATUS_REQUEST_DETAIL_CASH_TRANSFER_FAILED,
  STATUS_REQUEST_DETAIL_CASH_TRANSFER_PENDING,
  STATUS_REQUEST_DETAIL_CASH_TRANSFER_SUCCESS,
  STATUS_REQUEST_DETAIL_PAYMENT_CASH_FAILED,
  STATUS_REQUEST_DETAIL_PAYMENT_CASH_PENDING,
  STATUS_REQUEST_DETAIL_PAYMENT_CASH_SUCCESS,
  STATUS_REQUEST_DETAIL_RECEIPT_CASH_FAILED,
  STATUS_REQUEST_DETAIL_RECEIPT_CASH_PENDING,
  STATUS_REQUEST_DETAIL_RECEIPT_CASH_SUCCESS,
  STATUS_REQUEST_LIST_CASH_BANK_ACCOUNT_TRANSACTION_FAILED,
  STATUS_REQUEST_LIST_CASH_BANK_ACCOUNT_TRANSACTION_PENDING,
  STATUS_REQUEST_LIST_CASH_BANK_ACCOUNT_TRANSACTION_SUCCESS,
  STATUS_REQUEST_LIST_CASH_BANK_FAILED,
  STATUS_REQUEST_LIST_CASH_BANK_PENDING,
  STATUS_REQUEST_LIST_CASH_BANK_SUCCESS,
  STATUS_REQUEST_LIST_CASH_TRANSFER_FAILED,
  STATUS_REQUEST_LIST_CASH_TRANSFER_PENDING,
  STATUS_REQUEST_LIST_CASH_TRANSFER_SUCCESS,
  STATUS_REQUEST_LIST_PAYMENT_CASH_FAILED,
  STATUS_REQUEST_LIST_PAYMENT_CASH_PENDING,
  STATUS_REQUEST_LIST_PAYMENT_CASH_SUCCESS,
  STATUS_REQUEST_LIST_RECEIPT_CASH_FAILED,
  STATUS_REQUEST_LIST_RECEIPT_CASH_PENDING,
  STATUS_REQUEST_LIST_RECEIPT_CASH_SUCCESS,
  STATUS_REQUEST_UPDATE_CASH_TRANSFER_FAILED,
  STATUS_REQUEST_UPDATE_CASH_TRANSFER_PENDING,
  STATUS_REQUEST_UPDATE_CASH_TRANSFER_SUCCESS,
  STATUS_REQUEST_UPDATE_PAYMENT_CASH_FAILED,
  STATUS_REQUEST_UPDATE_PAYMENT_CASH_PENDING,
  STATUS_REQUEST_UPDATE_PAYMENT_CASH_SUCCESS,
  STATUS_REQUEST_UPDATE_RECEIPT_CASH_FAILED,
  STATUS_REQUEST_UPDATE_RECEIPT_CASH_PENDING,
  STATUS_REQUEST_UPDATE_RECEIPT_CASH_SUCCESS,
} from '../../utils/constants/store.constant';
import { DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS } from '../../utils/default/params.default';
import formatHelp from '../../utils/helpers/format.helpers';
import objHelper from '../../utils/helpers/object.helper';
import { sliceReduceHelper } from '../../utils/helpers/slice-reducer.helper';

import {
  addCashPayment,
  addCashPaymentV2,
  addReceiptCash,
  addReceiptCashV2,
  addTransferCash,
  addTransferCashV2,
  deleteCashPayment,
  deleteCashTransfer,
  deleteReceiptCash,
  detailCashPayment,
  getCalculateCashBank,
  getDetailAccountTransaction,
  getDetailCashReceipt,
  getDetailCashTransfer,
  getListAccountTransaction,
  getListCashBank,
  getListCashBankRefresh,
  getListCashTransfer,
  getListCashTransferRefresh,
  getListPaymentCash,
  getListPaymentCashRefresh,
  getListReceiptCash,
  getListReceiptCashRefresh,
  updateCashPayment,
  updateCashTransfer,
  updateReceiptCash,
} from './cash-bank.action';

const initialState = {
  statusRequest: STATUS_REQUEST_BASE_IDDLE,
  cashBankData: LocalStorage.get(LOCAL_STORAGE_CASH_BANK),
  accountTransactionData: LocalStorage.get(LOCAL_STORAGE_CASH_BANK_ACCOUNT_TRANSACTION),
  accountDetailTransactionData: LocalStorage.get(
    LOCAL_STORAGE_CASH_BANK_DETAIL_ACCOUNT_TRANSACTION,
  ),
  receiptCashData: LocalStorage.get(LOCAL_STORAGE_RECEIPT_CASH),
  paymentCashData: LocalStorage.get(LOCAL_STORAGE_PAYMENT_CASH),
  cashTransferData: LocalStorage.get(LOCAL_STORAGE_CASH_TRANSFER),
  [SLICE_NAME_DETAIL_CASH_BANK_CASH_PAYMENT]: null,
  [SLICE_NAME_DETAIL_CASH_BANK_CASH_RECEIPT]: null,
  [SLICE_NAME_DETAIL_CASH_BANK_CASH_TRANSFER]: null,
  [SLICE_NAME_TEMP_UPDATE_CASH_TRANSFER]: null,
  [SLICE_NAME_TEMP_UPDATE_CASH_RECEIPT]: null,
  [SLICE_NAME_TEMP_UPDATE_CASH_PAYMENT]: null,
  cashBankCalculate: null,
  requestStatusParallel: [],
};

const cashBankSlice = createSlice({
  name: NAME_STORE.RECEIPT_CASH,
  initialState,
  reducers: {
    getCalculateCashBank,
    getListCashBank,
    getListCashBankRefresh,
    getListAccountTransaction,
    getDetailAccountTransaction,
    getListReceiptCash,
    getListReceiptCashRefresh,
    addReceiptCash,
    getDetailCashReceipt,
    deleteReceiptCash,
    getListPaymentCash,
    getListPaymentCashRefresh,
    detailCashPayment,
    updateReceiptCash,
    addCashPayment,
    updateCashPayment,
    deleteCashPayment,
    getListCashTransfer,
    getListCashTransferRefresh,
    getDetailCashTransfer,
    addTransferCash,
    updateCashTransfer,
    deleteCashTransfer,
    clearCashBankData: (state) => {
      LocalStorage.remove(LOCAL_STORAGE_CASH_BANK);

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        cashBankData: null,
      };
    },
    clearAccountTransactionData: (state) => {
      LocalStorage.remove(LOCAL_STORAGE_CASH_BANK_ACCOUNT_TRANSACTION);

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        cashBankData: null,
      };
    },
    clearDetailAccountTransactionData: (state) => {
      LocalStorage.remove(LOCAL_STORAGE_CASH_BANK_DETAIL_ACCOUNT_TRANSACTION);

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        cashBankData: null,
      };
    },
    clearReceiptCashData: (state) => {
      LocalStorage.remove(LOCAL_STORAGE_RECEIPT_CASH);

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        receiptCashData: null,
      };
    },
    clearDetailCashReceipt: (state) => {
      return {
        ...state,
        [SLICE_NAME_DETAIL_CASH_BANK_CASH_RECEIPT]: null,
      };
    },
    clearPaymentCashData: (state) => {
      LocalStorage.remove(LOCAL_STORAGE_PAYMENT_CASH);

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        paymentCashData: null,
      };
    },
    clearDetailCashPayment: (state) => {
      return {
        ...state,
        [SLICE_NAME_DETAIL_CASH_BANK_CASH_PAYMENT]: null,
      };
    },
    clearCashTransferData: (state) => {
      LocalStorage.remove(LOCAL_STORAGE_CASH_TRANSFER);

      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        cashTransferData: null,
      };
    },
    clearDetailDataCashTransfer: (state) => {
      return {
        ...state,
        statusRequest: STATUS_REQUEST_BASE_IDDLE,
        [SLICE_NAME_DETAIL_CASH_BANK_CASH_TRANSFER]: null,
      };
    },
    ...sliceReduceHelper.commonSliceReducers(initialState, {
      cashBankData: LOCAL_STORAGE_CASH_BANK,
      accountTransactionData: LOCAL_STORAGE_CASH_BANK_ACCOUNT_TRANSACTION,
      accountDetailTransactionData: LOCAL_STORAGE_CASH_BANK_DETAIL_ACCOUNT_TRANSACTION,
      receiptCashData: LOCAL_STORAGE_RECEIPT_CASH,
      paymentCashData: LOCAL_STORAGE_PAYMENT_CASH,
      cashTransferData: LOCAL_STORAGE_CASH_TRANSFER,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getListCashBank.fulfilled, (state, action) => {
      state.statusRequest = STATUS_REQUEST_LIST_CASH_BANK_SUCCESS;

      if (!isEmpty(action.payload.cashBankData)) {
        action.payload.cashBankData = objHelper.changeFormatValueV2(
          action.payload.cashBankData,
          DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS,
          null,
          formatHelp.changeFormatAccountName,
        );
      }

      state.cashBankData = action.payload.cashBankData;

      // handling request status parallel
      state.requestStatusParallel = without(
        state.requestStatusParallel,
        STATUS_REQUEST_LIST_CASH_BANK_PENDING,
        STATUS_REQUEST_LIST_CASH_BANK_SUCCESS,
      ).concat(STATUS_REQUEST_LIST_CASH_BANK_SUCCESS);
    }),
      builder.addCase(getListCashBank.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_CASH_BANK_PENDING;

        // handling request status parallel
        state.requestStatusParallel = without(
          state.requestStatusParallel,
          STATUS_REQUEST_LIST_CASH_BANK_PENDING,
        ).concat(STATUS_REQUEST_LIST_CASH_BANK_PENDING);
      }),
      builder.addCase(getListCashBank.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_CASH_BANK_FAILED;

        // handling request status parallel
        state.requestStatusParallel = state.requestStatusParallel = without(
          state.requestStatusParallel,
          STATUS_REQUEST_LIST_CASH_BANK_PENDING,
          STATUS_REQUEST_LIST_CASH_BANK_FAILED,
        ).concat(STATUS_REQUEST_LIST_CASH_BANK_FAILED);
      }),
      builder.addCase(getListCashBankRefresh.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_LIST_CASH_BANK_SUCCESS;

        if (!isEmpty(action.payload.cashBankData)) {
          action.payload.cashBankData = objHelper.changeFormatValueV2(
            action.payload.cashBankData,
            DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS,
            null,
            formatHelp.changeFormatAccountName,
          );
        }

        state.cashBankData = action.payload.cashBankData;

        // handling request status parallel
        state.requestStatusParallel = without(
          state.requestStatusParallel,
          STATUS_REQUEST_LIST_CASH_BANK_PENDING,
          STATUS_REQUEST_LIST_CASH_BANK_SUCCESS,
        ).concat(STATUS_REQUEST_LIST_CASH_BANK_SUCCESS);
      }),
      builder.addCase(getListCashBankRefresh.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_CASH_BANK_PENDING;

        // handling request status parallel
        state.requestStatusParallel = without(
          state.requestStatusParallel,
          STATUS_REQUEST_LIST_CASH_BANK_PENDING,
        ).concat(STATUS_REQUEST_LIST_CASH_BANK_PENDING);
      }),
      builder.addCase(getListCashBankRefresh.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_CASH_BANK_FAILED;

        // handling request status parallel
        state.requestStatusParallel = state.requestStatusParallel = without(
          state.requestStatusParallel,
          STATUS_REQUEST_LIST_CASH_BANK_PENDING,
          STATUS_REQUEST_LIST_CASH_BANK_FAILED,
        ).concat(STATUS_REQUEST_LIST_CASH_BANK_FAILED);
      }),
      builder.addCase(getListAccountTransaction.fulfilled, (state, action) => {
        state.accountTransactionData = action.payload.accountTransactionData;
        state.statusRequest = STATUS_REQUEST_LIST_CASH_BANK_ACCOUNT_TRANSACTION_SUCCESS;
      }),
      builder.addCase(getListAccountTransaction.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_CASH_BANK_ACCOUNT_TRANSACTION_PENDING;
      }),
      builder.addCase(getListAccountTransaction.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_CASH_BANK_ACCOUNT_TRANSACTION_FAILED;
      }),
      builder.addCase(getDetailAccountTransaction.fulfilled, (state, action) => {
        state.accountDetailTransactionData = action.payload.detailAccountTransactionData;
        state.statusRequest = STATUS_REQUEST_DETAIL_CASH_BANK_ACCOUNT_TRANSACTION_SUCCESS;
      }),
      builder.addCase(getDetailAccountTransaction.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_CASH_BANK_ACCOUNT_TRANSACTION_PENDING;
      }),
      builder.addCase(getDetailAccountTransaction.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_CASH_BANK_ACCOUNT_TRANSACTION_FAILED;
      }),
      builder.addCase(getListReceiptCash.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_LIST_RECEIPT_CASH_SUCCESS;

        if (!isEmpty(action.payload.receiptCashData)) {
          action.payload.receiptCashData = objHelper.changeFormatValueV2(
            action.payload.receiptCashData,
            DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS,
            null,
            formatHelp.changeFormatAccountName,
          );
        }

        state.receiptCashData = action.payload.receiptCashData;

        // handling request status parallel
        state.requestStatusParallel = without(
          state.requestStatusParallel,
          STATUS_REQUEST_LIST_RECEIPT_CASH_PENDING,
          STATUS_REQUEST_LIST_RECEIPT_CASH_SUCCESS,
        ).concat(STATUS_REQUEST_LIST_RECEIPT_CASH_SUCCESS);
      }),
      builder.addCase(getListReceiptCash.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_RECEIPT_CASH_PENDING;

        // handling request status parallel
        state.requestStatusParallel = without(
          state.requestStatusParallel,
          STATUS_REQUEST_LIST_RECEIPT_CASH_PENDING,
        ).concat(STATUS_REQUEST_LIST_RECEIPT_CASH_PENDING);
      }),
      builder.addCase(getListReceiptCash.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_RECEIPT_CASH_FAILED;
        state.receiptCashData = null;

        // handling request status parallel
        state.requestStatusParallel = without(
          state.requestStatusParallel,
          STATUS_REQUEST_LIST_RECEIPT_CASH_PENDING,
          STATUS_REQUEST_LIST_RECEIPT_CASH_SUCCESS,
          STATUS_REQUEST_LIST_RECEIPT_CASH_FAILED,
        ).concat(STATUS_REQUEST_LIST_RECEIPT_CASH_FAILED);
      }),
      builder.addCase(getListReceiptCashRefresh.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_LIST_RECEIPT_CASH_SUCCESS;

        if (!isEmpty(action.payload.receiptCashData)) {
          action.payload.receiptCashData = objHelper.changeFormatValueV2(
            action.payload.receiptCashData,
            DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS,
            null,
            formatHelp.changeFormatAccountName,
          );
        }

        state.receiptCashData = action.payload.receiptCashData;

        // handling request status parallel
        state.requestStatusParallel = without(
          state.requestStatusParallel,
          STATUS_REQUEST_LIST_RECEIPT_CASH_PENDING,
          STATUS_REQUEST_LIST_RECEIPT_CASH_SUCCESS,
        ).concat(STATUS_REQUEST_LIST_RECEIPT_CASH_SUCCESS);
      }),
      builder.addCase(getListReceiptCashRefresh.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_RECEIPT_CASH_PENDING;

        // handling request status parallel
        state.requestStatusParallel = without(
          state.requestStatusParallel,
          STATUS_REQUEST_LIST_RECEIPT_CASH_PENDING,
          STATUS_REQUEST_LIST_RECEIPT_CASH_SUCCESS,
          STATUS_REQUEST_LIST_RECEIPT_CASH_FAILED,
        ).concat(STATUS_REQUEST_LIST_RECEIPT_CASH_PENDING);
      }),
      builder.addCase(getListReceiptCashRefresh.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_RECEIPT_CASH_FAILED;
        state.receiptCashData = null;

        // handling request status parallel
        state.requestStatusParallel = without(
          state.requestStatusParallel,
          STATUS_REQUEST_LIST_RECEIPT_CASH_FAILED,
          STATUS_REQUEST_LIST_RECEIPT_CASH_PENDING,
        ).concat(STATUS_REQUEST_LIST_RECEIPT_CASH_FAILED);
      }),
      // detail cash receipt
      builder.addCase(getDetailCashReceipt.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_RECEIPT_CASH_SUCCESS;

        if (!isEmpty(action.payload[SLICE_NAME_DETAIL_CASH_BANK_CASH_RECEIPT])) {
          state[SLICE_NAME_DETAIL_CASH_BANK_CASH_RECEIPT] = objHelper.changeFormatValueV2(
            action.payload[SLICE_NAME_DETAIL_CASH_BANK_CASH_RECEIPT],
            DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS,
            null,
            formatHelp.changeFormatAccountName,
          );
        }
      }),
      builder.addCase(getDetailCashReceipt.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_RECEIPT_CASH_PENDING;
      }),
      builder.addCase(getDetailCashReceipt.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_RECEIPT_CASH_FAILED;
        state[SLICE_NAME_DETAIL_CASH_BANK_CASH_RECEIPT] = null;
      }),
      // add receipt cash
      builder.addCase(addReceiptCash.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_RECEIPT_CASH_SUCCESS;
      }),
      builder.addCase(addReceiptCash.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_RECEIPT_CASH_PENDING;
      }),
      builder.addCase(addReceiptCash.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_RECEIPT_CASH_FAILED;
      }),
      // add receipt cash version 2
      builder.addCase(addReceiptCashV2.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_RECEIPT_CASH_SUCCESS;
      }),
      builder.addCase(addReceiptCashV2.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_RECEIPT_CASH_PENDING;
      }),
      builder.addCase(addReceiptCashV2.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_RECEIPT_CASH_FAILED;
      }),
      // update cash receipt
      builder.addCase(updateReceiptCash.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_RECEIPT_CASH_SUCCESS;

        state[SLICE_NAME_TEMP_UPDATE_CASH_RECEIPT] =
          action.payload[SLICE_NAME_TEMP_UPDATE_CASH_RECEIPT];
      }),
      builder.addCase(updateReceiptCash.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_RECEIPT_CASH_PENDING;
      }),
      builder.addCase(updateReceiptCash.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_RECEIPT_CASH_FAILED;

        state[SLICE_NAME_TEMP_UPDATE_CASH_RECEIPT] = null;
      }),
      builder.addCase(deleteReceiptCash.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_RECEIPT_CASH_SUCCESS;
      }),
      builder.addCase(deleteReceiptCash.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_RECEIPT_CASH_PENDING;
      }),
      builder.addCase(deleteReceiptCash.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_RECEIPT_CASH_FAILED;
      }),
      builder.addCase(getListPaymentCash.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_LIST_PAYMENT_CASH_SUCCESS;

        if (!isEmpty(action.payload.paymentCashData)) {
          action.payload.paymentCashData = objHelper.changeFormatValueV2(
            action.payload.paymentCashData,
            DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS,
            null,
            formatHelp.changeFormatAccountName,
          );
        }

        // handling request status parallel
        state.requestStatusParallel = without(
          state.requestStatusParallel,
          STATUS_REQUEST_LIST_PAYMENT_CASH_PENDING,
          STATUS_REQUEST_LIST_PAYMENT_CASH_SUCCESS,
        ).concat(STATUS_REQUEST_LIST_PAYMENT_CASH_SUCCESS);

        state.paymentCashData = action.payload.paymentCashData;
      }),
      builder.addCase(getListPaymentCash.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_PAYMENT_CASH_PENDING;

        // handling request status parallel
        state.requestStatusParallel = without(
          state.requestStatusParallel,
          STATUS_REQUEST_LIST_PAYMENT_CASH_SUCCESS,
          STATUS_REQUEST_LIST_PAYMENT_CASH_PENDING,
          STATUS_REQUEST_LIST_PAYMENT_CASH_FAILED,
        ).concat(STATUS_REQUEST_LIST_PAYMENT_CASH_PENDING);
      }),
      builder.addCase(getListPaymentCash.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_PAYMENT_CASH_FAILED;
        state.paymentCashData = null;

        // handling request status parallel
        state.requestStatusParallel = without(
          state.requestStatusParallel,
          STATUS_REQUEST_LIST_PAYMENT_CASH_PENDING,
          STATUS_REQUEST_LIST_PAYMENT_CASH_FAILED,
        ).concat(STATUS_REQUEST_LIST_PAYMENT_CASH_FAILED);
      }),
      builder.addCase(getListPaymentCashRefresh.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_LIST_PAYMENT_CASH_SUCCESS;

        if (!isEmpty(action.payload.paymentCashData)) {
          action.payload.paymentCashData = objHelper.changeFormatValueV2(
            action.payload.paymentCashData,
            DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS,
            null,
            formatHelp.changeFormatAccountName,
          );
        }

        // handling request status parallel
        state.requestStatusParallel = without(
          state.requestStatusParallel,
          STATUS_REQUEST_LIST_PAYMENT_CASH_PENDING,
          STATUS_REQUEST_LIST_PAYMENT_CASH_SUCCESS,
        ).concat(STATUS_REQUEST_LIST_PAYMENT_CASH_SUCCESS);

        state.paymentCashData = action.payload.paymentCashData;
      }),
      builder.addCase(getListPaymentCashRefresh.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_PAYMENT_CASH_PENDING;

        // handling request status parallel
        state.requestStatusParallel = without(
          state.requestStatusParallel,
          STATUS_REQUEST_LIST_PAYMENT_CASH_SUCCESS,
          STATUS_REQUEST_LIST_PAYMENT_CASH_PENDING,
          STATUS_REQUEST_LIST_PAYMENT_CASH_FAILED,
        ).concat(STATUS_REQUEST_LIST_PAYMENT_CASH_PENDING);
      }),
      builder.addCase(getListPaymentCashRefresh.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_PAYMENT_CASH_FAILED;
        state.paymentCashData = null;

        // handling request status parallel
        state.requestStatusParallel = without(
          state.requestStatusParallel,
          STATUS_REQUEST_LIST_PAYMENT_CASH_PENDING,
          STATUS_REQUEST_LIST_PAYMENT_CASH_FAILED,
        ).concat(STATUS_REQUEST_LIST_PAYMENT_CASH_FAILED);
      }),
      builder.addCase(detailCashPayment.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_PAYMENT_CASH_SUCCESS;

        if (!isEmpty(action.payload[SLICE_NAME_DETAIL_CASH_BANK_CASH_PAYMENT])) {
          state[SLICE_NAME_DETAIL_CASH_BANK_CASH_PAYMENT] = objHelper.changeFormatValueV2(
            action.payload[SLICE_NAME_DETAIL_CASH_BANK_CASH_PAYMENT],
            DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS,
            null,
            formatHelp.changeFormatAccountName,
          );
        }
      }),
      builder.addCase(detailCashPayment.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_PAYMENT_CASH_PENDING;
      }),
      builder.addCase(detailCashPayment.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_PAYMENT_CASH_FAILED;
        state[SLICE_NAME_DETAIL_CASH_BANK_CASH_PAYMENT] = null;
      }),
      builder.addCase(addCashPayment.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_PAYMENT_CASH_SUCCESS;
      }),
      builder.addCase(addCashPayment.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_PAYMENT_CASH_PENDING;
      }),
      builder.addCase(addCashPayment.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_PAYMENT_CASH_FAILED;
      }),
      // add cash payment version 2
      builder.addCase(addCashPaymentV2.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_PAYMENT_CASH_SUCCESS;
      }),
      builder.addCase(addCashPaymentV2.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_PAYMENT_CASH_PENDING;
      }),
      builder.addCase(addCashPaymentV2.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_PAYMENT_CASH_FAILED;
      }),
      // update cash payment
      builder.addCase(updateCashPayment.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_PAYMENT_CASH_SUCCESS;

        state[SLICE_NAME_TEMP_UPDATE_CASH_PAYMENT] =
          action.payload[SLICE_NAME_TEMP_UPDATE_CASH_PAYMENT];
      }),
      builder.addCase(updateCashPayment.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_PAYMENT_CASH_PENDING;
      }),
      builder.addCase(updateCashPayment.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_PAYMENT_CASH_FAILED;

        state[SLICE_NAME_TEMP_UPDATE_CASH_PAYMENT] = null;
      }),
      builder.addCase(deleteCashPayment.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_PAYMENT_CASH_SUCCESS;
      }),
      builder.addCase(deleteCashPayment.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_PAYMENT_CASH_PENDING;
      }),
      builder.addCase(deleteCashPayment.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_PAYMENT_CASH_FAILED;
      }),
      builder.addCase(getListCashTransfer.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_LIST_CASH_TRANSFER_SUCCESS;

        if (!isEmpty(action.payload.cashTransferData)) {
          action.payload.cashTransferData = objHelper.changeFormatValueV2(
            action.payload.cashTransferData,
            DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS,
            null,
            formatHelp.changeFormatAccountName,
          );
        }

        // handling request status parallel
        state.requestStatusParallel = without(
          state.requestStatusParallel,
          STATUS_REQUEST_LIST_CASH_TRANSFER_PENDING,
          STATUS_REQUEST_LIST_CASH_TRANSFER_SUCCESS,
        ).concat(STATUS_REQUEST_LIST_CASH_TRANSFER_SUCCESS);

        state.cashTransferData = action.payload.cashTransferData;
      }),
      builder.addCase(getListCashTransfer.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_LIST_CASH_TRANSFER_PENDING;

        // handling request status parallel
        state.requestStatusParallel = without(
          state.requestStatusParallel,
          STATUS_REQUEST_LIST_CASH_TRANSFER_SUCCESS,
          STATUS_REQUEST_LIST_CASH_TRANSFER_PENDING,
          STATUS_REQUEST_LIST_CASH_TRANSFER_FAILED,
        ).concat(STATUS_REQUEST_LIST_CASH_TRANSFER_PENDING);
      }),
      builder.addCase(getListCashTransfer.rejected, (state) => {
        state.cashTransferData = null;
        state.statusRequest = STATUS_REQUEST_LIST_CASH_TRANSFER_FAILED;

        // handling request status parallel
        state.requestStatusParallel = without(
          state.requestStatusParallel,
          STATUS_REQUEST_LIST_CASH_TRANSFER_PENDING,
          STATUS_REQUEST_LIST_CASH_TRANSFER_FAILED,
        ).concat(STATUS_REQUEST_LIST_CASH_TRANSFER_FAILED);
      }),
      builder.addCase(getListCashTransferRefresh.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_LIST_CASH_TRANSFER_SUCCESS;

        if (!isEmpty(action.payload.cashTransferData)) {
          action.payload.cashTransferData = objHelper.changeFormatValueV2(
            action.payload.cashTransferData,
            DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS,
            null,
            formatHelp.changeFormatAccountName,
          );
        }

        // handling request status parallel
        state.requestStatusParallel = without(
          state.requestStatusParallel,
          STATUS_REQUEST_LIST_CASH_TRANSFER_PENDING,
          STATUS_REQUEST_LIST_CASH_TRANSFER_SUCCESS,
        ).concat(STATUS_REQUEST_LIST_CASH_TRANSFER_SUCCESS);

        state.cashTransferData = action.payload.cashTransferData;
      }),
      builder.addCase(getListCashTransferRefresh.pending, (state) => {
        // handling request status parallel
        state.requestStatusParallel = without(
          state.requestStatusParallel,
          STATUS_REQUEST_LIST_CASH_TRANSFER_SUCCESS,
          STATUS_REQUEST_LIST_CASH_TRANSFER_PENDING,
          STATUS_REQUEST_LIST_CASH_TRANSFER_FAILED,
        ).concat(STATUS_REQUEST_LIST_CASH_TRANSFER_PENDING);

        state.statusRequest = STATUS_REQUEST_LIST_CASH_TRANSFER_PENDING;
      }),
      builder.addCase(getListCashTransferRefresh.rejected, (state) => {
        // handling request status parallel
        state.requestStatusParallel = without(
          state.requestStatusParallel,
          STATUS_REQUEST_LIST_CASH_TRANSFER_PENDING,
          STATUS_REQUEST_LIST_CASH_TRANSFER_FAILED,
        ).concat(STATUS_REQUEST_LIST_CASH_TRANSFER_FAILED);

        state.statusRequest = STATUS_REQUEST_LIST_CASH_TRANSFER_FAILED;
      }),
      // detail cash transfer
      builder.addCase(getDetailCashTransfer.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_CASH_TRANSFER_SUCCESS;

        if (!isEmpty(action.payload[SLICE_NAME_DETAIL_CASH_BANK_CASH_TRANSFER])) {
          state[SLICE_NAME_DETAIL_CASH_BANK_CASH_TRANSFER] = objHelper.changeFormatValueV2(
            action.payload[SLICE_NAME_DETAIL_CASH_BANK_CASH_TRANSFER],
            DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS,
            null,
            formatHelp.changeFormatAccountName,
          );
        }
      }),
      builder.addCase(getDetailCashTransfer.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_CASH_TRANSFER_PENDING;
      }),
      builder.addCase(getDetailCashTransfer.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_DETAIL_CASH_TRANSFER_FAILED;
        state[SLICE_NAME_DETAIL_CASH_BANK_CASH_TRANSFER] = null;
      }),
      // add cash transfer
      builder.addCase(addTransferCash.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_CASH_TRANSFER_SUCCESS;
      }),
      builder.addCase(addTransferCash.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_CASH_TRANSFER_PENDING;
      }),
      builder.addCase(addTransferCash.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_CASH_TRANSFER_FAILED;
      }),
      // add cash transfer version 2
      builder.addCase(addTransferCashV2.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_CASH_TRANSFER_SUCCESS;
      }),
      builder.addCase(addTransferCashV2.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_CASH_TRANSFER_PENDING;
      }),
      builder.addCase(addTransferCashV2.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_ADD_CASH_TRANSFER_FAILED;
      }),
      builder.addCase(deleteCashTransfer.fulfilled, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_CASH_TRANSFER_SUCCESS;
      }),
      builder.addCase(deleteCashTransfer.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_CASH_TRANSFER_PENDING;
      }),
      builder.addCase(deleteCashTransfer.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_DELETE_CASH_TRANSFER_FAILED;
      }),
      // update cash transfer
      builder.addCase(updateCashTransfer.fulfilled, (state, action) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_CASH_TRANSFER_SUCCESS;

        state[SLICE_NAME_TEMP_UPDATE_CASH_TRANSFER] =
          action.payload[SLICE_NAME_TEMP_UPDATE_CASH_TRANSFER];
      }),
      builder.addCase(updateCashTransfer.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_CASH_TRANSFER_PENDING;
      }),
      builder.addCase(updateCashTransfer.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_UPDATE_CASH_TRANSFER_FAILED;
        state[SLICE_NAME_TEMP_UPDATE_CASH_TRANSFER] = null;
      }),
      // calculate cash bank
      builder.addCase(getCalculateCashBank.fulfilled, (state, action) => {
        state.cashBankCalculate = action.payload.cashBankCalculate;
        state.statusRequest = STATUS_REQUEST_CALCULATE_CASH_BANK_SUCCESS;

        state.requestStatusParallel = without(
          state.requestStatusParallel,
          STATUS_REQUEST_CALCULATE_CASH_BANK_PENDING,
          STATUS_REQUEST_CALCULATE_CASH_BANK_SUCCESS,
        ).concat(STATUS_REQUEST_CALCULATE_CASH_BANK_SUCCESS);
      }),
      builder.addCase(getCalculateCashBank.pending, (state) => {
        state.statusRequest = STATUS_REQUEST_CALCULATE_CASH_BANK_PENDING;

        state.requestStatusParallel = without(
          state.requestStatusParallel,
          STATUS_REQUEST_CALCULATE_CASH_BANK_PENDING,
        ).concat(STATUS_REQUEST_CALCULATE_CASH_BANK_PENDING);
      }),
      builder.addCase(getCalculateCashBank.rejected, (state) => {
        state.statusRequest = STATUS_REQUEST_CALCULATE_CASH_BANK_FAILED;

        state.requestStatusParallel = without(
          state.requestStatusParallel,
          STATUS_REQUEST_CALCULATE_CASH_BANK_PENDING,
          STATUS_REQUEST_CALCULATE_CASH_BANK_FAILED,
        ).concat(STATUS_REQUEST_CALCULATE_CASH_BANK_FAILED);
      });
  },
});

export const { actions, reducer } = cashBankSlice;

export const {
  clearCashBankData,
  clearAccountTransactionData,
  clearDetailAccountTransactionData,
  clearReceiptCashData,
  clearDetailCashReceipt,
  clearPaymentCashData,
  clearDetailCashPayment,
  clearCashTransferData,
  clearDetailDataCashTransfer,
} = actions;

export default reducer;
