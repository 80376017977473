export const DEFAULT_KEY_NAME_CONTAINER_COMBINE_RESPONSE = 'containerCombineResponse';
export const DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL = 'requestStatusParallel';
export const DEFAULT_KEY_NAME_STATUS_REQUEST = 'statusRequest';
export const DEFAULT_KEY_NAME_REQUEST_STATUS = 'requestStatus';
export const DEFAULT_KEY_NAME_ROUTER_PARAMS = 'params';
export const DEFAULT_KEY_NAME_PRODUCT_SALES_QUOTATION_DETAILS = 'sales_quotation_product';
export const DEFAULT_KEY_NAME_PRODUCT_SALES_ORDER_DETAILS = 'sales_order_product';
export const DEFAULT_KEY_NAME_SALES_INVOICE_PAYMENT_DETAILS = 'sales_invoice_payments';
export const DEFAULT_KEY_NAME_PURCHASE_INVOICE_PAYMENT_API_REF = 'purchase_invoice_payment';
export const DEFAULT_KEY_NAME_PRODUCT_SALES_DELIVEY_DETAILS = 'product';
export const DEFAULT_KEY_NAME_UPDATED_BY_NAME_API = 'updated_by_name';
export const DEFAULT_KEY_NAME_UPDATED_AT_API = 'updated_at';
export const DEFAULT_KEY_NAME_PARAMS_URL_SALES_QUOTATION_ID = 'salesQuotationID';
export const DEFAULT_KEY_NAME_PARAMS_URL_SALES_ORDER_ID = 'salesOrderID';
export const DEFAULT_KEY_NAME_PARAMS_URL_SALES_DELIVERY_ID = 'salesDeliveryID';
export const DEFAULT_KEY_NAME_PARAMS_URL_SALES_INVOICE_ID = 'salesInvoiceID';
export const DEFAULT_KEY_NAME_PARAMS_URL_SALES_PAYMENT_ID = 'salesPaymentID';
export const DEFAULT_KEY_NAME_PARAMS_URL_SALES_DELIVERY_HANDOVER_ID = 'salesDeliveryHandoverID';
export const DEFAULT_KEY_NAME_PARAMS_URL_TRANSACTION_ID = 'transactionID';
export const DEFAULT_KEY_NAME_PRODUCT_API = 'product';
export const DEFAULT_KEY_NAME_PRODUCT_SALES_HANDOVER_DETAILS = 'sales_handover_product';
export const DEFAULT_KEY_NAME_PRODUCT_PURCHASE_HANDOVER_DETAILS_API_REF =
  'purchase_handover_product';

export const DEFAULT_KEY_NAME_QUOTATION_REQUEST_PRODUCT_REF_API = 'quotation_request_product';
export const DEFAULT_KEY_NAME_PURCHASE_ORDER_PRODUCT_REF_API = 'purchase_order_product';

export const DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_SALES_QUOTATION_DATA =
  'selectedSalesQuotationData';
export const DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_SALES_ORDER_DATA = 'selectedSalesOrderData';
export const DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_SALES_DELIVERY_DATA =
  'selectedSalesDeliveryData';
export const DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_SALES_INVOICE_DATA =
  'selectedSalesInvoiceData';
export const DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_SALES_PAYMENT_DATA =
  'selectedSalesPaymentData';

export const DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_QUOTATION_ID = 'quotationRequestID';
export const DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_ORDER_ID = 'purchaseOrderID';
export const DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_DELIVERY_ID = 'purchaseDeliveryID';
export const DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_INVOICE_ID = 'purchaseInvoiceID';
export const DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_PAYMENT_ID = 'purchasePaymentID';
export const DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_DELIVERY_HANDOVER_ID =
  'purchaseDeliveryHandoverID';

export const DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_QUOTATION_REQUEST_DATA =
  'selectedQuotationRequestData';
export const DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_PURCHASE_ORDER_DATA =
  'selectedPurchaseOrderData';
export const DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_PURCHASE_DELIVERY_DATA =
  'selectedPurchaseDeliveryData';
export const DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_PURCHASE_INVOICE_DATA =
  'selectedPurchaseInvoiceData';
export const DEFAULT_KEY_NAME_SEARCH_PARAMS_SELECTED_PURCHASE_PAYMENT_DATA =
  'selectedPurchasePaymentData';

export const DEFAULT_KEY_NAME_PARAMS_URL_EXPENSE_ID = 'expenseID';

// getting reference from API
export const DEFAULT_KEY_NAME_STATUS_REF_API = 'status';
export const DEFAULT_KEY_NAME_AMOUNT_REF_API = 'amount';
export const DEFAULT_KEY_NAME_AMOUNT_CONST = 'amount_const';
export const DEFAULT_KEY_NAME_TRANSACTION_DATE_API_REF = 'transaction_date';
export const DEFAULT_KEY_NAME_EXPIRED_DATE_API_REF = 'expired_date';
export const DEFAULT_KEY_NAME_TRANSACTION_NO_API_REF = 'transaction_no';
export const DEFAULT_KEY_NAME_SALES_AGENT_NAME_API_REF = 'sales_agent_name';
export const DEFAULT_KEY_NAME_CUSTOMER_API_REF = 'customer';
export const DEFAULT_KEY_NAME_CUSTOMER_ADDRESS_API_REF = 'customer_address';
export const DEFAULT_KEY_NAME_SUPPLIER_API_REF = 'supplier';
export const DEFAULT_KEY_NAME_SUPPLIER_NAME_API_REF = 'supplier_name';
export const DEFAULT_KEY_NAME_SUPPLIER_ADDRESS_API_REF = 'supplier_address';
export const DEFAULT_KEY_NAME_DESCRIPTION_API_REF = 'description';
export const DEFAULT_KEY_NAME_TYPE_API_REF = 'type';

export const DEFAULT_KEY_NAME_SALES_QUOTATION_ID_API_REF = 'sales_quotation_id';
export const DEFAULT_KEY_NAME_SALES_QUOTATION_ID_CONTAINER = 'sales_quotation_id_container';

export const DEFAULT_KEY_NAME_SALES_ORDER_ID_API_REF = 'sales_order_id';
export const DEFAULT_KEY_NAME_SALES_DELIVERY_ID_API_REF = 'sales_delivery_id';
export const DEFAULT_KEY_NAME_SALES_INVOICE_ID_API_REF = 'sales_invoice_id';
export const DEFAULT_KEY_NAME_SALES_PAYMENT_ID_API_REF = 'sales_payment_id';
export const DEFAULT_KEY_NAME_TRANSACTION_ID_API_REF = 'transaction_id';
export const DEFAULT_KEY_NAME_JOURNAL_ENTRY_LIST_API_REF = 'journal_entry';
export const DEFAULT_KEY_NAME_QUOTATION_REQUEST_ID_API_REF = 'quotation_request_id';
export const DEFAULT_KEY_NAME_QUOTATION_REQUEST_ID_CONTAINER = 'quotation_request_id_container';
export const DEFAULT_KEY_NAME_PURCHASE_INVOICE_ID_API_REF = 'purchase_invoice_id';
export const DEFAULT_KEY_NAME_PURCHASE_INVOICE_ID_CONTAINER = 'purchase_invoice_id_container';
export const DEFAULT_KEY_NAME_PURCHASE_DELIVERY_ID_API_REF = 'purchase_delivery_id';
export const DEFAULT_KEY_NAME_PURCHASE_ORDER_ID_API_REF = 'purchase_order_id';
export const DEFAULT_KEY_NAME_PURCHASE_ORDER_ID_CONTAINER = 'purchase_order_id_container';
export const DEFAULT_KEY_NAME_PURCHASE_PAYMENT_ID_API_REF = 'purchase_payment_id';

export const DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF = 'accounting_periode';
export const DEFAULT_KEY_NAME_TRANSACTION_DATA = 'transactionData';
export const DEFAULT_KEY_NAME_TRANSACTION_VALIDATION = 'transactionValidation';
export const DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_DATA = 'childrenTransactionData';
export const DEFAULT_KEY_NAME_CHILDREN_TRANSACTION_VALIDATION = 'childrenTransactionValidation';
export const DEFAULT_KEY_NAME_IS_NEW_CONTACT_DATA = 'isNewContactData';

export const DEFAULT_KEY_NAME_REFERENCE_NUMBER = 'reference_number';
export const DEFAULT_KEY_NAME_REFERENCE_NUMBER_CONTAINER = 'reference_number_container';
export const DEFAULT_KEY_NAME_CONTACT_ID_API_REF = 'contact_id';
export const DEFAULT_KEY_NAME_CONTACT_ID_CONTAINER_API_REF = 'contact_id_container';
export const DEFAULT_KEY_NAME_CUSTOMER_NAME_API_REF = 'customer_name';
export const DEFAULT_KEY_NAME_CUSTOMER_ID_API_REF = 'customer_id';
export const DEFAULT_KEY_NAME_CUSTOMER_CONTAINER_ID_API_REF = 'customer_id_container';
export const DEFAULT_KEY_NAME_SUPPLIER_ID_API_REF = 'supplier_id';
export const DEFAULT_KEY_NAME_SUPPLIER_CONTAINER_ID_API_REF = 'supplier_id_container';

export const DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_API_REF = 'discount_account_id';
export const DEFAULT_KEY_NAME_DISCOUNT_ACCOUNT_ID_CONTAINER = 'discount_account_id_container';

export const DEFAULT_KEY_NAME_DOWN_PAYMENT_ACCOUNT_ID_API_REF = 'down_payment_account_id';
export const DEFAULT_KEY_NAME_DOWN_PAYMENT_ACCOUNT_ID_CONTAINER =
  'down_payment_account_id_container';

export const DEFAULT_KEY_NAME_EXPENSE_ACCOUNT_ID_API_REF = 'expense_account_id';
export const DEFAULT_KEY_NAME_EXPENSE_ACCOUNT_ID_CONTAINER = 'expense_account_id_container';
export const DEFAULT_KEY_NAME_EXPENSE_VALUE = 'expense_value';

export const DEFAULT_KEY_NAME_DOWN_PAYMENT_VALUE = 'down_payment_value';

export const DEFAULT_KEY_NAME_IS_PAID_OFF_API_REF = 'is_paid_off';
export const DEFAULT_KEY_NAME_IS_PAID_OF_MINUTES_HANDOVER_API_REF = 'is_paid_off_minutes_handover';
export const DEFAULT_KEY_NAME_IS_SENT_API_REF = 'is_sent';

export const DEFAULT_KEY_NAME_ACCOUNTING_PERIOD = 'accounting_period';
export const DEFAULT_KEY_NAME_ACCOUNTING_PERIODE = 'accounting_periode';

export const DEFAULT_KEY_NAME_DISCOUNT_TYPE_API_REF = 'discount_type';
export const DEFAULT_KEY_NAME_DISCOUNT_PERCENTAGE_API_REF = 'discount_percentage';
export const DEFAULT_KEY_NAME_DISCOUNT_NOMINAL_API_REF = 'discount_nominal';
export const DEFAULT_KEY_NAME_SALES_HANDOVER_ID_API_REF = 'sales_handover_id';
export const DEFAULT_KEY_NAME_PURCHASE_HANDOVER_ID_API_REF = 'purchase_handover_id';

export const DEFAULT_KEY_NAME_RESPONSE_DATA_API_REF = 'data';
export const DEFAULT_KEY_NAME_SUB_RESPONSE_DATA_API_REF = 'data';

export const DEFAULT_KEY_NAME_SUCESS_API_REF = 'sucess';

export const DEFAULT_KEY_NAME_ACCOUNT_ID_API_REF = 'account_id';
export const DEFAULT_KEY_NAME_ACCOUNT_ID_CONTAINER = 'account_id_container';
export const DEFAULT_KEY_NAME_REFERENCE_NUMBER_ID_API_REF = 'reference_number_id';

export const DEFAULT_KEY_NAME_SALES_PAYMENT_NO_API_REF = 'sales_payment_no';
export const DEFAULT_KEY_NAME_SALES_PAYMENT_NO_CONTAINER = 'sales_payment_no_container';
export const DEFAULT_KEY_NAME_SALES_INVOICE_NO_API_REF = 'sales_invoice_no';

export const DEFAULT_KEY_NAME_PURCHASE_PAYMENT_NO_API_REF = 'purchase_payment_no';
export const DEFAULT_KEY_NAME_PURCHASE_PAYMENT_NO_CONTAINER = 'purchase_payment_no_container';
export const DEFAULT_KEY_NAME_PURCHASE_INVOICE_NO_API_REF = 'purchase_invoice_no';
export const DEFAULT_KEY_NAME_PURCHASE_INVOICE_NO_API_REF_CONTAINER =
  'purchase_invoice_no_container';

export const DEFAULT_KEY_NAME_WAREHOUSE_ID_API_REF = 'warehouse_id';
export const DEFAULT_KEY_NAME_WAREHOUSE_ID_CONTAINER = 'warehouse_id_container';
export const DEFAULT_KEY_NAME_WAREHOUSE_NAME_API_REF = 'warehouse_name';
export const DEFAULT_KEY_NAME_WAREHOUSE_TYPE_API_REF = 'warehouse_type';

export const DEFAULT_KEY_NAME_PRODUCT_ID_API_REF = 'product_id';
export const DEFAULT_KEY_NAME_PRODUCT_ID_CONTAINER = 'product_id_container';

export const DEFAULT_KEY_NAME_PARAMS_URL_PRODUCT_ID = 'productID';

export const DEFAULT_KEY_NAME_PRODUCT_NAME_API_REF = 'product_name';
export const DEFAULT_KEY_NAME_PRODUCT_UNIT_API_REF = 'product_unit';
export const DEFAULT_KEY_NAME_UNIT_API_REF = 'unit';

export const DEFAULT_KEY_NAME_PRICE_API_REF = 'price';
export const DEFAULT_KEY_NAME_QUANTITY_API_REF = 'quantity';

export const DEFAULT_KEY_NAME_IS_FOR_SALE_API_REF = 'is_for_sale';
export const DEFAULT_KEY_NAME_IS_PURCHASED_API_REF = 'is_purchased';

export const DEFAULT_KEY_NAME_SALES_DELIVERY_NO_API_REF = 'sales_delivery_no';

export const DEFAULT_KEY_NAME_ON_BEHALF_API_REF = 'on_behalf';

export const DEFAULT_KEY_NAME_SALES_ORDER_ID_CONTAINER = 'sales_order_id_container';
export const DEFAULT_KEY_NAME_SALES_ORDER_NO_API_REF = 'sales_order_no';

export const DEFAULT_KEY_NAME_DELIVERY_DATE_API_REF = 'delivery_date';

export const DEFAULT_KEY_NAME_CONTACT_CODE_API_REF = 'contact_code';
export const DEFAULT_KEY_NAME_CONTACT_NAME_API_REF = 'contact_name';

export const DEFAULT_KEY_NAME_SALES_AGENT_ID_API_REF = 'sales_agent_id';
export const DEFAULT_KEY_NAME_SALES_AGENT_ID_CONTAINER = 'sales_agent_id_container';

export const DEFAULT_KEY_NAME_SALES_ORDER_PRODUCT_API_REF = 'sales_order_product';

export const DEFAULT_KEY_NAME_MODUL_ID = 'modul_id';
export const DEFAULT_KEY_NAME_MODUL_ID_API_REF = 'modul_id';
export const DEFAULT_KEY_NAME_MODUL_ID_CONTAINER = 'modul_id_container';
export const DEFAULT_KEY_NAME_MODUL_TYPE_API_REF = 'modul_type';

export const DEFAULT_KEY_NAME_AMOUNT_INPUT = 'amount_input';
export const DEFAULT_KEY_NAME_CATEGORY_NAME_API_REF = 'category_name';
export const DEFAULT_KEY_NAME_ACCOUNT_NAME_API_REF = 'account_name';

export const DEFAULT_KEY_NAME_PURCHASE_ORDER_NO_API_REF = 'purchase_order_no';
export const DEFAULT_KEY_NAME_PURCHASE_DELIVERY_NO_API_REF = 'purchase_delivery_no';

export const DEFAULT_KEY_NAME_QUANTITY_REAL_API_REF = 'quantity_real';
export const DEFAULT_KEY_NAME_QUANTITY_WRITTEN_API_REF = 'quantity_written';
export const DEFAULT_KEY_NAME_QUANTITY_CONST = 'quantity_const';
export const DEFAULT_KEY_NAME_QUANTITY_AVAILABLE_CONST = 'quantity_available_const';

export const DEFAULT_KEY_NAME_AMOUNT_AVAILABLE_API_REF = 'amount_available';
export const DEFAULT_KEY_NAME_AMOUNT_AVAILABLE_CONST = 'amount_available_const';

export const DEFAULT_KEY_NAME_MENU_PARENT = 'menuParent';

export const DEFAULT_KEY_NAME_IS_STOCK_MONITORED_API_REF = 'is_stock_monitored';

export const DEFAULT_KEY_NAME_EXPENSE_ID_API_REF = 'expense_id';
export const DEFAULT_KEY_NAME_EXPENSE_DATA_API = 'expense_data';
export const DEFAULT_KEY_NAME_ORIGIN_ACCOUNT_ID_API_REF = 'account_origin_id';
export const DEFAULT_KEY_NAME_ORIGIN_ACCOUNT_ID_CONTAINER = 'account_origin_id_container';
export const DEFAULT_KEY_NAME_DESTINATION_ACCOUNT_ID_API_REF = 'account_destination_id';
export const DEFAULT_KEY_NAME_DESTINATION_ACCOUNT_ID_CONTAINER = 'account_destination_id_container';
export const DEFAULT_KEY_NAME_TYPE_MODULE_ID_API_REF = 'type_module_id';
export const DEFAULT_KEY_NAME_TYPE_MODULE_ID_CONTAINER = 'type_module_id_container';

export const DEFAULT_KEY_NAME_ASSET_ID_API_REF = 'asset_id';
export const DEFAULT_KEY_NAME_CLOSING_BOOK_ID_API_REF = 'closing_book_id';
export const DEFAULT_KEY_NAME_JOURNAL_ENTRY_ID_API_REF = 'journal_entry_id';
export const DEFAULT_KEY_NAME_PAYMENT_CASH_ID_API_REF = 'payment_cash_id';
export const DEFAULT_KEY_NAME_RECEIPT_CASH_ID_API_REF = 'receipt_cash_id';
export const DEFAULT_KEY_NAME_TRANSFER_CASH_ID_API_REF = 'transfer_cash_id';
export const DEFAULT_KEY_NAME_STOCK_ADJUSTMENT_ID_API_REF = 'stock_adjustment_id';

export const DEFAULT_KEY_NAME_TRANSACTION_TYPE_API_REF = 'transaction_type';

export const DEFAULT_KEY_NAME_NEXT_PAGE_URL_API_REF = 'next_page_url';
export const DEFAULT_KEY_NAME_PREV_PAGE_URL_API_REF = 'prev_page_url';
export const DEFAULT_KEY_NAME_CURRENT_PAGE_API_REF = 'current_page';
export const DEFAULT_KEY_NAME_PER_PAGE_API_REF = 'per_page';

export const DEFAULT_KEY_NAME_FROM_DATE_API_REF = 'from_date';
export const DEFAULT_KEY_NAME_TO_DATE_API_REF = 'to_date';

export const DEFAULT_KEY_NAME_HISTORY_PATHNAME_PREVIOUS = 'historyPathnamePrevious';

export const DEFAULT_KEY_NAME_ORIGIN_SUB_PATHNAME = 'originSubPathname';
export const DEFAULT_KEY_NAME_HISTORY_PATHNAME = 'historyPathname';
export const DEFAULT_KEY_NAME_MENU_NAME_TRANSLATION = 'menuNameTranslation';

export const DEFAULT_KEY_NAME_INVOICE_PAYMENT_API_REF = 'invoice_payment';

export const DEFAULT_KEY_NAME_NORMAL_BALANCE_API_REF = 'normal_balance';
export const DEFAULT_KEY_NAME_ACCOUNT_NORMAL_BALANCE_API_REF = 'account_normal_balance';

export const DEFAULT_KEY_NAME_DEFAULT_CODE_API_REF = 'default_code';

export const DEFAULT_KEY_NAME_MUTATION_QUANTITY_API_REF = 'mutation_quantity';
export const DEFAULT_KEY_NAME_MUTATION_DATE_API_REF = 'mutation_date';

export const DEFAULT_KEY_NAME_MODULE_DESCRIPTION_API_REF = 'module_description';

export const DEFAULT_KEY_NAME_PAGE_STATUS = 'pageStatus';
export const DEFAULT_KEY_NAME_PREV_PAGE_STATUS = 'prevPageStatus';

export const DEFAULT_KEY_NAME_SELECTED_CONTACT_DATA = 'selectedContactData';
export const DEFAULT_KEY_NAME_SELECTED_DATA_DETAILS = 'selectedDataDetails';

export const DEFAULT_KEY_NAME_PARAMS_URL_ACCOUNT_ID = 'accountID';

export const DEFAULT_KEY_NAME_SELECTED_TAB = 'selectedTab';
export const DEFAULT_KEY_NAME_STATUS_PAGE = 'statusPage';

export const DEFAULT_KEY_NAME_SELECTED_CASH_TRANSFER_DATA = 'selectedCashTransferData';
export const DEFAULT_KEY_NAME_SELECTED_CASH_RECEIPT_DATA = 'selectedCashReceiptData';
export const DEFAULT_KEY_NAME_SELECTED_CASH_PAYMENT_DATA = 'selectedCashPaymentData';
export const DEFAULT_KEY_NAME_SELECTED_CASH_BANK_INDEX_DATA = 'selectedCashBankIndexData';

export const DEFAULT_KEY_NAME_SELECTED_PRODUCT_DATA = 'selectedProductData';
export const DEFAULT_KEY_NAME_SELECTED_STOCK_ADJUSTMENT_DATA = 'selectedStockAdjusmentData';
export const DEFAULT_KEY_NAME_SELECTED_WAREHOUSE_TRANSFER_DATA = 'selectedWarehouseTransferData';

export const DEFAULT_KEY_NAME_DATA_PRODUCT = 'dataProduct';
export const DEFAULT_KEY_NAME_DATA_STOCK_ADJUSTMENT = 'dataStockAdjustment';
export const DEFAULT_KEY_NAME_DATA_WAREHOUSE_TRANSFER = 'dataWarehouseTransfer';

export const DEFAULT_KEY_NAME_TRANSFER_STOCK_ID_API_REF = 'transfer_stock_id';

export const DEFAULT_KEY_NAME_PARENT_URL_SEARCH_PARAMS_STR = 'parentUrlSearchParamsStr';
export const DEFAULT_KEY_NAME_PARENT_URL_SEARCH_PARAMS_OBJ = 'parentUrlSearchParamsObj';

export const DEFAULT_KEY_NAME_PARENT_STATE = 'parentState';
export const DEFAULT_KEY_NAME_CHILDREN_STATE = 'childrenState';
export const DEFAULT_KEY_NAME_OF_KEY_NAME_REQUEST_STATUS = 'keynameRequestStatus';
export const DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING = 'requestStatusPending';
export const DEFAULT_KEY_NAME_REQUEST_STATUS_SUCCESS = 'requestStatusSuccess';
export const DEFAULT_KEY_NAME_REQUEST_STATUS_FAILED = 'requestStatusFailed';
export const DEFAULT_KEY_NAME_URL_TEMPLATE = 'urlTemplate';
export const DEFAULT_KEY_NAME_USE_REQUEST_STATUS_CUSTOM = 'useRequestStatusCustom';

export const DEFAULT_KEY_NAME_CONNECT_GLOBAL_STATE_PROPERTIES = 'connectGlobalStateProperties';

export const DEFAULT_KEY_NAME_ALTER_ROUTE_PATHNAME_PROPERTIES = 'alterRoutePathnameProperties';

export const DEFAULT_KEY_NAME_ATTACHMENT_API_REF = 'attachment';
export const DEFAULT_KEY_NAME_FILE_NAME_API_REF = 'file_name';

export const DEFAULT_KEY_NAME_UUID_API_REF = 'uuid';
export const DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF = 'uuid_attachment';
export const DEFAULT_KEY_NAME_ATTACHMENT_ID_API_REF = 'attachment_id';
export const DEFAULT_KEY_NAME_FILE_PROPERTIES = 'file-properties';
export const DEFAULT_KEY_NAME_FEATURE_API_REF = 'feature';

export const DEFAULT_KEY_NAME_FILE_CONTAINER = 'fileContainer';

export const DEFAULT_KEY_NAME_PATH = 'path';

export const DEFAULT_KEY_NAME_ATTACHMENT_NAME_API_REF = 'attachment_name';

export const DEFAULT_KEY_NAME_PAGE = 'page';
export const DEFAULT_KEY_NAME_PAGINATE = 'paginate';

export const DEFAULT_KEY_NAME_JOURNAL_ENTRY_API_REF = 'journal_entry';
export const DEFAULT_KEY_NAME_CREDIT_AMOUNT_API_REF = 'credit_amount';
export const DEFAULT_KEY_NAME_DEBIT_AMOUNT_API_REF = 'debit_amount';

export const DEFAULT_KEY_NAME_TODAYS_DATE = 'todays_date';

export const DEFAULT_KEY_NAME_CURRENT_DATE = 'current_date';

export const DEFAULT_KEY_NAME_MUTATION_API_REF = 'mutation';
export const DEFAULT_KEY_NAME_WAREHOUSE_API_REF = 'warehouse';

export const DEFAULT_KEY_NAME_CONTACT_API_REF = 'contact';

export const DEFAULT_KEY_NAME_IS_CUSTOMER_API_REF = 'is_customer';
export const DEFAULT_KEY_NAME_IS_SUPPLIER_API_REF = 'is_supplier';

export const DEFAULT_KEY_NAME_MODULE_ID_API_REF = 'module_id';
export const DEFAULT_KEY_NAME_MODULE_NAME_API_REF = 'module_name';

export const DEFAULT_KEY_NAME_TRANSACTION_TYPE = 'transactionType';

export const DEFAULT_KEY_NAME_UNIT_ID_API_REF = 'unit_id';
export const DEFAULT_KEY_NAME_CURRENT_COMPANY_API_REF = 'current_company';
export const DEFAULT_KEY_NAME_MAIN_COMPANY_ID_API_REF = 'main_company_id';
export const DEFAULT_KEY_NAME_COMPANY_ID_API_REF = 'company_id';

export const DEFAULT_KEY_NAME_MONTHLY_TRANSACTION_API_REF = 'monthly_transaction';
export const DEFAULT_KEY_NAME_DAILY_TRANSACTION_API_REF = 'daily_transaction';
export const DEFAULT_KEY_NAME_BEST_MONTH_API_REF = 'best_month';
export const DEFAULT_KEY_NAME_BEST_DATE_API_REF = 'best_date';

export const DEFAULT_KEY_NAME_CURRENT_MONTH_API_REF = 'current_month';
export const DEFAULT_KEY_NAME_CURRENT_MONTH_AMOUNT_API_REF = 'current_month_amount';
export const DEFAULT_KEY_NAME_CURRENT_DATE_API_REF = 'current_date';
export const DEFAULT_KEY_NAME_CURRENT_DATE_AMOUNT_API_REF = 'current_date_amount';
export const DEFAULT_KEY_NAME_PREVIOUS_MONTH_API_REF = 'previous_month';
export const DEFAULT_KEY_NAME_PREVIOUS_MONTH_AMOUNT_API_REF = 'previous_month_amount';
export const DEFAULT_KEY_NAME_PREVIOUS_DATE_API_REF = 'previous_date';
export const DEFAULT_KEY_NAME_PREVIOUS_DATE_AMOUNT_API_REF = 'previous_date_amount';
export const DEFAULT_KEY_NAME_CURRENT_DAY_API_REF = 'current_day';
export const DEFAULT_KEY_NAME_PREVIOUS_DAY_API_REF = 'previous_day';

export const DEFAULT_KEY_NAME_SORT_BY_API_REF = 'sort_by';
export const DEFAULT_KEY_NAME_DATE_API_REF = 'date';
export const DEFAULT_KEY_NAME_NAME_API_REF = 'name';

export const DEFAULT_KEY_NAME_ACTION_UTILS = 'actionUtils';
export const DEFAULT_KEY_NAME_CHART_UTILS_TYPE = 'chartUtilsType';

export const DEFAULT_KEY_NAME_EXPENSE_API_REF = 'expense';
export const DEFAULT_KEY_NAME_INCOME_API_REF = 'income';
export const DEFAULT_KEY_NAME_INCOME_STATEMENT_API_REF = 'income_statement';
export const DEFAULT_KEY_NAME_LOSS_API_REF = 'loss';
export const DEFAULT_KEY_NAME_SALE_API_REF = 'sale';

export const DEFAULT_KEY_NAME_CASE_API_REF = 'case';

export const DEFAULT_KEY_NAME_NOTIFICATION_TYPE_API_REF = 'notification_type';
export const DEFAULT_KEY_NAME_NOTIFICATION_TYPE = 'notificationType';

export const DEFAULT_KEY_NAME_IS_SHOW_MESSAGE = 'isShowMessage';

export const DEFAULT_KEY_NAME_NOTIFICATION_ID_API_REF = 'notification_id';
export const DEFAULT_KEY_NAME_UNREAD_API_REF = 'unread';

export const DEFAULT_KEY_NAME_COMPANY_NAME_API_REF = 'company_name';

export const DEFAULT_KEYNAME_ROUTER_PATH_ON_PARAMS = 'routerPathOnParams';

export const DEFAULT_KEY_NAME_USER_ID_URL_PARAM = 'userID';
export const DEFAULT_KEY_NAME_USER_ID_API_REF = 'user_id';

export const DEFAULT_KEY_NAME_PROFILE_PICTURE_API_REF = 'profile_picture';
export const DEFAULT_KEY_NAME_BILLING_ADDRESS_API_REF = 'billing_address';
export const DEFAULT_KEY_NAME_SHIPPING_ADDRESS_API_REF = 'shipping_address';

export const DEFAULT_KEY_NAME_BILLING_ID_API_REF = 'billing_id';

export const DEFAULT_KEY_NAME_PERMISSION_REQUIRED = 'permissionRequired';

export const DEFAULT_KEY_NAME_PERMISSIONS_REQUIRED = 'permissionsRequired';

export const DEFAULT_KEY_NAME_PURCHASE_RETURN_ID_API_REF = 'purchase_return_id';

export const DEFAULT_KEY_NAME_PARAMS_URL_PURCHASE_RETURN_ID = 'purchaseReturnID';

export const DEFAULT_KEY_NAME_PURCHASE_RETURN_PRODUCT_API_REF = 'purchase_return_product';

export const DEFAULT_KEY_NAME_ROLE_API_REF = 'role';
export const DEFAULT_KEY_NAME_SUMMARY_API_REF = 'summary';

export const DEFAULT_KEY_NAME_PERMISSION_ID_API_REF = 'permission_id';
export const DEFAULT_KEY_NAME_UNIT_NAME_API_REF = 'unit_name';

export const DEFAULT_KEY_NAME_UNIT_ORIGIN_API_REF = 'unit_origin';
export const DEFAULT_KEY_NAME_UNIT_DESTINATION_API_REF = 'unit_destination';

export const DEFAULT_KEY_NAME_COMPARISON_VALUE_API_REF = 'comparison_value';

export const DEFAULT_KEY_NAME_QUANTITY_ORIGIN_API_REF = 'quantity_origin';

export const DEFAULT_KEY_NAME_PURCHASE_PRICE_API_REF = 'purchase_price';
export const DEFAULT_KEY_NAME_SALES_PRICE_API_REF = 'sales_price';

export const DEFAULT_KEY_NAME_UNIT_TYPE_API_REF = 'unit_type';

export const DEFAULT_KEY_NAME_DEBIT_NOTE_ID_API_REF = 'debit_note_id';
export const DEFAULT_KEY_NAME_PARAMS_URL_DEBITE_NOTE_ID = 'debitNoteID';

export const DEFAULT_KEY_NAME_TRANSACTION_NO_CONTAINER = 'transaction_no_container';

export const DEFAULT_KEY_NAME_RECEIVABLE_PAYMENT_ID_API_REF = 'receivable_payment_id';
export const DEFAULT_KEY_NAME_PARAMS_URL_RECEIVABLE_PAYMENT_ID = 'receivablePaymentID';

export const DEFAULT_KEY_NAME_TODAY = 'today';
export const DEFAULT_KEY_NAME_ACCOUNTING_PERIOD_CAMEL_CASE = 'accountingPeriod';

export const DEFAULT_KEY_NAME_ACCOUNT_RECEIVABLE_API_REF = 'account_receivable';
export const DEFAULT_KEY_NAME_ACCOUNT_RECEIVABLE_CONTAINER = 'account_receivable_container';

export const DEFAULT_KEY_NAME_ITEM_API_REF = 'item';

export const DEFAULT_KEY_NAME_SALES_RETURN_ID_API_REF = 'sales_return_id';
export const DEFAULT_KEY_NAME_PARAMS_URL_SALES_RETURN_ID = 'salesReturnID';
export const DEFAULT_KEY_NAME_SALES_RETURN_PRODUCT_API_REF = 'sales_return_product';

export const DEFAULT_KEY_NAME_SALES_INVOICE_ID_CONTAINER = 'sales_invoice_id_container';

export const DEFAULT_KEY_NAME_PARAMS_URL_USER_INVOICE_ID = 'userInvoiceID';

export const DEFAULT_KEY_NAME_USER_INVOICE_ID_API_REF = 'user_invoice_id';
export const DEFAULT_KEY_NAME_MASTER_PACKAGE_ID_API_REF = 'master_package_id';

export const DEFAULT_KEY_NAME_PARAMS_URL_COMPANY_BILL_ID = 'companyBillID';
export const DEFAULT_KEY_NAME_COMPANY_BILL_ID_API_REF = 'company_bill_id';

export const DEFAULT_KEY_NAME_PURCHASE_ORDER_PRODUCT_AVAILABLE_API_REF =
  'purchase_order_product_available';

export const DEFAULT_KEY_NAME_MODUL_QUANTITY_AVAILABLE_API_REF = 'modul_quantity_available';

export const DEFAULT_KEY_NAME_PAYABLE_PAYMENT_ID_API_REF = 'payable_payment_id';
export const DEFAULT_KEY_NAME_PARAMS_URL_PAYABLE_PAYMENT_ID = 'payablePaymentID';

export const DEFAULT_KEY_NAME_CREDIT_NOTE_ID_API_REF = 'credit_note_id';
export const DEFAULT_KEY_NAME_PARAMS_URL_CREDIT_NOTE_ID = 'creditNoteID';

export const DEFAULT_KEY_NAME_ACCOUNT_PAYABLE_API_REF = 'account_payable';
export const DEFAULT_KEY_NAME_ACCOUNT_PAYABLE_CONTAINER = 'account_payable_container';

export const DEFAULT_KEY_NAME_SALES_DELIVERY_QUANTITY_AVAILABLE_API_REF =
  'sales_delivery_quantity_available';
export const DEFAULT_KEY_NAME_SALES_ORDER_PRODUCT_AVAILABLE_API_REF =
  'sales_order_product_available';

export const DEFAULT_KEY_NAME_PARAMS_URL_ASSET_ID = 'assetID';

export const DEFAULT_ASSET_TRANSACTION_API_REF = 'asset_transaction';

export const DEFAULT_KEY_NAME_QUANTITY_AVAILABLE_WITH_UNIT = 'quantity_available_with_unit';

export const DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF = 'minDateInputRef';

export const DEFAULT_KEY_NAME_PRICE_AVERAGE_API_REF = 'price_average';

export const DEFAULT_KEY_NAME_ASSET_STATUS_API_REF = 'asset_status';

export const DEFAULT_KEY_NAME_PARAMS_URL_CONTACT_ID = 'contactID';

export const DEFAULT_KEY_NAME_BANK_NAME_API_REF = 'bank_name';

export const DEFAULT_KEY_NAME_TAG_API_REF = 'tag';
export const DEFAULT_KEY_NAME_TAG_NAME_API_REF = 'tag_name';
export const DEFAULT_KEY_NAME_TAG_ID_API_REF = 'tag_id';

export const DEFAULT_KEY_NAME_PACKAGE_NAME_API_REF = 'package_name';

export const DEFAULT_KEY_NAME_BILLING_SUBSCRIPTION_TYPE = 'billingSubscriptionType';
export const DEFAULT_KEY_NAME_SUBSCRIPTION_INTERVAL = 'subscriptionInterval';

export const DEFAULT_KEY_NAME_PARAMS_URL_TRANSFER_STOCK_ID = 'transferStockID';
export const DEFAULT_KEY_NAME_PARAMS_URL_STOCK_ADJUSTMENT_ID = 'stockAdjustmentID';

export const DEFAULT_KEY_NAME_STOCK_ADJUSTMENT_PRODUCT_API_REF = 'stock_adjustment_product';
export const DEFAULT_KEY_NAME_TRANSFER_STOCK_PRODUCT_API_REF = 'transfer_stock_product';

export const DEFAULT_KEY_NAME_PARAMS_URL_CASH_TRANSFER_ID = 'cashTransferID';
export const DEFAULT_KEY_NAME_PARAMS_URL_CASH_RECEIPT_ID = 'cashReceiptID';
export const DEFAULT_KEY_NAME_PARAMS_URL_CASH_PAYMENT_ID = 'cashPaymentID';

export const DEFAULT_KEY_NAME_CASH_TRANSFER_ID_API_REF = 'cash_transfer_id';
export const DEFAULT_KEY_NAME_CASH_RECEIPT_ID_API_REF = 'cash_receipt_id';
export const DEFAULT_KEY_NAME_CASH_PAYMENT_ID_API_REF = 'cash_payment_id';

export const DEFAULT_KEY_NAME_IS_REWRITE_ALL = 'isRewriteAll';

export const DEFAULT_KEY_NAME_PAID_OFF_ACCOUNT_ID_API_REF = 'paid_off_account_id';
export const DEFAULT_KEY_NAME_PAID_OFF_ACCOUNT_ID_CONTAINER = 'paid_off_account_id_container';

export const DEFAULT_KEY_NAME_SHOW_ALL_PRODUCT_API_REF = 'show_all_product';

export const DEFAULT_KEY_NAME_GET_UNIT_GROUP_API_REF = 'get_unit_group';

export const DEFAULT_KEY_NAME_SETUP_COMPANY_PROGRESS = 'setupCompanyProgress';

export const DEFAULT_KEY_NAME_LOGIN_SETUP_API_REF = 'login_setup';

export const DEFAULT_KEY_NAME_INVENTORY_ACCOUNT_ID_API_REF = 'inventory_account_id';
export const DEFAULT_KEY_NAME_PURCHASE_ACCOUNT_ID_API_REF = 'purchase_account_id';
export const DEFAULT_KEY_NAME_SALES_ACCOUNT_ID_API_REF = 'sales_account_id';

export const DEFAULT_KEY_NAME_PURCHASE_INVOICE_AMOUNT_AVAILABLE_API_REF =
  'purchase_invoice_amount_available';
export const DEFAULT_KEY_NAME_SALES_INVOICE_AMOUNT_AVAILABLE_API_REF =
  'sales_invoice_amount_available';

export const DEFAULT_KEY_NAME_PACKAGE_NAME = 'packageName';

export const DEFAULT_KEY_NAME_TOKEN_API_REF = 'token';

export const DEFAULT_KEY_NAME_PARAMS_URL_CLOSING_BOOK_ID = 'closingBookID';

export const DEFAULT_KEY_NAME_ACCOUNT_API_REF = 'account';
export const DEFAULT_KEY_NAME_CATEGORY_TYPE_API_REF = 'category_type';

export const DEFAULT_KEY_NAME_NET_PROFIT_LOSS_API_REF = 'net_profit_loss';

export const AUTH_USER_PERMISSION_KEY_NAME = 'permission';

export const PRODUCT_CATEGORY_ID_KEY_NAME_API_REF = 'product_category_id';

export const ASSET_CATEGORY_ID_KEY_NAME_API_REF = 'asset_category_id';

export const SLICE_NAME_KEY_NAME = 'sliceName';
export const SLICE_PAYLOAD_KEYNAME_KEY_NAME = 'slicePayloadKeyname';

export const ADD_ON_KEY_NAME = 'add_on';

export const TAG_CONTAINER_KEY_NAME = 'tagContainer';
export const TAG_NAME_INPUT_KEY_NAME = 'tagNameInput';
