export const styles = () => {
  return {
    boxContainerMain: {
      minWidth: '100%',
      minHeight: '100%',
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      backgroundColor: 'white',
    },
  };
};
