/* eslint-disable no-undef */
require('dotenv').config();

export const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
export const REACT_APP_API_URL_USER_AUTH_CHANGE_COMPANY = `${REACT_APP_API_URL}/change-company`;
export const REACT_APP_API_URL_USER = `${REACT_APP_API_URL}/user`;
export const REACT_APP_API_URL_USER_COMPANY = `${REACT_APP_API_URL}/user/company`;
export const REACT_APP_API_URL_ACCOUNT = `${REACT_APP_API_URL}/account`;
export const REACT_APP_API_URL_ACCOUNT_DETAILS = `${REACT_APP_API_URL_ACCOUNT}/{account_id}`;
export const REACT_APP_API_URL_ACCOUNT_TRANSACTION = `${REACT_APP_API_URL_ACCOUNT}/{account_id}/transaction`;
export const REACT_APP_API_URL_ACCOUNT_CATEGORY = `${REACT_APP_API_URL}/account-category`;
export const REACT_APP_API_URL_ACCOUNT_PARENT = `${REACT_APP_API_URL}/account/list`;
export const REACT_APP_API_URL_ACCOUNT_SUB = `${REACT_APP_API_URL}/account/sub`;
export const REACT_APP_API_URL_EMAIL = `${REACT_APP_API_URL}/email`;
export const REACT_APP_API_URL_COMPANY = `${REACT_APP_API_URL}/company`;
export const REACT_APP_API_URL_MANAGEMENT_USER = `${REACT_APP_API_URL}/management/user`;
export const REACT_APP_API_URL_MANAGEMENT_USER_DETAILS = `${REACT_APP_API_URL_MANAGEMENT_USER}/{user_id}`;
export const REACT_APP_API_URL_MANAGEMENT_ROLE = `${REACT_APP_API_URL}/management/role`;
export const REACT_APP_API_URL_MANAGEMENT_COMPANY = `${REACT_APP_API_URL}/management/company`;
export const REACT_APP_API_URL_CHANGE_COMPANY = `${REACT_APP_API_URL}/change-company`;
export const REACT_APP_API_URL_CONTACT = `${REACT_APP_API_URL}/contact`;
export const REACT_APP_API_URL_CONTACT_PURCHASE_INVOCE = `${REACT_APP_API_URL}/contact/{contact_id}/purchase-invoice`;
export const REACT_APP_API_URL_CONTACT_SALES_INVOICE = `${REACT_APP_API_URL}/contact/{contact_id}/sales-invoice`;
export const REACT_APP_API_URL_PROVINCE = `${REACT_APP_API_URL}/province`;
export const REACT_APP_API_URL_INDO_REGIONAL = `${REACT_APP_API_URL}/postal-code`;
export const REACT_APP_API_URL_POSTAL_CODE = `${REACT_APP_API_URL}/postal-code`;
export const REACT_APP_API_URL_BANK = `${REACT_APP_API_URL}/bank`;
export const REACT_APP_API_URL_REFERENCE_NUMBER = `${REACT_APP_API_URL}/reference-number`;
export const REACT_APP_API_URL_PRODUCT_DATA = `${REACT_APP_API_URL}/product`;
export const REACT_APP_API_URL_PRODUCT_PURCHASE_SALE = `${REACT_APP_API_URL}/product/supplier`;
export const REACT_APP_API_URL_PRODUCT_BY_SUPPLIER = `${REACT_APP_API_URL}/product-supplier`;
export const REACT_APP_API_URL_PRODUCT_CATEGORY = `${REACT_APP_API_URL}/product-category`;
export const REACT_APP_API_URL_PRODUCT_UPLOAD = `${REACT_APP_API_URL}/product-upload`;
export const REACT_APP_API_URL_PRODUCT_UPLOAD_PROGRESS = `${REACT_APP_API_URL_PRODUCT_UPLOAD}/batch/{id}`;
export const REACT_APP_API_URL_PRODUCT_UNIT = `${REACT_APP_API_URL}/product-unit`;
export const REACT_APP_API_URL_PRODUCT_MUTATION = `${REACT_APP_API_URL_PRODUCT_DATA}/{product_id}/mutation`;
export const REACT_APP_API_URL_JOURNAL_ENTRY = `${REACT_APP_API_URL}/journal-entry`;
export const REACT_APP_API_URL_JOURNAL_ENTRY_UPLOAD = REACT_APP_API_URL_PRODUCT_UPLOAD;
export const REACT_APP_API_URL_JOURNAL_ENTRY_UPLOAD_PROGRESS =
  REACT_APP_API_URL_PRODUCT_UPLOAD_PROGRESS;
export const REACT_APP_API_URL_BEGINNING_BALANCE = `${REACT_APP_API_URL}/beginning-balance`;
export const REACT_APP_API_URL_RECEIPT_CASH = `${REACT_APP_API_URL}/receipt-cash`;
export const REACT_APP_API_URL_RECEIPT_CASH_DETAIL = `${REACT_APP_API_URL_RECEIPT_CASH}/{transaction_id}/detail`;
export const REACT_APP_API_URL_PAYMENT_CASH = `${REACT_APP_API_URL}/payment-cash`;
export const REACT_APP_API_URL_PAYMENT_CASH_DETAIL = `${REACT_APP_API_URL_PAYMENT_CASH}/{transaction_id}`;
export const REACT_APP_API_URL_CASH_BANK = `${REACT_APP_API_URL}/cash-bank`;
export const REACT_APP_API_URL_CASH_BANK_CALCULATE = `${REACT_APP_API_URL}/cash-bank/calculate`;
export const REACT_APP_API_URL_CASH_BANK_TRANSACTION_ACCOUNT = `${REACT_APP_API_URL_CASH_BANK}/transaction/account`;
export const REACT_APP_API_URL_CASH_TRANSFER = `${REACT_APP_API_URL}/transfer-cash`;
export const REACT_APP_API_URL_CASH_TRANSFER_DETAIL = `${REACT_APP_API_URL_CASH_TRANSFER}/{transaction_id}/detail`;
export const REACT_APP_API_URL_QUOTATION_REQUEST = `${REACT_APP_API_URL}/quotation-request`;
export const REACT_APP_API_URL_QUOTATION_REQUEST_HISTORY = `${REACT_APP_API_URL_QUOTATION_REQUEST}/{quotation_request_id}/history`;
export const REACT_APP_API_URL_PURCHASE_ORDER = `${REACT_APP_API_URL}/purchase-order`;
export const REACT_APP_API_URL_PURCHASE_ORDER_HISTORY = `${REACT_APP_API_URL_PURCHASE_ORDER}/{purchase_order_id}/history`;
export const REACT_APP_API_URL_PURCHASE_PAYMENT = `${REACT_APP_API_URL}/purchase-payment`;
export const REACT_APP_API_URL_PURCHASE_PAYMENT_HISTORY = `${REACT_APP_API_URL_PURCHASE_PAYMENT}/{purchase_payment_id}/history`;
export const REACT_APP_API_URL_PURCHASE_DELIVERY = `${REACT_APP_API_URL}/purchase-delivery`;
export const REACT_APP_API_URL_PURCHASE_DELIVERY_HISTORY = `${REACT_APP_API_URL}/purchase-delivery/{purchase_delivery_id}/history`;
export const REACT_APP_API_URL_PURCHASE_INVOICE = `${REACT_APP_API_URL}/purchase-invoice`;
export const REACT_APP_API_URL_PURCHASE_INVOICE_UPLOAD = REACT_APP_API_URL_PRODUCT_UPLOAD;
export const REACT_APP_API_URL_PURCHASE_INVOICE_UPLOAD_PROGRESS =
  REACT_APP_API_URL_PRODUCT_UPLOAD_PROGRESS;
export const REACT_APP_API_URL_PURCHASE_INVOICE_DETAILS_TEMPLATE = `${REACT_APP_API_URL_PURCHASE_INVOICE}/{purchase_invoice_id}`;
export const REACT_APP_API_URL_PURCHASE_INVOICE_HISTORY = `${REACT_APP_API_URL_PURCHASE_INVOICE}/{purchase_invoice_id}/history`;
export const REACT_APP_API_URL_PURCHASE_PAYMENT_DETAIL = `${REACT_APP_API_URL}/purchase-payment/{purchase_payment_id}`;
export const REACT_APP_API_URL_PURCHASE_PAYMENT_PURCHASE_PAYMENT = `${REACT_APP_API_URL}/purchase-payment/{purchase_payment_id}/history`;
export const REACT_APP_API_URL_SALES_QUOTATION = `${REACT_APP_API_URL}/sales-quotation`;
export const REACT_APP_API_URL_SALES_QUOTATION_HISTORY = `${REACT_APP_API_URL}/sales-quotation/{sales_quotation_id}/history`;
export const REACT_APP_API_URL_SALES_INVOICE = `${REACT_APP_API_URL}/sales-invoice`;
export const REACT_APP_API_URL_SALES_INVOICE_UPLOAD = REACT_APP_API_URL_PRODUCT_UPLOAD;
export const REACT_APP_API_URL_SALES_INVOICE_UPLOAD_PROGRESS =
  REACT_APP_API_URL_PRODUCT_UPLOAD_PROGRESS;
export const REACT_APP_API_URL_SALES_INVOICE_DETAILS_TEMPLATE = `${REACT_APP_API_URL_SALES_INVOICE}/{sales_invoice_id}`;
export const REACT_APP_API_URL_SALES_INVOICE_HISTORY = `${REACT_APP_API_URL}/sales-invoice/{sales_invoice_id}/history`;
export const REACT_APP_API_URL_SALES_ORDER = `${REACT_APP_API_URL}/sales-order`;
export const REACT_APP_API_URL_SALES_ORDER_HISTORY = `${REACT_APP_API_URL}/sales-order/{sales_order_id}/history`;
export const REACT_APP_API_URL_SALES_DELIVERY_AND_ORDER = `${REACT_APP_API_URL}/sales-delivery-and-order`;
export const REACT_APP_API_URL_SALES_DELIVERY = `${REACT_APP_API_URL}/sales-delivery`;
export const REACT_APP_API_URL_SALES_DELIVERY_HISTORY = `${REACT_APP_API_URL}/sales-delivery/{sales_delivery_id}/history`;
export const REACT_APP_API_URL_SALES_PAYMENT = `${REACT_APP_API_URL}/sales-payment`;
export const REACT_APP_API_URL_SALES_PAYMENT_HISTORY = `${REACT_APP_API_URL}/sales-payment/{sales_payment_id}/history`;
export const REACT_APP_API_URL_ASSET_CATEGORY = `${REACT_APP_API_URL}/asset-category`;
export const REACT_APP_API_URL_ASSET_MANAGEMENT = `${REACT_APP_API_URL}/asset-management`;
export const REACT_APP_API_URL_SALE_ASSET_MANAGEMENT = `${REACT_APP_API_URL}/asset-management/{asset_id}/sell`;
export const REACT_APP_API_URL_ASSET_MANAGEMENT_REVERT = `${REACT_APP_API_URL_ASSET_MANAGEMENT}/{asset_id}/restore`;
export const REACT_APP_API_URL_REPORT = `${REACT_APP_API_URL}/report`;
export const REACT_APP_API_URL_REPORT_JOURNAL = `${REACT_APP_API_URL_REPORT}/journal`;
export const REACT_APP_API_URL_REPORT_INCOME_STATEMENT = `${REACT_APP_API_URL_REPORT}/income-statement`;
export const REACT_APP_API_URL_REPORT_BALANCE_SHEET = `${REACT_APP_API_URL_REPORT}/balance-sheet`;
export const REACT_APP_API_URL_REPORT_CAPITAL_CHANGE = `${REACT_APP_API_URL_REPORT}/change-capital`;
export const REACT_APP_API_URL_REPORT_CASH_FLOW = `${REACT_APP_API_URL_REPORT}/cash-flow`;
export const REACT_APP_API_URL_REPORT_GENERAL_LEDGER = `${REACT_APP_API_URL_REPORT}/ledger`;
export const REACT_APP_API_URL_REPORT_TRIAL_BALANCE = `${REACT_APP_API_URL_REPORT}/trial-balance`;
export const REACT_APP_API_URL_REPORT_DOWNLOAD = `${REACT_APP_API_URL_REPORT}/download`;
export const REACT_APP_API_URL_REPORT_STOCK_SALES = `${REACT_APP_API_URL_REPORT}/stock-sales`;
export const REACT_APP_API_URL_REPORT_STOCK_CARD = `${REACT_APP_API_URL_REPORT}/stock-card/{product_id}`;
export const REACT_APP_API_URL_REPORT_STOCK_MUTATION = `${REACT_APP_API_URL_REPORT}/stock-mutation/{product_id}`;
export const REACT_APP_API_URL_REPORT_STOCK_PURCHASE = `${REACT_APP_API_URL_REPORT}/stock-purchase`;
export const REACT_APP_API_URL_REPORT_STOCK_ADJUSTMENT = `${REACT_APP_API_URL_REPORT}/stock-adjustment`;
export const REACT_APP_API_URL_EXPORT_GENERAL_JOURNAL_REPORT = `${REACT_APP_API_URL_REPORT}/export/journal`;
export const REACT_APP_API_URL_EXPORT_INCOME_STATEMENT_REPORT = `${REACT_APP_API_URL_REPORT}/export/income-statement`;
export const REACT_APP_API_URL_EXPORT_BALANCE_SHEET_REPORT = `${REACT_APP_API_URL_REPORT}/export/balance-sheet`;
export const REACT_APP_API_URL_EXPORT_CAPITAL_CHANGE_REPORT = `${REACT_APP_API_URL_REPORT}/export/capital-change`;
export const REACT_APP_API_URL_EXPORT_CASH_FLOW_REPORT = `${REACT_APP_API_URL_REPORT}/export/cash-flow`;
export const REACT_APP_API_URL_EXPORT_GENERAL_LEDGER_REPORT = `${REACT_APP_API_URL_REPORT}/export/ledger`;
export const REACT_APP_API_URL_EXPORT_TRIAL_BALANCE_REPORT = `${REACT_APP_API_URL_REPORT}/export/trial-balance`;
export const REACT_APP_API_URL_SETTINGS_WAREHOUSE = `${REACT_APP_API_URL}/warehouse`;
export const REACT_APP_API_URL_SETTINGS_WAREHOUSE_PRODUCT = `${REACT_APP_API_URL_SETTINGS_WAREHOUSE}/{warehouse_id}/product`;
export const REACT_APP_API_URL_CLOSING_BOOK = `${REACT_APP_API_URL}/closing-book`;
export const REACT_APP_API_URL_CLOSING_BOOK_DETAILS = `${REACT_APP_API_URL_CLOSING_BOOK}/{closing_book_id}`;
export const REACT_APP_API_URL_CLOSING_BOOK_PROCESS = `${REACT_APP_API_URL_CLOSING_BOOK}/{closing_book_id}/process`;
export const REACT_APP_API_URL_CLOSING_BOOK_REVERT = `${REACT_APP_API_URL_CLOSING_BOOK}/{closing_book_id}`;
export const REACT_APP_API_URL_CLOSING_BOOK_CREATE = `${REACT_APP_API_URL}/closing-book/create/{closing_book_id}`;
export const REACT_APP_API_URL_TRANSACTION = `${REACT_APP_API_URL}/transaction`;
export const REACT_APP_API_URL_TRANSACTION_DETAILS = `${REACT_APP_API_URL_TRANSACTION}/{transaction_id}`;
export const REACT_APP_API_URL_TRANSFER_STOCK = `${REACT_APP_API_URL}/transfer-stock`;
export const REACT_APP_API_URL_STOCK_ADJUSTMENT = `${REACT_APP_API_URL}/stock-adjustment`;
export const REACT_APP_API_URL_STOCK_ADJUSTMENT_DETAILS = `${REACT_APP_API_URL_STOCK_ADJUSTMENT}/{stock_adjustment_id}`;
export const REACT_APP_API_URL_STOCK_TRANSFER = `${REACT_APP_API_URL}/transfer-stock`;
export const REACT_APP_API_URL_HANDOVER_PURCHASE = `${REACT_APP_API_URL}/hand-over/purchase`;
export const REACT_APP_API_URL_HANDOVER_PURCHASE_DETAILS = `${REACT_APP_API_URL_HANDOVER_PURCHASE}/{purchase_handover_id}`;
export const REACT_APP_API_URL_HANDOVER_PURCHASE_UPDATE = `${REACT_APP_API_URL_HANDOVER_PURCHASE}/{purchase_handover_id}`;
export const REACT_APP_API_URL_HANDOVER_PURCHASE_HISTORY = `${REACT_APP_API_URL_HANDOVER_PURCHASE}/{purchase_handover_id}/history`;
export const REACT_APP_API_URL_HANDOVER_SALES = `${REACT_APP_API_URL}/hand-over/sales`;
export const REACT_APP_API_URL_HANDOVER_SALES_DETAILS = `${REACT_APP_API_URL_HANDOVER_SALES}/{sales_handover_id}`;
export const REACT_APP_API_URL_HANDOVER_SALES_UPDATE = `${REACT_APP_API_URL_HANDOVER_SALES}/{sales_handover_id}`;
export const REACT_APP_API_URL_HANDOVER_SALES_HISTORY = `${REACT_APP_API_URL_HANDOVER_SALES}/{sales_handover_id}/history`;
export const REACT_APP_API_URL_CHART = `${REACT_APP_API_URL}/chart`;
export const REACT_APP_API_URL_CHART_PURCHASE = `${REACT_APP_API_URL_CHART}/purchase`;
export const REACT_APP_API_URL_CHART_SALES = `${REACT_APP_API_URL_CHART}/sales`;
export const REACT_APP_API_URL_CHART_PAYABLE = `${REACT_APP_API_URL_CHART}/payable`;
export const REACT_APP_API_URL_CHART_RECEIVABLE = `${REACT_APP_API_URL_CHART}/receivable`;
export const REACT_APP_API_URL_CHART_SALES_INVOICE = `${REACT_APP_API_URL_CHART}/sales-invoice`;
export const REACT_APP_API_URL_CHART_PURCHASE_INVOICE = `${REACT_APP_API_URL_CHART}/purchase-invoice`;
export const REACT_APP_API_URL_CHART_PRODUCT_STOCK = `${REACT_APP_API_URL_CHART}/product-stock`;
export const REACT_APP_API_URL_CHART_SALES_PERSON = `${REACT_APP_API_URL_CHART}/sales-person`;
export const REACT_APP_API_URL_CHART_TRANSACTION_SALES = `${REACT_APP_API_URL_CHART}/transaction-sales`;
export const REACT_APP_API_URL_CHART_INCOME_STATEMENT = `${REACT_APP_API_URL_CHART}/income-statement`;
export const REACT_APP_API_URL_CHART_TOP_SALES = `${REACT_APP_API_URL_CHART}/top-sales`;
export const REACT_APP_API_URL_CHART_TOP_SALES_CUSTOMER = `${REACT_APP_API_URL_CHART_TOP_SALES}/customer`;
export const REACT_APP_API_URL_CHART_TOP_SALES_SALES_AGENT = `${REACT_APP_API_URL_CHART_TOP_SALES}/sales-agent`;
export const REACT_APP_API_URL_CHART_TOP_SALES_PRODUCT = `${REACT_APP_API_URL_CHART_TOP_SALES}/product`;
export const REACT_APP_API_URL_CHART_TOP_SALES_PRODUCT_CATEGORY = `${REACT_APP_API_URL_CHART_TOP_SALES}/product-category`;
export const REACT_APP_API_URL_CHART_COMPARISON_SALES = `${REACT_APP_API_URL_CHART}/comparison-sales`;
export const REACT_APP_API_URL_CHART_EXPENSE = `${REACT_APP_API_URL_CHART}/expense`;
export const REACT_APP_API_URL_CHART_TOP_SALES_COMPARISON = `${REACT_APP_API_URL_CHART}/top-sales-comparison`;
export const REACT_APP_API_URL_CHART_TOP_SALES_COMPARISON_CUSTOMER = `${REACT_APP_API_URL_CHART_TOP_SALES_COMPARISON}/customer`;
export const REACT_APP_API_URL_CHART_TOP_SALES_COMPARISON_SALES_AGENT = `${REACT_APP_API_URL_CHART_TOP_SALES_COMPARISON}/sales-agent`;
export const REACT_APP_API_URL_CHART_TOP_SALES_COMPARISON_PRODUCT = `${REACT_APP_API_URL_CHART_TOP_SALES_COMPARISON}/product`;
export const REACT_APP_API_URL_CHART_TOP_SALES_COMPARISON_PRODUCT_CATEGORY = `${REACT_APP_API_URL_CHART_TOP_SALES_COMPARISON}/product-category`;
export const REACT_APP_API_URL_CHART_PIVOT_TABLE = `${REACT_APP_API_URL_CHART}/pivot-table`;
export const REACT_APP_API_URL_REPORT_BASIC = `${REACT_APP_API_URL}/report`;
export const REACT_APP_API_URL_REPORT_BASIC_PURCHASE = `${REACT_APP_API_URL_REPORT_BASIC}/purchase`;
export const REACT_APP_API_URL_REPORT_BASIC_SALES = `${REACT_APP_API_URL_REPORT_BASIC}/sales`;
export const REACT_APP_API_URL_REPORT_BASIC_STOCK = `${REACT_APP_API_URL_REPORT_BASIC}/stock`;
export const REACT_APP_API_URL_REPORT_BASIC_PRICE_LIST = `${REACT_APP_API_URL_REPORT_BASIC}/price-list`;
export const REACT_APP_API_URL_REPORT_BASIC_SALES_EXTRACTION = `${REACT_APP_API_URL_REPORT_BASIC}/extract-sales`;
export const REACT_APP_API_URL_EXPENSE = `${REACT_APP_API_URL}/expense`;
export const REACT_APP_API_URL_EXPENSE_UPLOAD = REACT_APP_API_URL_PRODUCT_UPLOAD;
export const REACT_APP_API_URL_EXPENSE_UPLOAD_PROGRESS = REACT_APP_API_URL_PRODUCT_UPLOAD_PROGRESS;
export const REACT_APP_API_URL_COMPANY_REGISTER = `${REACT_APP_API_URL}/company-register`;
export const REACT_APP_API_URL_ATTACHMENT = `${REACT_APP_API_URL}/attachment`;
export const REACT_APP_API_URL_ATTACHMENT_DETAILS = `${REACT_APP_API_URL_ATTACHMENT}/{uuid_attachment}`;
export const REACT_APP_API_URL_ATTACHMENT_DELETE = `${REACT_APP_API_URL}/attachment/{attachment_id}`;
export const REACT_APP_API_URL_ATTACHMENT_DELETE_BATCH = `${REACT_APP_API_URL_ATTACHMENT}/{uuid_attachment}/batch`;
export const REACT_APP_API_URL_NOTIFICATION = `${REACT_APP_API_URL}/notification`;
export const REACT_APP_API_URL_UNREAD_NOTIFICATION = `${REACT_APP_API_URL_NOTIFICATION}/unread`;
export const REACT_APP_API_URL_MANAGEMENT_INVITE = `${REACT_APP_API_URL}/management/invite`;
export const REACT_APP_API_URL_MANAGEMENT_INVITE_APPROVAL = `${REACT_APP_API_URL}/management/invite/{notification_id}`;
export const REACT_APP_API_URL_PURCHASE_RETURN = `${REACT_APP_API_URL}/purchase-return`;
export const REACT_APP_API_URL_PURCHASE_RETURN_DETAIL = `${REACT_APP_API_URL}/purchase-return/{purchase_return_id}`;
export const REACT_APP_API_URL_PURCHASE_RETURN_HISTORY = `${REACT_APP_API_URL_PURCHASE_RETURN}/{purchase_return_id}/history`;
export const REACT_APP_API_URL_USER_PERMISSION = `${REACT_APP_API_URL}/user-permission`;
export const REACT_APP_API_URL_USER_PERMISSION_DETAILS = `${REACT_APP_API_URL_USER_PERMISSION}/{user_id}`;
export const REACT_APP_API_URL_DEBIT_NOTE = `${REACT_APP_API_URL}/debit-note`;
export const REACT_APP_API_URL_DEBIT_NOTE_DETAILS = `${REACT_APP_API_URL_DEBIT_NOTE}/{debit_note_id}`;
export const REACT_APP_API_URL_DEBIT_NOTE_HISTORY = `${REACT_APP_API_URL_DEBIT_NOTE}/{debit_note_id}/history`;
export const REACT_APP_API_URL_RECEIVABLE_PAYMENT = `${REACT_APP_API_URL}/receivable-payment`;
export const REACT_APP_API_URL_RECEIVABLE_PAYMENT_DETAILS = `${REACT_APP_API_URL_RECEIVABLE_PAYMENT}/{receivable_payment_id}`;
export const REACT_APP_API_URL_RECEIVABLE_PAYMENT_HISTORY = `${REACT_APP_API_URL_RECEIVABLE_PAYMENT}/{receivable_payment_id}/history`;
export const REACT_APP_API_URL_SALES_RETURN = `${REACT_APP_API_URL}/sales-return`;
export const REACT_APP_API_URL_SALES_RETURN_DETAILS = `${REACT_APP_API_URL_SALES_RETURN}/{sales_return_id}`;
export const REACT_APP_API_URL_SALES_RETURN_HISTORY = `${REACT_APP_API_URL_SALES_RETURN}/{sales_return_id}/history`;
export const REACT_APP_API_URL_BILLING_PACKAGE = `${REACT_APP_API_URL}/billing-package`;
export const REACT_APP_API_URL_BILLING_INVOICE = `${REACT_APP_API_URL}/billing`;
export const REACT_APP_API_URL_BILLING_INVOICE_DETAILS = `${REACT_APP_API_URL_BILLING_INVOICE}/{company_bill_no}`;
export const REACT_APP_API_URL_BILLING_INVOICE_PAY = `${REACT_APP_API_URL_BILLING_INVOICE}/{company_bill_id}/pay`;
export const REACT_APP_API_URL_PAYABLE_PAYMENT = `${REACT_APP_API_URL}/payable-payment`;
export const REACT_APP_API_URL_PAYABLE_PAYMENT_DETAILS = `${REACT_APP_API_URL_PAYABLE_PAYMENT}/{payable_payment_id}`;
export const REACT_APP_API_URL_PAYABLE_PAYMENT_HISTORY = `${REACT_APP_API_URL_PAYABLE_PAYMENT}/{payable_payment_id}/history`;
export const REACT_APP_API_URL_CREDIT_NOTE = `${REACT_APP_API_URL}/credit-note`;
export const REACT_APP_API_URL_CREDIT_NOTE_DETAILS = `${REACT_APP_API_URL_CREDIT_NOTE}/{credit_note_id}`;
export const REACT_APP_API_URL_CREDIT_NOTE_HISTORY = `${REACT_APP_API_URL_CREDIT_NOTE}/{credit_note_id}/history`;
export const REACT_APP_API_URL_TAG = `${REACT_APP_API_URL}/tag`;
export const REACT_APP_API_URL_MANAGEMENT_COMPANY_LOGIN_SETUP = `${REACT_APP_API_URL}/management/company/{company_id}/login-setup`;
export const REACT_APP_API_URL_ROLE = `${REACT_APP_API_URL}/role`;
