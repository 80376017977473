import React from 'react';
import { MoreHoriz } from '@mui/icons-material';
import { Button } from '@mui/material';
import { isEmpty } from 'lodash';

import usePermission, { permissionFeatureType } from '../../../hooks/usePermission';
import arrHelp from '../../../utils/helpers/array.helpers';
import ButtonCollections from '../../button/ButtonCollections';
import PopperMenuListItems from '../../popper/PopperMenuListItems';

const defaultPermissionProps = {
  permissionType: permissionFeatureType,
  permissionsRequired: [],
};

export default function MenuIconButtons({
  Icon = <MoreHoriz />,
  id = 'composition-menu-icon-buttons',
  disabled = false,
  buttonProps = {},
  popperProps = {},
  menuListItems = [],
  onMenuItemsClick = [], // handling multiple menu item where index is for identity
  onMenuItemClick = () => null, // handling for generalize menu item click
  isWrapped = false,
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const { isPermissionsGranted } = usePermission();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  // checking permission
  if (!isEmpty(menuListItems)) {
    const menuListPermissionProps = arrHelp.mappingChildrenKeyOnObject(
      menuListItems,
      'permissionProps',
    );

    if (!isEmpty(menuListPermissionProps)) {
      const menuListPermissionsRequired = arrHelp.mappingChildrenKeyOnObject(
        menuListPermissionProps,
        'permissionsRequired',
      );

      if (
        !isPermissionsGranted({
          ...defaultPermissionProps,
          permissionsRequired: menuListPermissionsRequired,
        })
      )
        return null;
    }
  }

  if (isWrapped) {
    return (
      <ButtonCollections
        buttonConfigs={menuListItems}
        onClickButtonActions={onMenuItemsClick}
        onClickButtonAction={onMenuItemClick}
        disabled={disabled}
      />
    );
  }

  return (
    <>
      <Button
        ref={anchorRef}
        id={id}
        aria-controls={open ? 'composition-menu-list-items' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
        variant='outlined'
        className='primaryButtonV2'
        disableElevation
        disabled={disabled}
        {...buttonProps}
      >
        {Icon || null}
      </Button>
      <PopperMenuListItems
        open={open}
        anchorRef={anchorRef}
        onClose={handleClose}
        popperProps={popperProps}
        menuListItems={menuListItems}
        onMenuItemsClick={onMenuItemsClick}
        onMenuItemClick={onMenuItemClick}
      />
    </>
  );
}
