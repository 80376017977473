/**
 * Guide for namespace on createAsyncThunk type
 *
 * usage action type that includes all of
 *  {baseType}/{actionName}/{actionStatus}
 *
 *  base type is unique name per-endpoint
 *  action name can defaulted by action on endpoint, avoid redudancy
 *  action status is generated by redux toolkit
 *
 *  but for unique name each actions can usage combination of base type and action type
 *  that called asyncThunkType
 *      where {baseType}/{actionName}
 *
 */

import { generateAsyncTypeByBaseActionType } from '../helpers/slice-reducer.helper';

export const REDUX_TOOLKIT_PENDING_STATUS_ACTION_TYPE = 'pending';
export const REDUX_TOOLKIT_FULFILLED_STATUS_ACTION_TYPE = 'fulfilled';
export const REDUX_TOOLKIT_REJECTED_STATUS_ACTION_TYPE = 'rejected';

export const DEFAULT_LIST_ACTION_TYPE = 'list';
export const DEFAULT_LIST_REFRESH_ACTION_TYPE = 'listRefresh';
export const DEFAULT_LIST_ALTERNATIVE_ACTION_TYPE = 'listAlternative';
export const DEFAULT_ADD_ACTION_TYPE = 'add';
export const DEFAULT_UPDATE_ACTION_TYPE = 'update';
export const DEFAULT_DETAIL_ACTION_TYPE = 'detail';
export const DEFAULT_DELETE_ACTION_TYPE = 'delete';
export const DEFAULT_IMPORT_FILE_ACTION_TYPE = 'importFile';
export const DEFAULT_IMPORT_FILE_PROGRESS_ACTION_TYPE = 'importFileProgress';

export const defaultActionAsyncThunkName = [
  DEFAULT_LIST_ACTION_TYPE,
  DEFAULT_LIST_REFRESH_ACTION_TYPE,
  DEFAULT_LIST_ALTERNATIVE_ACTION_TYPE,
  DEFAULT_ADD_ACTION_TYPE,
  DEFAULT_UPDATE_ACTION_TYPE,
  DEFAULT_DETAIL_ACTION_TYPE,
  DEFAULT_DELETE_ACTION_TYPE,
];

const importNprogressActionAsyncThunkNames = [
  DEFAULT_IMPORT_FILE_ACTION_TYPE,
  DEFAULT_IMPORT_FILE_PROGRESS_ACTION_TYPE,
];

export const CONTACT_BASE_ACTION_TYPE = 'contact';
export const CONTACT_ASYNC_THUNK_TYPE = generateAsyncTypeByBaseActionType(
  CONTACT_BASE_ACTION_TYPE,
  defaultActionAsyncThunkName,
);

export const PRODUCT_BASE_ACTION_TYPE = 'product';
export const PRODUCT_ASYNC_THUNK_TYPE = generateAsyncTypeByBaseActionType(
  PRODUCT_BASE_ACTION_TYPE,
  defaultActionAsyncThunkName,
);

export const PRODUCT_CATEGORY_BASE_ACTION_TYPE = 'product-category';
export const PRODUCT_CATEGORY_ASYNC_THUNK_TYPE = generateAsyncTypeByBaseActionType(
  PRODUCT_CATEGORY_BASE_ACTION_TYPE,
  defaultActionAsyncThunkName,
);

export const ASSET_CATEGORY_BASE_ACTION_TYPE = 'asset-category';
export const ASSET_CATEGORY_ASYNC_THUNK_TYPE = generateAsyncTypeByBaseActionType(
  ASSET_CATEGORY_BASE_ACTION_TYPE,
  defaultActionAsyncThunkName,
);

export const ASSET_MANAGEMENT_BASE_ACTION_TYPE = 'asset-management';
export const ASSET_MANAGEMENT_ASYNC_THUNK_TYPE = generateAsyncTypeByBaseActionType(
  ASSET_MANAGEMENT_BASE_ACTION_TYPE,
  defaultActionAsyncThunkName,
);

export const MULTIPLE_BASE_TYPES_ASSET_SLICE = [
  ASSET_CATEGORY_BASE_ACTION_TYPE,
  ASSET_MANAGEMENT_BASE_ACTION_TYPE,
];

export const ACCOUNT_BASE_ACTION_TYPE = 'account';
export const ACCOUNT_ASYNC_THUNK_TYPE = generateAsyncTypeByBaseActionType(
  ACCOUNT_BASE_ACTION_TYPE,
  defaultActionAsyncThunkName,
);

export const ACCOUNT_CATEGORY_BASE_ACTION_TYPE = 'account-category';
export const ACCOUNT_CATEGORY_ASYNC_THUNK_TYPE = generateAsyncTypeByBaseActionType(
  ACCOUNT_CATEGORY_BASE_ACTION_TYPE,
  defaultActionAsyncThunkName,
);

export const MULTIPLE_BASE_TYPES_ACCOUNT_SLICE = [
  ACCOUNT_BASE_ACTION_TYPE,
  ACCOUNT_CATEGORY_BASE_ACTION_TYPE,
];

export const STOCK_ADJUSTMENT_BASE_ACTION_TYPE = 'stock-adjustment';
export const STOCK_ADJUSTMENT_ASYNC_THUNK_TYPE = generateAsyncTypeByBaseActionType(
  STOCK_ADJUSTMENT_BASE_ACTION_TYPE,
  defaultActionAsyncThunkName,
);

export const STOCK_TRANSFER_BASE_ACTION_TYPE = 'stock-transfer';
export const STOCK_TRANSFER_ASYNC_THUNK_TYPE = generateAsyncTypeByBaseActionType(
  STOCK_TRANSFER_BASE_ACTION_TYPE,
  defaultActionAsyncThunkName,
);

export const TRANSACTION_BASE_ACTION_TYPE = 'transaction';
export const TRANSACTION_ASYNC_THUNK_TYPE = generateAsyncTypeByBaseActionType(
  TRANSACTION_BASE_ACTION_TYPE,
  defaultActionAsyncThunkName,
);

export const PURCHASE_INVOICE_BASE_ACTION_TYPE = 'purchase-invoice';
export const PURCHASE_INVOICE_ASYNC_THUNK_TYPE = generateAsyncTypeByBaseActionType(
  PURCHASE_INVOICE_BASE_ACTION_TYPE,
  defaultActionAsyncThunkName,
  importNprogressActionAsyncThunkNames,
);

export const SALES_INVOICE_BASE_ACTION_TYPE = 'sales-invoice';
export const SALES_INVOICE_ASYNC_THUNK_TYPE = generateAsyncTypeByBaseActionType(
  SALES_INVOICE_BASE_ACTION_TYPE,
  defaultActionAsyncThunkName,
  importNprogressActionAsyncThunkNames,
);

export const EXPENSE_BASE_ACTION_TYPE = 'expense';
export const EXPENSE_ASYNC_THUNK_TYPE = generateAsyncTypeByBaseActionType(
  EXPENSE_BASE_ACTION_TYPE,
  defaultActionAsyncThunkName,
  importNprogressActionAsyncThunkNames,
);

export const JOURNAL_ENTRY_BASE_ACTION_TYPE = 'journal-entry';
export const JOURNAL_ENTRY_ASYNC_THUNK_TYPE = generateAsyncTypeByBaseActionType(
  JOURNAL_ENTRY_BASE_ACTION_TYPE,
  defaultActionAsyncThunkName,
  importNprogressActionAsyncThunkNames,
);
