import React, { PureComponent } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { Image } from 'mui-image';
import { PropTypes } from 'prop-types';

import imageDataListNotFound from '../../../assets/img/table/not-found-data-table.png';

import { styles } from './BoxTableNotFound.styles';

class BoxTableNotFound extends PureComponent {
  render() {
    const {
      classes,
      textNotFound,
      showImage,
      headerText,
      secondaryText,
      textButton,
      isExistButton,
      isUsingSingleText,
      isUsingHeaderNSecondaryText,
      buttonProps,
    } = this.props;

    return (
      <Box className={classes.boxContainerMain}>
        <Stack spacing={2} direction='column' justifyContent='center' alignItems='center'>
          {showImage ? (
            <Image src={imageDataListNotFound} duration={0} width={54} height={69} />
          ) : null}

          {isUsingHeaderNSecondaryText ? (
            <Stack spacing={0.5} maxWidth={'400px'} direction={'column'}>
              <Typography variant={'h6'} fontSize={'16px'}>
                {headerText}
              </Typography>
              <Typography variant={'body2'}>{secondaryText}</Typography>
            </Stack>
          ) : null}

          {isUsingSingleText ? <Typography>{textNotFound}</Typography> : null}

          {isExistButton ? (
            <Button
              className='primaryButton'
              variant='contained'
              onClick={this.props.handleClickedButton}
              {...buttonProps}
            >
              {textButton}
            </Button>
          ) : null}
        </Stack>
      </Box>
    );
  }
}

BoxTableNotFound.propsType = {
  textNotFound: PropTypes.string,
  textButton: PropTypes.string,
  isExistButton: PropTypes.bool,
  handleClickedButton: PropTypes.func.isRequired,
};

BoxTableNotFound.defaultProps = {
  textNotFound: 'Data not found!',
  showImage: true,
  headerText: '',
  secondaryText: '',
  textButton: 'Add Data',
  isExistButton: true,
  isUsingHeaderNSecondaryText: false,
  isUsingSingleText: true,
  buttonProps: {},
};

// adding style into component
const stylingBoxTableNotFound = withStyles(styles)(BoxTableNotFound);

export default stylingBoxTableNotFound;
