import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

import accountServices from '../../services/API/account/account.service';
import cashBankServices from '../../services/API/cash-bank/cash-bank.service';
import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import {
  REACT_APP_CASH_BANK_BASE_URL,
  REACT_APP_CASH_BANK_CASH_PAYMENT_DETAILS_TEMPLATE_URL,
  REACT_APP_CASH_BANK_CASH_RECEIPT_DETAILS_TEMPLATE_URL,
  REACT_APP_CASH_BANK_CASH_TRANSFER_DETAILS_TEMPLATE_URL,
} from '../../utils/configs/url.config';
import {
  LOCAL_STORAGE_CASH_BANK,
  LOCAL_STORAGE_CASH_BANK_ACCOUNT_TRANSACTION,
  LOCAL_STORAGE_CASH_BANK_CALCULATE,
  LOCAL_STORAGE_CASH_BANK_DETAIL_ACCOUNT_TRANSACTION,
  LOCAL_STORAGE_CASH_TRANSFER,
  LOCAL_STORAGE_PAYMENT_CASH,
  LOCAL_STORAGE_RECEIPT_CASH,
} from '../../utils/constants/storage.constant';
import {
  SLICE_NAME_DETAIL_CASH_BANK_CASH_PAYMENT,
  SLICE_NAME_DETAIL_CASH_BANK_CASH_RECEIPT,
  SLICE_NAME_DETAIL_CASH_BANK_CASH_TRANSFER,
  SLICE_NAME_TEMP_UPDATE_CASH_PAYMENT,
  SLICE_NAME_TEMP_UPDATE_CASH_RECEIPT,
  SLICE_NAME_TEMP_UPDATE_CASH_TRANSFER,
} from '../../utils/constants/store.constant';
import { DEFAULT_KEY_NAME_TRANSACTION_ID_API_REF } from '../../utils/default/object-keyname.default';
import {
  DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
  DEFAULT_SEARCH_FE_CASH_PAYMENT,
  DEFAULT_SEARCH_FE_CASH_RECEIPT_PARAMS,
  DEFAULT_SEARCH_FE_CASH_TRANSFER_PARAMS,
} from '../../utils/default/params.default';
import { DEFAULT_STATUS_PAGE_DETAIL_CASH_BANK_CASH_TRANSFER } from '../../utils/default/status-page.default';
import {
  DEFAULT_STATUS_PAGE_DETAIL_CASH_BANK_CASH_RECEIPT,
  DEFAULT_STATUS_PAGE_DETAIL_CASH_PAYMENT,
} from '../../utils/default/status-page.default';
import objHelper from '../../utils/helpers/object.helper';
import strHelp from '../../utils/helpers/string.helpers';
import {
  creatorAddActionWithPreviewButton,
  creatorAddActionWithPreviewButtonGenerateLink,
  creatorAddActionWithStoreDataOnSlice,
  creatorDeleteAction,
  creatorListAction,
  creatorListActionWithDynamicSliceName,
  creatorListRefreshAction,
} from '../creator-action/creator-action.helper';
import messageHelper from '../message/message.helper';

// calculate cash bank
export const getCalculateCashBank = createAsyncThunk(
  'cash-bank/cash-bank-calculate',
  async (paramsGetCalculateCashBank, thunkAPI) => {
    return creatorListAction(
      thunkAPI,
      paramsGetCalculateCashBank,
      cashBankServices.getCalculateCashBank,
      LOCAL_STORAGE_CASH_BANK_CALCULATE,
      'cashBankCalculate',
      '',
      'error.list-data.cash-bank-balance',
      DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
      [],
    );
  },
);

// handling list cash bank
export const getListCashBank = createAsyncThunk(
  'cash-bank/cash-bank-list',
  async ({ isRewriteAll = true, ...paramsGetListCashBank }, thunkAPI) => {
    try {
      const response = await cashBankServices.getListCashBank(paramsGetListCashBank);

      if (!response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(response);
      }

      let { data: cashBankData, sucess } = response.data;

      if (!sucess || !Array.isArray(cashBankData.data)) {
        cashBankData = {
          ...cashBankData,
          data: [],
        };
      }

      if (!isEmpty(paramsGetListCashBank)) {
        paramsGetListCashBank = objHelper.filterKeyObj(
          paramsGetListCashBank,
          [],
          ['account_code', 'account_name'],
        );

        paramsGetListCashBank = objHelper.changeSuffixKey(paramsGetListCashBank, 'search_', false);

        cashBankData = {
          ...cashBankData,
          ...paramsGetListCashBank,
        };
      }

      if (isRewriteAll) {
        LocalStorage.set(LOCAL_STORAGE_CASH_BANK, cashBankData);

        return { cashBankData };
      }

      const currentCashBankData = LocalStorage.get(LOCAL_STORAGE_CASH_BANK);

      let newListCashBankData = [];

      if (cashBankData && currentCashBankData) {
        if (cashBankData.data && currentCashBankData.data) {
          newListCashBankData = cashBankData.data.concat(currentCashBankData.data);
        }
      }

      const newCashBankData = Object.assign(cashBankData, { data: newListCashBankData });

      LocalStorage.set(LOCAL_STORAGE_CASH_BANK, newCashBankData);

      return { cashBankData: newCashBankData };
    } catch (error) {
      if (!error.response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(error.response);
      }

      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.list-data.cash-bank',
          'error.list-data.cash-bank',
          false,
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.list-data.cash-bank',
          false,
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// list cash bank when refresh data
export const getListCashBankRefresh = createAsyncThunk(
  'cash-bank/cash-bank-list-refresh',
  async (discardKeySearch = [], thunkAPI) => {
    const currentCashBankData = LocalStorage.get(LOCAL_STORAGE_CASH_BANK);

    // filtering data from prev search
    let filterDataSearch = objHelper.filterKeyObj(currentCashBankData, discardKeySearch, [
      'search_account_code',
      'search_account_name',
      'current_page',
      'per_page',
    ]);

    const filterDataNextSearch = objHelper.filterKeyObj(filterDataSearch, [
      'account_code',
      'account_name',
    ]);

    filterDataSearch = objHelper.changeSuffixKey(filterDataSearch, 'search_');

    filterDataSearch = objHelper.changeSuffixKey(filterDataSearch, 'current_');

    filterDataSearch = {
      ...filterDataSearch,
      paginate: filterDataSearch ? filterDataSearch.per_page : 20,
    };

    // get existed data only
    filterDataSearch = objHelper.filteringExistedValue(filterDataSearch);

    // removing unused key for search data
    filterDataSearch = objHelper.filterKeyObj(filterDataSearch, ['per_page']);

    try {
      const response = await cashBankServices.getListCashBank(filterDataSearch);

      if (!response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(response);
      }

      let { data: dataResponseCashBank, sucess } = response.data;

      if (!sucess || !Array.isArray(dataResponseCashBank.data)) {
        dataResponseCashBank = {
          ...dataResponseCashBank,
          data: [],
        };
      }

      // adding parameter search for next search
      const dataResWithSearch = Object.assign(dataResponseCashBank, { ...filterDataNextSearch });

      LocalStorage.set(LOCAL_STORAGE_CASH_BANK, dataResWithSearch);

      return { cashBankData: dataResWithSearch };
    } catch (error) {
      if (!error.response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(error.response);
      }

      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.list-data.cash-bank',
          'error.list-data.cash-bank',
          false,
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.list-data.cash-bank',
          false,
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// handling list account transaction
export const getListAccountTransaction = createAsyncThunk(
  'cash-bank/cash-bank-list/account-transacton-list',
  async ({ isRewriteAll = true, ...paramsGetListAccountTransaction }, thunkAPI) => {
    try {
      const response = await accountServices.getListAccountTransaction(
        paramsGetListAccountTransaction,
      );

      if (!response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(response);
      }

      let { data: accountTransactionData, sucess } = response.data;

      if (!sucess || !Array.isArray(accountTransactionData.data)) {
        accountTransactionData = {
          ...accountTransactionData,
          data: [],
        };
      }

      if (!isEmpty(paramsGetListAccountTransaction)) {
        paramsGetListAccountTransaction = objHelper.filterKeyObj(
          paramsGetListAccountTransaction,
          [],
          ['transaction_no', 'transaction_date', 'from_date', 'to_date'],
        );

        paramsGetListAccountTransaction = objHelper.changeSuffixKey(
          paramsGetListAccountTransaction,
          'search_',
          false,
        );

        accountTransactionData = {
          ...accountTransactionData,
          ...paramsGetListAccountTransaction,
        };
      }

      if (isRewriteAll) {
        LocalStorage.set(LOCAL_STORAGE_CASH_BANK_ACCOUNT_TRANSACTION, accountTransactionData);

        return { accountTransactionData };
      }

      const currentAccountTransactionData = LocalStorage.get(
        LOCAL_STORAGE_CASH_BANK_ACCOUNT_TRANSACTION,
      );

      let newListAccountTransactionData = [];

      if (accountTransactionData && currentAccountTransactionData) {
        if (accountTransactionData.data && currentAccountTransactionData.data) {
          newListAccountTransactionData = accountTransactionData.data.concat(
            currentAccountTransactionData.data,
          );
        }
      }

      const newAccountTransactionData = Object.assign(accountTransactionData, {
        data: newListAccountTransactionData,
      });

      LocalStorage.set(LOCAL_STORAGE_CASH_BANK_ACCOUNT_TRANSACTION, newAccountTransactionData);

      return { receiptCashData: newAccountTransactionData };
    } catch (error) {
      if (!error.response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(error.response);
      }

      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.list-data.account-transaction',
          'error.list-data.account-transaction',
          false,
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.list-data.account-transaction',
          false,
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// handling detail account transaction cash bank
export const getDetailAccountTransaction = createAsyncThunk(
  'cash-bank/cash-bank-list/account-transacton-detail',
  async ({ isRewriteAll = true, ...paramsGetDetailAccountTransaction }, thunkAPI) => {
    try {
      const response = await cashBankServices.getDetailAccountTransaction(
        paramsGetDetailAccountTransaction,
      );

      if (!response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(response);
      }

      let { data: detailAccountTransactionData, sucess } = response.data;

      if (!sucess || !Array.isArray(detailAccountTransactionData.data)) {
        detailAccountTransactionData = {
          ...detailAccountTransactionData,
          data: [],
        };
      }

      if (!isEmpty(paramsGetDetailAccountTransaction)) {
        paramsGetDetailAccountTransaction = objHelper.filterKeyObj(
          paramsGetDetailAccountTransaction,
          [],
          [
            'transaction_id',
            'transaction_no',
            'account_name',
            'account_code',
            'transaction_date',
            'transaction_description',
          ],
        );

        // paramsGetListAccountTransaction = objHelper.changeSuffixKey(
        //     paramsGetListAccountTransaction, 'search_',
        //     false,
        // )

        detailAccountTransactionData = {
          ...detailAccountTransactionData,
          ...paramsGetDetailAccountTransaction,
        };
      }

      if (isRewriteAll) {
        LocalStorage.set(
          LOCAL_STORAGE_CASH_BANK_DETAIL_ACCOUNT_TRANSACTION,
          detailAccountTransactionData,
        );

        return { detailAccountTransactionData };
      }

      const currentDetailAccountTransactionData = LocalStorage.get(
        LOCAL_STORAGE_CASH_BANK_DETAIL_ACCOUNT_TRANSACTION,
      );

      let newDetailAccountTransaction = [];

      if (detailAccountTransactionData && currentDetailAccountTransactionData) {
        if (detailAccountTransactionData.data && currentDetailAccountTransactionData.data) {
          newDetailAccountTransaction = detailAccountTransactionData.data.concat(
            currentDetailAccountTransactionData.data,
          );
        }
      }

      const newDetailAccountTransactionData = Object.assign(detailAccountTransactionData, {
        data: newDetailAccountTransaction,
      });

      LocalStorage.set(
        LOCAL_STORAGE_CASH_BANK_DETAIL_ACCOUNT_TRANSACTION,
        newDetailAccountTransactionData,
      );

      return { detailAccountTransactionData: newDetailAccountTransactionData };
    } catch (error) {
      if (!error.response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(error.response);
      }

      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.list-data.detail-account-transaction',
          'error.list-data.detail-account-transaction',
          false,
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.list-data.detail-account-transaction',
          false,
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

// handling list receipt cash
export const getListReceiptCash = createAsyncThunk(
  'cash-bank/receipt-cash-list',
  async (paramsGetListReceiptCash, thunkAPI) => {
    return creatorListAction(
      thunkAPI,
      paramsGetListReceiptCash,
      cashBankServices.getListReceiptCash,
      LOCAL_STORAGE_RECEIPT_CASH,
      'receiptCashData',
      'transaction_id',
      'error.list-data.receipt-cash',
    );
  },
);

// list receipt cash when refresh data
export const getListReceiptCashRefresh = createAsyncThunk(
  'cash-bank/receipt-cash-list-refresh',
  async (discardKeySearch = [], thunkAPI) => {
    return creatorListRefreshAction(
      thunkAPI,
      cashBankServices.getListReceiptCash,
      LOCAL_STORAGE_RECEIPT_CASH,
      'receiptCashData',
      discardKeySearch,
      DEFAULT_SEARCH_FE_CASH_RECEIPT_PARAMS,
      'error.list-data.receipt-cash',
    );
  },
);

// get detail receipt cash
export const getDetailCashReceipt = createAsyncThunk(
  'cash-bank/cash-receipt-detail',
  async (paramsGetDetailCashReceipt, thunkAPI) => {
    return await creatorListActionWithDynamicSliceName(
      thunkAPI,
      paramsGetDetailCashReceipt,
      cashBankServices.getDetailReceiptCash,
      '',
      SLICE_NAME_DETAIL_CASH_BANK_CASH_RECEIPT,
      'transaction_id',
      'error.detail.cash-receipt',
    );
  },
);

// adding receipt cash on company
export const addReceiptCash = createAsyncThunk(
  'cash-bank/receipt-cash-add',
  async (bodyAddReceiptCash, thunkAPI) => {
    const { contact_name } = bodyAddReceiptCash;

    return await creatorAddActionWithPreviewButton(
      thunkAPI,
      bodyAddReceiptCash,
      cashBankServices.addReceiptCash,
      'success.add.receipt-cash',
      'error.add.receipt-cash',
      {},
      { contact_name },
      'transaction_id',
      REACT_APP_CASH_BANK_BASE_URL,
      { statusPage: DEFAULT_STATUS_PAGE_DETAIL_CASH_BANK_CASH_RECEIPT },
    );
  },
);

// adding receipt cash where on version 2
export const addReceiptCashV2 = createAsyncThunk(
  'cash-bank/receipt-cash-add-version-2',
  async (bodyAddReceiptCash, thunkAPI) => {
    const { contact_name } = bodyAddReceiptCash;

    await creatorAddActionWithPreviewButtonGenerateLink({
      thunkAPI,
      bodyDataAction: bodyAddReceiptCash,
      serviceFunc: cashBankServices.addReceiptCash,
      successMessage: 'success.add.receipt-cash',
      errorMessage: 'error.add.receipt-cash',
      paramsTextSecondary: { contact_name },
      baseContentUrl: REACT_APP_CASH_BANK_BASE_URL,
      generateButtonLink: (responseResult) => {
        return strHelp.templateString(REACT_APP_CASH_BANK_CASH_RECEIPT_DETAILS_TEMPLATE_URL, {
          [DEFAULT_KEY_NAME_TRANSACTION_ID_API_REF]: objHelper.findAllValuesByKey(
            responseResult,
            DEFAULT_KEY_NAME_TRANSACTION_ID_API_REF,
          ),
        });
      },
    });
  },
);

export const updateReceiptCash = createAsyncThunk(
  'cash-bank/receipt-cash-update',
  async (bodyUpdateReceiptCash, thunkAPI) => {
    const { transaction_no } = bodyUpdateReceiptCash || {};

    return creatorAddActionWithStoreDataOnSlice(
      thunkAPI,
      bodyUpdateReceiptCash,
      cashBankServices.updateReceiptCash,
      SLICE_NAME_TEMP_UPDATE_CASH_RECEIPT,
      'success.update.receipt-cash',
      'error.update.receipt-cash',
      {},
      { transaction_no },
    );
  },
);

// deleting receipt cash of company
export const deleteReceiptCash = createAsyncThunk(
  'cash-bank/receipt-cash-delete',
  async (paramsDeleteReceiptCash, thunkAPI) => {
    const { transaction_no } = paramsDeleteReceiptCash;

    try {
      const response = await cashBankServices.deleteReceiptCash(paramsDeleteReceiptCash);

      if (!response) {
        return thunkAPI.rejectWithValue(response);
      }

      const { data } = response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          'success.delete.receipt-cash',
          'success.delete.receipt-cash',
          {},
          { transaction_no },
        );
      } else {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          responseMessage,
          'success.delete.receipt-cash',
          {},
          { transaction_no },
        );
      }

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      if (!error.response) {
        messageHelper.serverInternalError(thunkAPI.dispatch);

        return thunkAPI.rejectWithValue(error.response);
      }

      const { data } = error.response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.delete.receipt-cash',
          'error.delete.receipt-cash',
          false,
          {},
          { transaction_no },
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.delete.receipt-cash',
          false,
          {},
          { transaction_no },
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

export const getListPaymentCash = createAsyncThunk(
  'cash-bank/payment-cash-list',
  async (paramsGetListPaymentCash, thunkAPI) => {
    return creatorListAction(
      thunkAPI,
      paramsGetListPaymentCash,
      cashBankServices.getListPaymentCash,
      LOCAL_STORAGE_PAYMENT_CASH,
      'paymentCashData',
      'transaction_id',
      'error.list-data.payment-cash',
    );
  },
);

export const getListPaymentCashRefresh = createAsyncThunk(
  'cash-bank/payment-cash-list-refresh',
  async (discardKeySearch = [], thunkAPI) => {
    return creatorListRefreshAction(
      thunkAPI,
      cashBankServices.getListPaymentCash,
      LOCAL_STORAGE_PAYMENT_CASH,
      'paymentCashData',
      discardKeySearch,
      DEFAULT_SEARCH_FE_CASH_PAYMENT,
      'error.list-data.payment-cash',
    );
  },
);

// get detail cash payment with transaction_no
export const detailCashPayment = createAsyncThunk(
  'cash-bank/cash-payment-detail',
  async (paramsDetailCashPayment, thunkAPI) => {
    return await creatorListActionWithDynamicSliceName(
      thunkAPI,
      paramsDetailCashPayment,
      cashBankServices.getDetailPaymentCash,
      '',
      SLICE_NAME_DETAIL_CASH_BANK_CASH_PAYMENT,
      'transaction_id',
      'error.detail.cash-payment',
    );
  },
);

export const addCashPayment = createAsyncThunk(
  'cash-bank/cash-payment-add',
  async (bodyAddCashPayment, thunkAPI) => {
    return await creatorAddActionWithPreviewButton(
      thunkAPI,
      bodyAddCashPayment,
      cashBankServices.addCashPayment,
      'success.add.cash-payment',
      'error.add.cash-payment',
      {},
      {},
      'transaction_id',
      REACT_APP_CASH_BANK_BASE_URL,
      { statusPage: DEFAULT_STATUS_PAGE_DETAIL_CASH_PAYMENT },
    );
  },
);

// action create cash payment v2
export const addCashPaymentV2 = createAsyncThunk(
  'cash-bank/cash-payment-add-version-2',
  async (bodyAddCashPayment, thunkAPI) => {
    return await creatorAddActionWithPreviewButtonGenerateLink({
      thunkAPI,
      bodyDataAction: bodyAddCashPayment,
      serviceFunc: cashBankServices.addCashPayment,
      successMessage: 'success.add.cash-payment',
      errorMessage: 'error.add.cash-payment',
      baseContentUrl: REACT_APP_CASH_BANK_BASE_URL,
      generateButtonLink: (responseResult) => {
        return strHelp.templateString(REACT_APP_CASH_BANK_CASH_PAYMENT_DETAILS_TEMPLATE_URL, {
          [DEFAULT_KEY_NAME_TRANSACTION_ID_API_REF]: objHelper.findAllValuesByKey(
            responseResult,
            DEFAULT_KEY_NAME_TRANSACTION_ID_API_REF,
          ),
        });
      },
    });
  },
);

export const updateCashPayment = createAsyncThunk(
  'cash-bank/cash-payment-update',
  async (bodyUpdateCashPayment, thunkAPI) => {
    const { transaction_no } = bodyUpdateCashPayment || {};

    return creatorAddActionWithStoreDataOnSlice(
      thunkAPI,
      bodyUpdateCashPayment,
      cashBankServices.updateCashPayment,
      SLICE_NAME_TEMP_UPDATE_CASH_PAYMENT,
      'success.update.cash-payment',
      'error.update.cash-payment',
      {},
      { transaction_no },
    );
  },
);

export const deleteCashPayment = createAsyncThunk(
  'cash-bank/cash-payment-delete',
  async (paramsDeleteCashPayment, thunkAPI) => {
    const { transaction_no } = paramsDeleteCashPayment;

    try {
      const response = await cashBankServices.deleteCashPayment(paramsDeleteCashPayment);

      if (!response) {
        throw { response };
      }

      const { data } = response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          'success.delete.cash-payment',
          'success.delete.cash-payment',
          {},
          { transaction_no },
        );
      } else {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          responseMessage,
          'success.delete.cash-payment',
          {},
          { transaction_no },
        );
      }

      return thunkAPI.fulfillWithValue(data);
    } catch (error) {
      const { response } = error;

      if (!response) {
        messageHelper.failedMessage(thunkAPI.dispatch, 'error.internal-server-error', '', false);

        return thunkAPI.rejectWithValue(response);
      }

      const { data } = response;

      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          'error.delete.cash-payment',
          'error.delete.cash-payment',
          false,
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          'error.delete.cash-payment',
          false,
        );
      }

      return thunkAPI.rejectWithValue(data);
    }
  },
);

export const getListCashTransfer = createAsyncThunk(
  'cash-bank/cash-transfer-list',
  async (paramsGetListCashTransfer, thunkAPI) => {
    return creatorListAction(
      thunkAPI,
      paramsGetListCashTransfer,
      cashBankServices.getListCashTransfer,
      LOCAL_STORAGE_CASH_TRANSFER,
      'cashTransferData',
      'transaction_id',
      'error.list-data.cash-transfer',
    );
  },
);

export const getListCashTransferRefresh = createAsyncThunk(
  'cash-bank/cash-transfer-refresh',
  async (discardKeySearch = [], thunkAPI) => {
    return creatorListRefreshAction(
      thunkAPI,
      cashBankServices.getListCashTransfer,
      LOCAL_STORAGE_CASH_TRANSFER,
      'cashTransferData',
      discardKeySearch,
      DEFAULT_SEARCH_FE_CASH_TRANSFER_PARAMS,
      'error.list-data.cash-transfer',
    );
  },
);

// detail cash transfer
export const getDetailCashTransfer = createAsyncThunk(
  'cash-bank/cash-transfer-detail',
  async (paramsGetDetailCashTransfer, thunkAPI) => {
    return await creatorListActionWithDynamicSliceName(
      thunkAPI,
      paramsGetDetailCashTransfer,
      cashBankServices.getDetailCashTransfer,
      '',
      SLICE_NAME_DETAIL_CASH_BANK_CASH_TRANSFER,
      'transaction_id',
      'error.detail.cash-transfer',
    );
  },
);

export const addTransferCash = createAsyncThunk(
  'cash-bank/transfer-cash-add',
  async (bodyAddTransferCash, thunkAPI) => {
    return await creatorAddActionWithPreviewButton(
      thunkAPI,
      bodyAddTransferCash,
      cashBankServices.addTransferCash,
      'success.add.cash-transfer',
      'error.add.cash-transfer',
      {},
      {},
      'transaction_id',
      REACT_APP_CASH_BANK_BASE_URL,
      {
        statusPage: DEFAULT_STATUS_PAGE_DETAIL_CASH_BANK_CASH_TRANSFER,
      },
    );
  },
);

// add transfer cash version 2 where auto link on details new page
export const addTransferCashV2 = createAsyncThunk(
  'cash-bank/transfer-cash-add-version-2',
  async (bodyAddTransferCash, thunkAPI) => {
    return await creatorAddActionWithPreviewButtonGenerateLink({
      thunkAPI,
      bodyDataAction: bodyAddTransferCash,
      serviceFunc: cashBankServices.addTransferCash,
      successMessage: 'success.add.cash-transfer',
      errorMessage: 'error.add.cash-transfer',
      baseContentUrl: REACT_APP_CASH_BANK_BASE_URL,
      generateButtonLink: (responseResult) => {
        return strHelp.templateString(REACT_APP_CASH_BANK_CASH_TRANSFER_DETAILS_TEMPLATE_URL, {
          [DEFAULT_KEY_NAME_TRANSACTION_ID_API_REF]: objHelper.findAllValuesByKey(
            responseResult,
            DEFAULT_KEY_NAME_TRANSACTION_ID_API_REF,
          ),
        });
      },
    });
  },
);

export const updateCashTransfer = createAsyncThunk(
  'cash-bank/cash-transfer-update',
  async (bodyUpdateCashTransfer, thunkAPI) => {
    const { transaction_no } = bodyUpdateCashTransfer || {};

    return creatorAddActionWithStoreDataOnSlice(
      thunkAPI,
      bodyUpdateCashTransfer,
      cashBankServices.updateCashTransfer,
      SLICE_NAME_TEMP_UPDATE_CASH_TRANSFER,
      'success.update.cash-transfer',
      'error.update.cash-transfer',
      {},
      { transaction_no },
    );
  },
);

export const deleteCashTransfer = createAsyncThunk(
  'cash-bank/cash-transfer-delete',
  async (paramsDeleteCashTransfer, thunkAPI) => {
    const { transaction_no } = paramsDeleteCashTransfer;

    return creatorDeleteAction(
      thunkAPI,
      paramsDeleteCashTransfer,
      cashBankServices.deleteCashTransfer,
      'success.delete.cash-transfer',
      'error.delete.cash-transfer',
      {},
      { transaction_no },
    );
  },
);
