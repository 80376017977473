import { cloneDeep, isEmpty } from 'lodash';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import {
  REDUX_TOOLKIT_FULFILLED_STATUS_ACTION_TYPE,
  REDUX_TOOLKIT_PENDING_STATUS_ACTION_TYPE,
  REDUX_TOOLKIT_REJECTED_STATUS_ACTION_TYPE,
} from '../constants/action-type.constant';
import { STATUS_REQUEST_BASE_IDDLE } from '../constants/store.constant';
import { correspondPayloadActionWithPayloadStorageKeyname } from '../data/display/correspond-object';
import {
  DEFAULT_KEY_NAME_REQUEST_STATUS,
  DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL,
  DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING,
} from '../default/object-keyname.default';

import arrHelp from './array.helpers';
import objHelper from './object.helper';
import { promiseExecutionHelper } from './promise-execution.helper';
import strHelp from './string.helpers';

const ACTION_NAME_INDEX = 1;
const ACTION_BASE_TYPE_INDEX = 0;

/**
 * @param   { string }  actionType
 *      {baseType}/{actionName}/{actionStatus}
 *
 *  base type and action name called asyncThunkType
 */
function getActionTypeByIndex(actionType, index = 0) {
  if (!actionType || typeof actionType !== 'string') return '';

  return actionType.split('/')[index];
}

function getActionName(actionType) {
  return getActionTypeByIndex(actionType, ACTION_NAME_INDEX);
}

function getBaseActionType(actionType) {
  return getActionTypeByIndex(actionType, ACTION_BASE_TYPE_INDEX);
}

function getAsyncThunkType(actionType) {
  return strHelp.joinBy('/', getBaseActionType(actionType), getActionName(actionType));
}

function isActionStatusEqual({
  action,
  baseType = '',
  multipleBaseTypes = [],
  actionStatusType = '',
  addSlashSuffixOnBaseType = true,
  addSlashPrefixOnActionStatusType = true,
} = {}) {
  if (!actionStatusType) return false;

  actionStatusType = addSlashPrefixOnActionStatusType ? `/${actionStatusType}` : actionStatusType;

  const isEndStatusEqual = action.type.endsWith(actionStatusType);
  if (!baseType && isEmpty(multipleBaseTypes)) return isEndStatusEqual;

  if (baseType) {
    baseType = addSlashSuffixOnBaseType ? `${baseType}/` : baseType;

    return action.type.startsWith(baseType) && isEndStatusEqual;
  }

  multipleBaseTypes = addSlashPrefixOnActionStatusType
    ? multipleBaseTypes.map((baseType) => `${baseType}/`)
    : multipleBaseTypes;

  return multipleBaseTypes.some((baseType) => action.type.startsWith(baseType)) && isEndStatusEqual;
}

export function isRejectedAction({
  action,
  baseType = '',
  multipleBaseTypes = [],
  rejectedActionType = REDUX_TOOLKIT_REJECTED_STATUS_ACTION_TYPE,
} = {}) {
  return isActionStatusEqual({
    action,
    baseType,
    multipleBaseTypes,
    actionStatusType: rejectedActionType,
  });
}

export function isFulfilledAction({
  action,
  baseType = '',
  multipleBaseTypes = [],
  fulfilledActionType = REDUX_TOOLKIT_FULFILLED_STATUS_ACTION_TYPE,
} = {}) {
  return isActionStatusEqual({
    action,
    baseType,
    multipleBaseTypes,
    actionStatusType: fulfilledActionType,
  });
}

export function isPendingAction({
  action,
  baseType = '',
  multipleBaseTypes = [],
  pendingActionType = REDUX_TOOLKIT_PENDING_STATUS_ACTION_TYPE,
} = {}) {
  return isActionStatusEqual({
    action,
    baseType,
    multipleBaseTypes,
    actionStatusType: pendingActionType,
  });
}

function filterMultipleRequestStatus(
  currentMultipleRequestStatus,
  requestStatusAction,
  removeRequestStatusKeynames = [],
  subtituteRequestStatusKeyname = '',
) {
  if (isEmpty(removeRequestStatusKeynames) || !subtituteRequestStatusKeyname)
    return currentMultipleRequestStatus;

  return arrHelp.removeItemsAndSubtitue({
    arr: currentMultipleRequestStatus,
    itemsRemove: removeRequestStatusKeynames.map(
      (removeKeyname) => requestStatusAction[removeKeyname],
    ),
    itemsSubtitue: requestStatusAction[subtituteRequestStatusKeyname],
  });
}

/**
 *
 * usage for fulfilled action on builder
 *
 * @param {*} param0
 *
 *
 * @param   { array }       boundaryAsyncThunkType         sequence of type on async thunk
 *
 * @returns
 */
export function bodyBuilderFulfilledAction({
  state,
  action,
  keynameOfActionPayloadSliceName = 'sliceName',
  keynameOfActionPayloadRequestStatusSuccessName = 'requestStatusSuccessName',
  keynameOfStateRequestStatus = 'statusRequest',
  useMultipeStateRequestStatus = false,
  keynameOfStateMultipleRequestStatus = DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL,
  mappingAsyncThunkTypeWithActionProps = {},
  keynamePendingStatus = REDUX_TOOLKIT_PENDING_STATUS_ACTION_TYPE,
  keynameFulfilledStatus = REDUX_TOOLKIT_FULFILLED_STATUS_ACTION_TYPE,
  keynameRejectedStatus = REDUX_TOOLKIT_REJECTED_STATUS_ACTION_TYPE,
}) {
  const asyncThunkType = getAsyncThunkType(action.type);

  if (
    isEmpty(mappingAsyncThunkTypeWithActionProps) ||
    !Object.keys(mappingAsyncThunkTypeWithActionProps).includes(asyncThunkType) ||
    !isFulfilledAction({
      action,
      baseType: getBaseActionType(action.type),
    })
  )
    return;

  const {
    [keynameOfActionPayloadSliceName]: sliceName,
    [keynameOfActionPayloadRequestStatusSuccessName]: requestStatusSuccessName,
  } = action.payload;

  state[sliceName] = action.payload[sliceName];
  state[keynameOfStateRequestStatus] = action.payload[requestStatusSuccessName];

  if (useMultipeStateRequestStatus && asyncThunkType in mappingAsyncThunkTypeWithActionProps) {
    state[keynameOfStateMultipleRequestStatus] = filterMultipleRequestStatus(
      state[keynameOfStateMultipleRequestStatus],
      mappingAsyncThunkTypeWithActionProps[asyncThunkType],
      [keynamePendingStatus, keynameRejectedStatus],
      keynameFulfilledStatus,
    );
  }
}

/**
 *
 * @param {*} param0
 *
 *
 * @param       { object } mappingAsyncThunkTypeWithActionStatus
 *      key is for async thunk type, value for default request status pending on action
 *      default usage where request status pending is not includes in action.meta.arg
 *
 * @returns
 */
export function bodyBuilderPendingAction({
  state,
  action,
  keynameOfActionMetaRequestStatusPending = DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING,
  keynameOfStateRequestStatus = 'statusRequest',
  useMultipeStateRequestStatus = false,
  keynameOfStateMultipleRequestStatus = DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL,
  mappingAsyncThunkTypeWithActionProps = {},
  keynamePendingStatus = REDUX_TOOLKIT_PENDING_STATUS_ACTION_TYPE,
  keynameFulfilledStatus = REDUX_TOOLKIT_FULFILLED_STATUS_ACTION_TYPE,
  keynameRejectedStatus = REDUX_TOOLKIT_REJECTED_STATUS_ACTION_TYPE,
}) {
  const asyncThunkType = getAsyncThunkType(action.type);

  if (
    isEmpty(mappingAsyncThunkTypeWithActionProps) ||
    !Object.keys(mappingAsyncThunkTypeWithActionProps).includes(asyncThunkType) ||
    !isPendingAction({
      action,
      baseType: getBaseActionType(action.type),
    })
  )
    return;

  state[keynameOfStateRequestStatus] =
    action.meta && action.meta.arg && action.meta.arg[keynameOfActionMetaRequestStatusPending]
      ? action.meta.arg[keynameOfActionMetaRequestStatusPending]
      : mappingAsyncThunkTypeWithActionProps[asyncThunkType][keynamePendingStatus] || '';

  if (useMultipeStateRequestStatus && asyncThunkType in mappingAsyncThunkTypeWithActionProps) {
    state[keynameOfStateMultipleRequestStatus] = filterMultipleRequestStatus(
      state[keynameOfStateMultipleRequestStatus],
      mappingAsyncThunkTypeWithActionProps[asyncThunkType],
      [keynameFulfilledStatus, keynameRejectedStatus],
      keynamePendingStatus,
    );
  }
}

/**
 *
 * @param {*} param0
 *
 *
 * @param   { object }      mappingAsyncThunkTypeWithActionPropsDefault
 *
 *      where mapping on action name with object for sliceName and requestStatus
 *      @example
 *          {
 *              testAsyncThunkType: {
 *                  sliceName: 'testSliceName',
 *                  requestStatus: 'reject-request-status',
 *              }
 *          }
 *
 *
 * @returns
 *
 */
export function bodyBuilderRejectedAction({
  state,
  action,
  keynameOfActionPayloadSliceName = 'sliceName',
  keynameOfActionPayloadSearchParams = 'searchParams',
  keynameOfActionPayloadRequestStatusRejectedName = 'requestStatusFailedName',
  keynameOfStateRequestStatus = 'statusRequest',
  useMultipeStateRequestStatus = false,
  keynameOfStateMultipleRequestStatus = DEFAULT_KEY_NAME_REQUEST_STATUS_PARALLEL,
  mappingAsyncThunkTypeWithActionProps = {},
  keynamePendingStatus = REDUX_TOOLKIT_PENDING_STATUS_ACTION_TYPE,
  keynameFulfilledStatus = REDUX_TOOLKIT_FULFILLED_STATUS_ACTION_TYPE,
  keynameRejectedStatus = REDUX_TOOLKIT_REJECTED_STATUS_ACTION_TYPE,
}) {
  const asyncThunkType = getAsyncThunkType(action.type);

  if (
    isEmpty(mappingAsyncThunkTypeWithActionProps) ||
    !Object.keys(mappingAsyncThunkTypeWithActionProps).includes(asyncThunkType) ||
    !isRejectedAction({
      action,
      baseType: getBaseActionType(action.type),
    })
  )
    return;

  const { sliceName: sliceNameDefault, [keynameRejectedStatus]: requestStatusDefault } =
    mappingAsyncThunkTypeWithActionProps[asyncThunkType] || {
      sliceName: null,
      [keynameRejectedStatus]: null,
    };

  if (!requestStatusDefault || !sliceNameDefault) return;

  const {
    [keynameOfActionPayloadSliceName]: sliceName = sliceNameDefault,
    [keynameOfActionPayloadRequestStatusRejectedName]: requestStatus = requestStatusDefault,
    [keynameOfActionPayloadSearchParams]: searchParams,
  } = action.payload || {};

  state[sliceName] = searchParams;
  state[keynameOfStateRequestStatus] = requestStatus;

  if (useMultipeStateRequestStatus && asyncThunkType in mappingAsyncThunkTypeWithActionProps) {
    state[keynameOfStateMultipleRequestStatus] = filterMultipleRequestStatus(
      state[keynameOfStateMultipleRequestStatus],
      mappingAsyncThunkTypeWithActionProps[asyncThunkType],
      [keynamePendingStatus, keynameFulfilledStatus],
      keynameRejectedStatus,
    );
  }
}

/**
 *
 * usage generate async thunk type each base action
 * where generate object that each key is action type
 * and each value is combine base action and action type
 *
 * @param   { string }  baseActionType
 * @param   { array }   defaultActionTypes          default actions where included
 *  list, listAlternative, listRefresh, detail, update, add, delete
 * @param   { array }   additionalActionTypes       new additional default action types
 *
 * @returns
 */
export function generateAsyncTypeByBaseActionType(
  baseActionType,
  defaultActionTypes = [],
  additionalActionTypes = [],
) {
  const actionTypes = defaultActionTypes.concat(additionalActionTypes);

  if (isEmpty(actionTypes) || !Array.isArray(actionTypes) || !baseActionType) return {};

  return actionTypes.reduce((res, currActionType) => {
    return {
      ...res,
      [currActionType]: `${baseActionType}/${currActionType}`,
    };
  }, {});
}

export function generatePayloadAction(
  requestStatusPending,
  requestStatusSuccess,
  requestStatusFailed,
  slicePayloadKeyname = '',
  localStorageName = '',
  paginatePageParams = {},
) {
  return {
    requestStatusPending,
    requestStatusSuccess,
    requestStatusFailed,
    slicePayloadKeyname,
    localStorageName,
    paginatePageParams,
  };
}

function generatePayloadStorage(sliceName, localStorageName) {
  return {
    sliceName,
    localStorageName,
  };
}

/**
 * usage for link key name from payload action to payload storage
 *
 * @param       { object }      payloadAction       target payload action
 * @param       { object }      linkedKeyname       where correspond keyname target to changes
 *
 */
function linkedPayloadAction2PayloadStorage(
  payloadAction,
  linkedKeyname = correspondPayloadActionWithPayloadStorageKeyname,
) {
  if (isEmpty(payloadAction) || isEmpty(linkedKeyname)) return payloadAction;

  return objHelper.changeKeyNameWithCorrespondKeyTarget({
    obj: payloadAction,
    correspondObj: linkedKeyname,
  });
}

/**
 *
 * @param { object } param0     object as function params
 *
 * @param   { object }      state                           state of slice
 * @param   { object }      action                          action state
 * @param   { string }      requestStatusKeyname            keyname of request status
 * @param   { string }      subtituteRequestStatusValue     substitute request status after clear
 * @returns
 */
function clearSliceItem({
  state,
  action,
  requestStatusKeyname = DEFAULT_KEY_NAME_REQUEST_STATUS,
  subtituteRequestStatusValue = STATUS_REQUEST_BASE_IDDLE,
} = {}) {
  const { sliceName = '', localStorageName = '' } = action.payload || {};

  if (localStorageName && LocalStorage.get(localStorageName)) LocalStorage.remove(localStorageName);

  if (!sliceName) return state;

  return {
    ...state,
    [requestStatusKeyname]: subtituteRequestStatusValue,
    [sliceName]: null,
  };
}

/**
 *
 * @param   {*}     state       state from callback of reducer
 * @param   {*}     action      callback reducer action
 *
 * where payload includes
 *
 *  sliceName
 *  localStorageName
 *  filterParams
 *  listDataKeyname
 *
 * @returns
 */
function filterDataSliceItemByKeyname(state, action) {
  const { sliceName, localStorageName, filterParams, listDataKeyname = 'data' } = action.payload;

  if (!sliceName || !listDataKeyname || isEmpty(filterParams)) return state;

  const oldStorage = LocalStorage.get(localStorageName);
  if (
    isEmpty(oldStorage) ||
    isEmpty(oldStorage[listDataKeyname]) ||
    !Array.isArray(oldStorage[listDataKeyname])
  )
    return state;

  return {
    ...state,
    [sliceName]: {
      ...state[sliceName],
      [listDataKeyname]: arrHelp.filterMultipleObjWithObjProps({
        arr: oldStorage[listDataKeyname],
        objProps: filterParams,
      }),
    },
  };
}

/**
 *
 * @param { any } initialState
 * @param { object } sliceNamesOnLocalStorageNames      here for key is slice name and value as localstorage name
 * @returns
 */
function commonSliceReducers(
  initialState,
  sliceNamesOnLocalStorageNames,
  requestStatusKeyname = DEFAULT_KEY_NAME_REQUEST_STATUS,
) {
  return {
    clearSliceItem: (state, action) => {
      return clearSliceItem({
        state,
        action,
        requestStatusKeyname,
      });
    },
    reInitializeStateAndLocalStorage: (state) => {
      let cloneInitialState = cloneDeep(initialState);

      if (!isEmpty(sliceNamesOnLocalStorageNames)) {
        const sliceNames = Object.keys(sliceNamesOnLocalStorageNames);

        // remove correspond localstorage and slice name
        promiseExecutionHelper.sequentialRunnerPromises(
          promiseExecutionHelper.transform2executablePromise({
            actions: Array(sliceNames.length).fill(LocalStorage.remove),
            params: Object.values(sliceNamesOnLocalStorageNames),
          }),
        );

        // re initialize by null when already on local storage
        cloneInitialState = {
          ...initialState,
          ...sliceNames.reduce((res, curr) => {
            return {
              ...res,
              [curr]: null,
            };
          }, {}),
        };
      }

      state = cloneInitialState;

      return state;
    },
    resetState: (state) => {
      state = initialState;

      return state;
    },
  };
}

function generateRequestStatus(
  mainRequestStatus,
  requestStatuses = [
    REDUX_TOOLKIT_PENDING_STATUS_ACTION_TYPE,
    REDUX_TOOLKIT_FULFILLED_STATUS_ACTION_TYPE,
    REDUX_TOOLKIT_REJECTED_STATUS_ACTION_TYPE,
  ],
) {
  if (!mainRequestStatus || isEmpty(requestStatuses)) return {};

  return requestStatuses.reduce((result, requestStatus) => {
    return {
      ...result,
      [requestStatus]: strHelp.templateString(
        '{main}-{secondary}',
        {
          main: mainRequestStatus,
          secondary: requestStatus,
        },
        true,
      ),
    };
  }, {});
}

/**
 *
 * @param { object } param0   object as function params
 *
 * @param {} builder                                        callback function from extraReducers
 * @param { array } baseTypesRegistered                     base types that registered for detect request status action
 * @param { object } mappingAsyncThunkTypeWithActionProps   relation each async thunk action with actions type
 *
 * @returns
 */
function createMatcherEachRequestStatusAction({
  builder = null,
  baseTypesRegistered = [],
  mappingAsyncThunkTypeWithActionProps = {},
  bodyBuilderProps = {},
} = {}) {
  if (!builder || isEmpty(baseTypesRegistered) || isEmpty(mappingAsyncThunkTypeWithActionProps)) {
    return [];
  }

  return [
    builder.addMatcher(
      (action) => isFulfilledAction({ action, multipleBaseTypes: baseTypesRegistered }),
      (state, action) => {
        bodyBuilderFulfilledAction({
          state,
          action,
          mappingAsyncThunkTypeWithActionProps,
          ...bodyBuilderProps,
        });
      },
    ),
    builder.addMatcher(
      (action) => isPendingAction({ action, multipleBaseTypes: baseTypesRegistered }),
      (state, action) => {
        bodyBuilderPendingAction({
          state,
          action,
          mappingAsyncThunkTypeWithActionProps,
          ...bodyBuilderProps,
        });
      },
    ),
    builder.addMatcher(
      (action) => isRejectedAction({ action, multipleBaseTypes: baseTypesRegistered }),
      (state, action) => {
        bodyBuilderRejectedAction({
          state,
          action,
          mappingAsyncThunkTypeWithActionProps,
          ...bodyBuilderProps,
        });
      },
    ),
  ];
}

export const sliceReduceHelper = {
  isPendingAction,
  isFulfilledAction,
  isRejectedAction,
  generatePayloadStorage,
  linkedPayloadAction2PayloadStorage,
  clearSliceItem,
  filterDataSliceItemByKeyname,
  commonSliceReducers,
  generateRequestStatus,
  createMatcherEachRequestStatusAction,
};
