import {
  DEFAULT_KEY_NAME_USER_ID_API_REF,
  DEFAULT_KEY_NAME_USER_ID_URL_PARAM,
  SLICE_NAME_KEY_NAME,
  SLICE_PAYLOAD_KEYNAME_KEY_NAME,
} from '../../default/object-keyname.default';

// setting user details menu
export const correspondURLparamsSettingUserDetails = {
  [DEFAULT_KEY_NAME_USER_ID_URL_PARAM]: [DEFAULT_KEY_NAME_USER_ID_API_REF],
};

// linked payload action to payload storage
export const correspondPayloadActionWithPayloadStorageKeyname = {
  [SLICE_PAYLOAD_KEYNAME_KEY_NAME]: SLICE_NAME_KEY_NAME,
};
