import axios from 'axios';
import { isEmpty } from 'lodash';

import {
  REACT_APP_API_URL_CASH_BANK,
  REACT_APP_API_URL_CASH_BANK_CALCULATE,
  REACT_APP_API_URL_CASH_BANK_TRANSACTION_ACCOUNT,
  REACT_APP_API_URL_CASH_TRANSFER,
  REACT_APP_API_URL_CASH_TRANSFER_DETAIL,
  REACT_APP_API_URL_PAYMENT_CASH,
  REACT_APP_API_URL_PAYMENT_CASH_DETAIL,
  REACT_APP_API_URL_RECEIPT_CASH,
  REACT_APP_API_URL_RECEIPT_CASH_DETAIL,
} from '../../../utils/configs/api.config';
import { DEFAULT_EMPTY_CALCULATIONS_NUMBER } from '../../../utils/data/default/number.default';
import { allowKeysCashTransferData } from '../../../utils/data/display/initial-transfer-cash';
import {
  ALLOW_PARAMS_CASH_BANK_ACCOUNT_TRANSACTION,
  DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS,
  DEFAULT_CASH_BANK_INDEX_LIST_PARAMS,
  DEFAULT_CASH_PAYMENT_PARAMS,
} from '../../../utils/default/params.default';
import arrHelp from '../../../utils/helpers/array.helpers';
import { authHeader } from '../../../utils/helpers/auth-header.helpers';
import formatHelp from '../../../utils/helpers/format.helpers';
import objHelper from '../../../utils/helpers/object.helper';
import {
  creatorAddService,
  creatorDeleteService,
  creatorDetailServiceV2,
  creatorListService,
  creatorUpdateService,
} from '../creator-services/creator-services.helper';

// calculate cash bank
const getCalculateCashBank = async (paramsGetCalculateCashBank) => {
  return creatorListService(
    REACT_APP_API_URL_CASH_BANK_CALCULATE,
    paramsGetCalculateCashBank,
    [],
    {},
  );
};

// list cash bank
const getListCashBank = async (paramsGetListCashBank) => {
  paramsGetListCashBank = objHelper.changeFormatValueV2(
    paramsGetListCashBank,
    DEFAULT_ACCOUNT_CHANGE_FORMAT_PARAMS,
    null,
    formatHelp.changeFormatAccountName,
    true,
  );

  return creatorListService(
    REACT_APP_API_URL_CASH_BANK,
    paramsGetListCashBank,
    DEFAULT_CASH_BANK_INDEX_LIST_PARAMS,
  );
};

// list account transaction
const getListAccountTransaction = async (paramsGetListAccountTransaction) => {
  return await creatorListService(
    REACT_APP_API_URL_CASH_BANK_TRANSACTION_ACCOUNT,
    paramsGetListAccountTransaction,
    ALLOW_PARAMS_CASH_BANK_ACCOUNT_TRANSACTION,
  );
};

// detail account transaction cash bank
const getDetailAccountTransaction = async (paramsGetDetailAccountTransaction) => {
  const { Authorization } = authHeader();

  if (!Authorization) {
    return null;
  }

  paramsGetDetailAccountTransaction = objHelper.filterKeyObj(
    paramsGetDetailAccountTransaction,
    [],
    [
      'transaction_id',
      'transaction_no',
      'account_name',
      'account_code',
      'transaction_date',
      'transaction_description',
    ],
  );

  // adding default params
  paramsGetDetailAccountTransaction = {
    ...paramsGetDetailAccountTransaction,
  };

  return await axios({
    method: 'GET',
    url: `${REACT_APP_API_URL_CASH_BANK}/transaction`,
    headers: {
      Authorization,
    },
    params: {
      ...paramsGetDetailAccountTransaction,
    },
  });
};

const getListReceiptCash = async (paramsGetListReceiptCash) => {
  return creatorListService(REACT_APP_API_URL_RECEIPT_CASH, paramsGetListReceiptCash, [
    'page',
    'paginate',
    'transaction_no',
    'from_date',
    'to_date',
  ]);
};

// service cash receipt details
const getDetailReceiptCash = async (paramsGetDetailReceiptCash) => {
  return await creatorDetailServiceV2(
    REACT_APP_API_URL_RECEIPT_CASH_DETAIL,
    paramsGetDetailReceiptCash,
  );
};

/**
 *
 * @param { object } bodyData, data body of update or add cash receipt, this data for sending into backend
 * @returns
 * data generalize for action update or add in BE
 *
 */
function generalizeDataAddUpdateCashReceipt(bodyData) {
  bodyData = objHelper.filterKeyObj(
    bodyData,
    [],
    [
      'transaction_date',
      'transaction_no',
      'transaction_description',
      'contact_id',
      'contact_name',
      'destination_account',
      'account',
    ],
  );

  if (Array.isArray(bodyData.account) && !isEmpty(bodyData.account)) {
    bodyData.account = bodyData.account.map((bodyAccount) => {
      const filteringKeyBodyAccount = objHelper.filterKeyObj(
        bodyAccount,
        [],
        [
          'account_id',
          'account_category_id',
          'category_name',
          'account_name',
          'account_code',
          'default_category_name',
          'category_code',
          'account_balance',
          'account_normal_balance',
          'account_description',
        ],
      );

      const isExistedValueInRow = objHelper.filteringExistedValue(
        filteringKeyBodyAccount,
        [],
        true,
        DEFAULT_EMPTY_CALCULATIONS_NUMBER,
      );

      if (!isEmpty(isExistedValueInRow)) {
        return filteringKeyBodyAccount;
      }
    });

    bodyData.account = arrHelp.filteringExistedValue(bodyData.account);
  }

  return (bodyData = objHelper.filteringExistedValue(bodyData));
}

// adding receipt cash company
const addReceiptCash = async (bodyAddReceiptCash) => {
  return creatorAddService(
    REACT_APP_API_URL_RECEIPT_CASH,
    bodyAddReceiptCash,
    generalizeDataAddUpdateCashReceipt,
  );
};

const updateReceiptCash = async (bodyUpdateReceiptCash) => {
  const { transaction_id } = bodyUpdateReceiptCash;

  return creatorUpdateService(
    transaction_id,
    REACT_APP_API_URL_RECEIPT_CASH,
    bodyUpdateReceiptCash,
    generalizeDataAddUpdateCashReceipt,
  );
};

// delete receipt cash company
const deleteReceiptCash = async (paramsDeleteReceiptCash) => {
  const { Authorization } = authHeader();

  const { transaction_id } = paramsDeleteReceiptCash;

  if (!Authorization || !transaction_id) {
    return null;
  }

  return await axios({
    method: 'DELETE',
    url: `${REACT_APP_API_URL_RECEIPT_CASH}/${transaction_id}`,
    headers: {
      Authorization,
    },
  });
};

// list payment cash on company
const getListPaymentCash = async (paramsGetListPaymentCash) => {
  return await creatorListService(
    REACT_APP_API_URL_PAYMENT_CASH,
    paramsGetListPaymentCash,
    DEFAULT_CASH_PAYMENT_PARAMS,
  );
};

// service cash payment details
const getDetailPaymentCash = async (paramsGetDetailPaymentCash) => {
  return await creatorDetailServiceV2(
    REACT_APP_API_URL_PAYMENT_CASH_DETAIL,
    paramsGetDetailPaymentCash,
  );
};

/**
 *
 * @param { object } bodyData, data body of update or add cash payment, this data for sending into backed
 * @returns
 * data generalize for action update or add in BE
 *
 */
function generalizeDataAddUpdateCashPayment(bodyData) {
  bodyData = objHelper.filterKeyObj(
    bodyData,
    [],
    [
      'transaction_date',
      'transaction_description',
      'contact_id',
      'contact_name',
      'origin_account',
      'reference_number',
      'account',
    ],
  );

  if (Array.isArray(bodyData.account) && !isEmpty(bodyData.account)) {
    bodyData.account = bodyData.account.map((bodyAccount) => {
      const filteringKeyBodyAccount = objHelper.filterKeyObj(
        bodyAccount,
        [],
        ['account_id', 'account_balance', 'account_description'],
      );

      const isExistedValueInRow = objHelper.filteringExistedValue(
        filteringKeyBodyAccount,
        [],
        true,
        DEFAULT_EMPTY_CALCULATIONS_NUMBER,
      );

      if (!isEmpty(isExistedValueInRow)) {
        return filteringKeyBodyAccount;
      }
    });

    bodyData.account = arrHelp.filteringExistedValue(bodyData.account);
  }

  return (bodyData = objHelper.filteringExistedValue(bodyData));
}

const addCashPayment = async (bodyAddCashPayment) => {
  const { Authorization } = authHeader();

  if (!Authorization) {
    return null;
  }

  bodyAddCashPayment = generalizeDataAddUpdateCashPayment(bodyAddCashPayment);

  return await axios({
    method: 'POST',
    url: REACT_APP_API_URL_PAYMENT_CASH,
    headers: {
      Authorization,
    },
    data: {
      ...bodyAddCashPayment,
    },
  });
};

const updateCashPayment = async (bodyUpdateCashPayment) => {
  const { Authorization } = authHeader();

  const { transaction_id } = bodyUpdateCashPayment;

  if (!Authorization || !transaction_id) {
    return null;
  }

  bodyUpdateCashPayment = generalizeDataAddUpdateCashPayment(bodyUpdateCashPayment);

  return await axios({
    method: 'PUT',
    url: `${REACT_APP_API_URL_PAYMENT_CASH}/${transaction_id}`,
    headers: {
      Authorization,
    },
    data: {
      ...bodyUpdateCashPayment,
    },
  });
};

const deleteCashPayment = async (paramsDeleteCashPayment) => {
  const { Authorization } = authHeader();

  const { transaction_id } = paramsDeleteCashPayment;

  if (!Authorization || !transaction_id) {
    return null;
  }

  return await axios({
    method: 'DELETE',
    url: `${REACT_APP_API_URL_PAYMENT_CASH}/${transaction_id}`,
    headers: {
      Authorization,
    },
  });
};

const getListCashTransfer = async (paramsGetListCashTransfer) => {
  return await creatorListService(
    REACT_APP_API_URL_CASH_TRANSFER,
    paramsGetListCashTransfer,
    DEFAULT_CASH_PAYMENT_PARAMS,
  );
};

// service cash transfer detail
const getDetailCashTransfer = async (paramsGetDetailCashTransfer) => {
  return await creatorDetailServiceV2(
    REACT_APP_API_URL_CASH_TRANSFER_DETAIL,
    paramsGetDetailCashTransfer,
  );
};

/**
 *
 * @param { object } bodyTransferCashData, object data for generalize into requirement BE
 * @returns
 *      data that has been generalize into BE requirement
 */
function generalizeDataAddUpdateCashTransfer(bodyTransferCashData) {
  bodyTransferCashData = objHelper.filterKeyObj(
    bodyTransferCashData,
    [],
    allowKeysCashTransferData,
  );

  if (bodyTransferCashData) {
    bodyTransferCashData = objHelper.filteringExistedValue(bodyTransferCashData);
  }

  return bodyTransferCashData;
}

const addTransferCash = async (bodyAddTransferCash) => {
  const { Authorization } = authHeader();

  if (!Authorization) {
    return null;
  }

  bodyAddTransferCash = objHelper.filterKeyObj(bodyAddTransferCash, [], allowKeysCashTransferData);

  bodyAddTransferCash = objHelper.filteringExistedValue(bodyAddTransferCash);

  return await axios({
    method: 'POST',
    url: REACT_APP_API_URL_CASH_TRANSFER,
    headers: {
      Authorization,
    },
    data: {
      ...bodyAddTransferCash,
    },
  });
};

const updateCashTransfer = async (bodyUpdateCashTransfer) => {
  const { transaction_id } = bodyUpdateCashTransfer;

  return await creatorUpdateService(
    transaction_id,
    REACT_APP_API_URL_CASH_TRANSFER,
    bodyUpdateCashTransfer,
    generalizeDataAddUpdateCashTransfer,
  );
};

const deleteCashTransfer = async (paramsDeleteCashTransfer) => {
  const { transaction_id } = paramsDeleteCashTransfer;

  return await creatorDeleteService(transaction_id, REACT_APP_API_URL_CASH_TRANSFER);
};

const cashBankServices = {
  getCalculateCashBank,
  getListCashBank,
  getListAccountTransaction,
  getDetailAccountTransaction,
  getListReceiptCash,
  addReceiptCash,
  updateReceiptCash,
  deleteReceiptCash,
  getListPaymentCash,
  addCashPayment,
  updateCashPayment,
  deleteCashPayment,
  getListCashTransfer,
  addTransferCash,
  updateCashTransfer,
  deleteCashTransfer,
  getDetailCashTransfer,
  getDetailReceiptCash,
  getDetailPaymentCash,
};

export default cashBankServices;
