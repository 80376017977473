import { isEmpty } from 'lodash';
import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';

import { DEFAULT_DISCOUNT_PERCENTAGE_TYPE } from '../../default/discount-type.default';
import {
  DEFAULT_COMPARISON_VALUE_NUMBER,
  DEFAULT_EMPTY_CALCULATIONS_NUMBER,
} from '../../default/number.default';
import {
  DEFAULT_KEY_NAME_ACCOUNTING_PERIODE,
  DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF,
  DEFAULT_KEY_NAME_FILE_PROPERTIES,
  DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF,
  DEFAULT_KEY_NAME_QUOTATION_REQUEST_PRODUCT_REF_API,
  DEFAULT_KEY_NAME_UUID_API_REF,
  DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF,
} from '../../default/object-keyname.default';
import { DEFAULT_TRANSACTION_NUMBER_PURCHASE_ORDER } from '../../default/transaction-number.default';
import { hasImplementedUnitProductUnit } from '../../default/unit-product.default';
import arrHelp from '../../helpers/array.helpers';
import formatHelp from '../../helpers/format.helpers';
import objHelper from '../../helpers/object.helper';

import { getAccountingPeriodCompareWithDocumentTransactionDate } from './initial-accounting-period';
import {
  allowKeysProductPurchaseOrderData,
  allowKeysPurchaseOrderData,
  initialProductData,
  initialProductValidation,
  initialPurchaseData,
  initialPurchaseValidation,
} from './initial-data-purchase-order';
import { purchaseQuotationRequestCalculationNumberKeyNames } from './initial-data-quotation-request';

/**
 *
 * @param { number } amount amount data and validation for generate
 * @param { string } customDataKey name key for returning data
 * @param { string } customValidationKey name key for returning validation
 * @returns
 *      generated data and validation purchase for purchase order
 */
export const generateDataNValidationAddPurchasePO = (
  amount = 1,
  customDataKey = 'purchaseData',
  customValidationKey = 'purchaseValidation',
) => {
  const usedID = arrHelp.generateArrWithFunc(amount, uuidV4);

  const addPurchaseData = usedID.map((id) => {
    return initialPurchaseData('', id);
  });

  const addPurchaseValidation = usedID.map((id) => {
    return initialPurchaseValidation('', id);
  });

  return {
    [customDataKey]: addPurchaseData,
    [customValidationKey]: addPurchaseValidation,
  };
};

/**
 *
 * @param { number } amount                 amount data generate
 * @param { string } customDataKey          name key return for data
 * @param { object } companyUserData        data user company
 * @param { string } customValidationKey    name key return for validation
 * @returns
 *     generated data and validation for purchase order (enhancement)
 */
export const generateDataNValidationAddPurchaseOrderV2 = ({
  amount = 1,
  companyUserData = {},
  customDataKey = 'purchaseData',
  customValidationKey = 'purchaseValidation',
}) => {
  const usedID = arrHelp.generateArrWithFunc(amount, uuidV4);

  const addPurchaseData = usedID.map((id) => {
    return {
      ...initialPurchaseData('', id),
      accounting_period: companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIODE_API_REF],
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
        }),
    };
  });

  const addPurchaseValidation = usedID.map((id) => {
    return initialPurchaseValidation('', id);
  });

  return {
    [customDataKey]: addPurchaseData,
    [customValidationKey]: addPurchaseValidation,
  };
};

/**
 *
 * @param { number } amount amount data and validation for generate
 * @param { string } customDataKey name key for returning data
 * @param { string } customValidationKey name key for validation data
 * @returns
 *      generated data and validation for adding product in purchase order
 */
export const generateDataNValidationAddProductPO = (
  amount = 2,
  customDataKey = 'productData',
  customValidationKey = 'productValidation',
) => {
  const usedID = arrHelp.generateArrWithFunc(amount, uuidV4);

  const addProductData = usedID.map((id) => {
    return initialProductData('', id);
  });

  const addProductValidation = usedID.map((id) => {
    return initialProductValidation('', id);
  });

  return {
    [customDataKey]: addProductData,
    [customValidationKey]: addProductValidation,
  };
};

/**
 *
 * @param { number } amount amount data and validation for generate
 * @param { string } customDataKey name key for returning data
 * @param { string } customValidationKey name key for validation data
 * @returns
 *      generated data and validation for adding product in purchase order
 */
export const generateDataNValidationAddProductPOV2 = ({
  amount = 2,
  customDataKey = 'productData',
  customValidationKey = 'productValidation',
}) => {
  const usedID = arrHelp.generateArrWithFunc(amount, uuidV4);

  const addProductData = usedID.map((id) => {
    return initialProductData('', id);
  });

  const addProductValidation = usedID.map((id) => {
    return initialProductValidation('', id);
  });

  return {
    [customDataKey]: addProductData,
    [customValidationKey]: addProductValidation,
  };
};

/**
 *
 * @param { object } quotationRequestDataSelected data that selected for quotation request
 * @param { string } customDataKey returning name key of data purchase on purchase order
 * @param { string } customValidationKey returning name key of validation purchase on purchase order
 * @returns
 *      generated data and validation purchase order for updating data
 *      this data from quotation request, convert to purchase order data selected
 */
export const generateDataNValidationInitialPurchasePOFromQR = (
  quotationRequestDataSelected,
  customDataKey = 'purchaseData',
  customValidationKey = 'purchaseValidation',
) => {
  const usedID = arrHelp.generateArrWithFunc(1, uuidV4);

  const {
    supplier_id,
    supplier_name,
    contact_code,
    transaction_no,
    quotation_request_id,
    quotation_request_code,
  } = quotationRequestDataSelected;

  quotationRequestDataSelected = objHelper.filterKeyObj(
    quotationRequestDataSelected,
    [],
    [
      'invoice_status',
      'status',
      'purchase_order_id',
      'supplier_id',
      'supplier_name',
      'supplier_address',
      'transaction_date',
      'expired_date',
      'purchase_order_no',
      'reference_number',
      'quotation_request_id',
      'amount',
      'product_description',
      'description',
    ],
  );

  const purchaseData = usedID.map((id) => {
    return {
      ...initialPurchaseData('', id),
      ...quotationRequestDataSelected,
      supplier_id: supplier_id || null,
      supplier_name: supplier_name || '',
      supplier_id_container: {
        supplier_id: supplier_id || null,
        supplier_name: supplier_name || '',
        contact_name: supplier_name || '',
        contact_code,
      },
      transaction_date: moment(),
      expired_date: null,
      reference_number: DEFAULT_TRANSACTION_NUMBER_PURCHASE_ORDER,
      reference_number_container: {
        value: DEFAULT_TRANSACTION_NUMBER_PURCHASE_ORDER,
      },
      quotation_request_id: quotation_request_id || null,
      quotation_request_id_container: {
        transaction_no,
        quotation_request_id: quotation_request_id || null,
        quotation_request_code: quotation_request_code || '',
      },
      description: '',
      discount_account_id_container: {
        account_id: quotationRequestDataSelected.discount_account_id || '',
        account_name: quotationRequestDataSelected.discount_account_name || '',
        account_code: quotationRequestDataSelected.discount_account_code || '',
      },
      expense_account_id_container: {
        account_id: quotationRequestDataSelected.expense_account_id || '',
        account_name: quotationRequestDataSelected.expense_account_name || '',
        account_code: quotationRequestDataSelected.expense_account_code || '',
      },
      down_payment_account_id_container: {
        account_id: quotationRequestDataSelected.down_payment_account_id || '',
        account_name: quotationRequestDataSelected.down_payment_account_name || '',
        account_code: quotationRequestDataSelected.down_payment_account_code || '',
      },
    };
  });

  const purchaseValidation = usedID.map((id) => {
    return initialPurchaseValidation('', id);
  });

  return {
    [customDataKey]: purchaseData,
    [customValidationKey]: purchaseValidation,
  };
};

/**
 *
 * @param { object } quotationRequestDataSelected data that selected for quotation request
 * @param { string } customDataKey returning name key of data purchase on purchase order
 * @param { string } customValidationKey returning name key of validation purchase on purchase order
 * @returns
 *      generated data and validation purchase order for updating data
 *      this data from quotation request, convert to purchase order data selected
 */
export const generateDataNValidationInitialPurchaseOrderFromQuotationRequestV2 = ({
  quotationRequestDataSelected,
  currentPurchaseOrderData = [],
  companyUserData = {},
  customDataKey = 'purchaseData',
  customValidationKey = 'purchaseValidation',
}) => {
  if (isEmpty(quotationRequestDataSelected) && isEmpty(currentPurchaseOrderData)) {
    return generateDataNValidationAddPurchasePO(1, customDataKey, customValidationKey);
  } else if (!isEmpty(currentPurchaseOrderData) && isEmpty(quotationRequestDataSelected)) {
    const generatedDataNvalidationPurchaseOrder = generateDataNValidationAddPurchasePO(
      1,
      customDataKey,
      customValidationKey,
    );

    const { reference_number, reference_number_container } = currentPurchaseOrderData[0];

    const newPurchaseOrderData = [
      {
        ...generatedDataNvalidationPurchaseOrder[customDataKey][0],
        reference_number,
        reference_number_container,
        supplier_address: '',
      },
    ];

    return {
      [customDataKey]: newPurchaseOrderData,
      [customValidationKey]: generatedDataNvalidationPurchaseOrder[customValidationKey],
    };
  }

  let revertCurrentPurchaseOrderData = {};

  if (!isEmpty(currentPurchaseOrderData) && Array.isArray(currentPurchaseOrderData)) {
    const {
      reference_number,
      reference_number_container,
      accounting_period,
      uuid_attachment,
      attachment,
    } = currentPurchaseOrderData[0];

    revertCurrentPurchaseOrderData = {
      accounting_period,
      reference_number,
      reference_number_container,
      uuid_attachment,
      attachment,
    };
  }

  const usedID = arrHelp.generateArrWithFunc(1, uuidV4);

  quotationRequestDataSelected = objHelper.changeFormatValue(
    quotationRequestDataSelected,
    purchaseQuotationRequestCalculationNumberKeyNames,
    [(value) => Number(value) || ''],
  );

  console.log(quotationRequestDataSelected);

  const {
    supplier_id,
    supplier_name,
    contact_code,
    transaction_no,
    quotation_request_id,
    quotation_request_code,
    discount_account_id,
    discount_account_name,
    discount_account_code,
    expense_account_id,
    expense_account_name,
    expense_account_code,
    down_payment_account_id,
    down_payment_account_name,
    down_payment_account_code,
    discount_type,
  } = quotationRequestDataSelected;

  const purchaseData = usedID.map((id) => {
    return {
      ...initialPurchaseData('', id),
      ...quotationRequestDataSelected,
      ...revertCurrentPurchaseOrderData,
      accounting_period: isEmpty(companyUserData)
        ? null
        : companyUserData[DEFAULT_KEY_NAME_ACCOUNTING_PERIODE],
      supplier_id: supplier_id || null,
      supplier_name: supplier_name || '',
      supplier_id_container: {
        supplier_id: supplier_id || null,
        supplier_name: supplier_name || '',
        contact_name: supplier_name || '',
        contact_code,
      },
      transaction_date: moment(),
      expired_date: null,
      quotation_request_id: quotation_request_id || null,
      quotation_request_id_container: {
        transaction_no,
        quotation_request_id: quotation_request_id || null,
        quotation_request_code: quotation_request_code || '',
      },
      description: '',
      discount_account_id_container: {
        account_id: discount_account_id || '',
        account_name: discount_account_name || '',
        account_code: discount_account_code || '',
      },
      expense_account_id_container: {
        account_id: expense_account_id || '',
        account_name: expense_account_name || '',
        account_code: expense_account_code || '',
      },
      down_payment_account_id_container: {
        account_id: down_payment_account_id || '',
        account_name: down_payment_account_name || '',
        account_code: down_payment_account_code || '',
      },
      discount_type: discount_type || DEFAULT_DISCOUNT_PERCENTAGE_TYPE,
      [DEFAULT_KEY_NAME_MINIMUM_DATE_INPUT_REF]:
        getAccountingPeriodCompareWithDocumentTransactionDate({
          companyUserData,
          documentData: quotationRequestDataSelected,
        }),
    };
  });

  const purchaseValidation = usedID.map((id) => {
    return initialPurchaseValidation('', id);
  });

  return {
    [customDataKey]: purchaseData,
    [customValidationKey]: purchaseValidation,
  };
};

/**
 *
 * @param { object } quotationRequestDataSelected data that represent quotation request data selected
 * @param { string } customDataKey name key of data that for returning
 * @param { string } customValidationKey name key of validation data for returing value
 * @returns
 *      object that represent data and validation for product purchase order
 *      this data from quotation request, convert to purchase order data selected
 */
export const generateDataNValidationInitialProductPOFromQR = (
  quotationRequestDataSelected,
  customDataKey = 'productData',
  customValidationKey = 'productValidation',
) => {
  const quotationRequestProduct =
    quotationRequestDataSelected[DEFAULT_KEY_NAME_QUOTATION_REQUEST_PRODUCT_REF_API];

  const quotationRequestProductLength = quotationRequestProduct
    ? quotationRequestProduct.length
    : 0;

  const usedID = arrHelp.generateArrWithFunc(quotationRequestProductLength, uuidV4);

  const productData = usedID.map((id, index) => {
    const {
      product_id,
      product_name,
      quantity,
      price,
      description: product_description,
    } = quotationRequestProduct[index];

    const amount = Number(quantity) * Number(price);

    return {
      ...initialProductData('', id),
      ...quotationRequestProduct[index],
      product_id_container: {
        product_id: product_id || null,
        product_name: product_name || '',
        ...quotationRequestProduct[index],
      },
      price: price ? formatHelp.currencyFormatWithRegex(price) : '',
      amount: formatHelp.currencyFormatWithRegex(amount),
      product_description: product_description || '',
    };
  });

  const productValidation = usedID.map((id) => {
    return initialProductValidation('', id);
  });

  return {
    [customDataKey]: productData,
    [customValidationKey]: productValidation,
  };
};

/**
 *
 * @param { object } quotationRequestDataSelected data that represent quotation request data selected
 * @param { string } customDataKey name key of data that for returning
 * @param { string } customValidationKey name key of validation data for returing value
 * @returns
 *      object that represent data and validation for product purchase order
 *      this data from quotation request, convert to purchase order data selected
 */
export const generateDataNValidationInitialProductPOFromQuotationRequestV2 = ({
  quotationRequestDataSelected,
  customDataKey = 'productData',
  customValidationKey = 'productValidation',
}) => {
  if (
    isEmpty(quotationRequestDataSelected) ||
    !quotationRequestDataSelected[DEFAULT_KEY_NAME_QUOTATION_REQUEST_PRODUCT_REF_API]
  ) {
    return generateDataNValidationAddProductPOV2({
      customDataKey,
      customValidationKey,
    });
  }

  const quotationRequestProduct =
    quotationRequestDataSelected[DEFAULT_KEY_NAME_QUOTATION_REQUEST_PRODUCT_REF_API];

  const quotationRequestProductLength = quotationRequestProduct
    ? quotationRequestProduct.length
    : 0;
  const usedID = arrHelp.generateArrWithFunc(quotationRequestProductLength, uuidV4);

  let productData = usedID.map((id, index) => {
    const {
      product_id,
      product_name,
      quantity,
      price,
      description: product_description,
      quantity_origin,
      unit_origin,
      unit_destination,
      product_unit,
    } = quotationRequestProduct[index];

    const amount = Number(quantity) * Number(price);

    const quantityComparison = hasImplementedUnitProductUnit
      ? Number(quantity_origin) / Number(quantity)
      : DEFAULT_COMPARISON_VALUE_NUMBER;

    return {
      ...initialProductData('', id),
      ...quotationRequestProduct[index],
      product_id_container: {
        product_id: product_id || null,
        product_name: product_name || '',
        ...quotationRequestProduct[index],
      },
      price: price ? Number(price) : '',
      amount,
      description: product_description || '',
      product_description: product_description || '',
      unit_origin: !hasImplementedUnitProductUnit ? product_unit || '' : unit_origin || '',
      unit_destination: !hasImplementedUnitProductUnit
        ? product_unit || ''
        : unit_destination || '',
      quantity: Number(quantity),
      quantity_origin: Number(quantity_origin) || '',
      quantity_available: Number(quantity),
      quantity_comparison: quantityComparison,
      quantity_comparison_const_props: {
        quantity,
        quantity_origin,
        quantity_available: Number(quantity),
        value_comparison: quantityComparison,
      },
    };
  });

  const { productData: productDataGen, productValidation: productValidationGen } =
    generateDataNValidationAddProductPO(1);

  let productValidation = usedID.map((id) => {
    return initialProductValidation('', id);
  });

  productData = productData.concat(productDataGen);
  productValidation = productValidation.concat(productValidationGen);

  return {
    [customDataKey]: productData,
    [customValidationKey]: productValidation,
  };
};

/**
 *
 * @param { array } purchaseData contain all data purchase
 * @param { array } productData contain all data for product
 * @returns
 *      generalized data for purchase order add
 */
export const generalizeDataAddPurchaseOrder = (purchaseData, productData) => {
  if (Array.isArray(purchaseData) && Array.isArray(productData)) {
    productData = productData.map((product) => {
      const { price, amount, product_description } = product;

      return {
        ...product,
        price: price ? formatHelp.reverseCurrencyFormatWithRegex(price) : 0,
        amount: amount ? formatHelp.reverseCurrencyFormatWithRegex(amount) : 0,
        product_description: product_description ? product_description : '',
      };
    });

    purchaseData = purchaseData.map((purchase) => {
      const {
        transaction_date,
        expired_date,
        discount_percentage,
        discount_nominal,
        discount_type,
        discount_account_id,
        expense_value,
        expense_account_id,
        down_payment_value,
        down_payment_account_id,
        attachment,
      } = purchase;

      const uuidAttachment =
        Array.isArray(attachment) && attachment.length
          ? attachment[0][DEFAULT_KEY_NAME_FILE_PROPERTIES][DEFAULT_KEY_NAME_UUID_API_REF] || ''
          : '';

      return {
        ...purchase,
        [DEFAULT_KEY_NAME_UUID_ATTACHMENT_API_REF]: uuidAttachment,
        transaction_date: transaction_date ? moment(transaction_date).toISOString(true) : '',
        expired_date: expired_date ? moment(expired_date).toISOString(true) : '',
        discount_percentage: discount_percentage == 0 ? '' : discount_percentage,
        discount_nominal: discount_nominal == 0 ? '' : discount_nominal,
        discount_type: discount_nominal == 0 || discount_percentage == 0 ? '' : discount_type,
        discount_account_id:
          discount_nominal == 0 || discount_percentage == 0 ? '' : discount_account_id,
        expense_account_id: expense_value ? expense_account_id : '',
        down_payment_account_id: down_payment_value ? down_payment_account_id : '',
      };
    });

    return {
      product: productData,
      ...purchaseData[0],
      supplier_name: purchaseData[0].supplier_id_container
        ? purchaseData[0].supplier_id_container.contact_name
        : '',
    };
  }
};

export function generalizeDataAddUpdatePurchaseOrder(dataPurchaseOrder) {
  if (typeof dataPurchaseOrder !== 'object') return dataPurchaseOrder;

  dataPurchaseOrder = objHelper.filterKeyObj(dataPurchaseOrder, [], allowKeysPurchaseOrderData);

  if (Array.isArray(dataPurchaseOrder.product) && !isEmpty(dataPurchaseOrder)) {
    dataPurchaseOrder.product = dataPurchaseOrder.product.map((productPurchaseOrder) => {
      let isDataPurchaseOrderProductExist = objHelper.filterKeyObj(
        productPurchaseOrder,
        [],
        allowKeysProductPurchaseOrderData,
      );

      isDataPurchaseOrderProductExist = objHelper.filteringExistedValue(
        isDataPurchaseOrderProductExist,
        ['id'],
        true,
        DEFAULT_EMPTY_CALCULATIONS_NUMBER,
      );

      if (isDataPurchaseOrderProductExist) {
        return objHelper.filterKeyObj(productPurchaseOrder, [], allowKeysProductPurchaseOrderData);
      }
    });

    dataPurchaseOrder.product = arrHelp.filteringExistedValue(dataPurchaseOrder.product);
  }

  if (Array.isArray(dataPurchaseOrder.product)) {
    dataPurchaseOrder.product = arrHelp.filteringExistedValue(dataPurchaseOrder.product);
  }

  return objHelper.filteringExistedValue(dataPurchaseOrder, [], true);
}
