/**
 * base types of action that registered, usage to creating matcher
 * what about registered, because all base types already for usage on addMatch
 */

import {
  ACCOUNT_BASE_ACTION_TYPE,
  ACCOUNT_CATEGORY_BASE_ACTION_TYPE,
  ASSET_CATEGORY_BASE_ACTION_TYPE,
  ASSET_MANAGEMENT_BASE_ACTION_TYPE,
  EXPENSE_BASE_ACTION_TYPE,
  JOURNAL_ENTRY_BASE_ACTION_TYPE,
  PRODUCT_BASE_ACTION_TYPE,
  PRODUCT_CATEGORY_BASE_ACTION_TYPE,
  PURCHASE_INVOICE_BASE_ACTION_TYPE,
  SALES_INVOICE_BASE_ACTION_TYPE,
} from './action-type.constant';

export const BASE_TYPES_ACCOUNT_ACTION_REGISTERED = [
  ACCOUNT_BASE_ACTION_TYPE,
  ACCOUNT_CATEGORY_BASE_ACTION_TYPE,
];

export const BASE_TYPES_ASSET_ACTION_REGISTERED = [
  ASSET_CATEGORY_BASE_ACTION_TYPE,
  ASSET_MANAGEMENT_BASE_ACTION_TYPE,
];

export const BASE_TYPES_PRODUCT_ACTION_REGISTERED = [
  PRODUCT_BASE_ACTION_TYPE,
  PRODUCT_CATEGORY_BASE_ACTION_TYPE,
];

export const BASE_TYPES_PURCHASE_ACTION_REGISTERED = [PURCHASE_INVOICE_BASE_ACTION_TYPE];

export const BASE_TYPES_SALES_ACTION_REGISTERED = [SALES_INVOICE_BASE_ACTION_TYPE];

export const BASE_TYPES_EXPENSE_ACTION_REGISTERED = [EXPENSE_BASE_ACTION_TYPE];

export const BASE_TYPES_JOURNAL_ENTRY_ACTION_REGISTERED = [JOURNAL_ENTRY_BASE_ACTION_TYPE];
