import { useTranslation } from 'react-i18next';
import { isEmpty, pick } from 'lodash';

import formatHelp from '../utils/helpers/format.helpers';
import objHelper from '../utils/helpers/object.helper';
import strHelp from '../utils/helpers/string.helpers';

export const commonFormat = 'common-format';
export const dateFormat = 'date-format';
export const currencyFormat = 'currency-format';
export const currencyWithPrefixFormat = 'currency-with-prefix-format';
export const mappingTranslationFormat = 'mapping-translation-format';
export const mappingTranslationExistedValueFormat = 'mapping-translation-existed-value-format';
export const numberOrderFormat = 'number-order';
export const prefixNumberFormat = 'prefix-number';
export const additionalAffixesFormat = 'additional-affixes';
export const currencyCalculationKeynameWithSignFormat =
  'currency-calculation-keyname-with-sign-format';

const mappingValueFormatter = {
  [commonFormat]: ({ value }) => value,
  [dateFormat]: ({ value }) => {
    if (!value || Number.isNaN(new Date(value).getTime())) return null;

    return formatHelp.getReadableDateV2(value);
  },
  [currencyFormat]: ({ value }) => {
    if (Number.isNaN(Number(value))) return 0;

    return formatHelp.currencyWithAutoComma(Number(value)) || 0;
  },
  [currencyWithPrefixFormat]: ({ value, i18n }) => {
    if (!['string', 'number'].includes(typeof value)) return value;

    const { language } = i18n;

    return formatHelp.currencyFormat(value, language);
  },
  [mappingTranslationFormat]: ({ t, value, mappingTranslation }) => {
    if (!Object.keys(mappingTranslation).includes(value)) return '';

    return t(mappingTranslation[value]);
  },
  [mappingTranslationExistedValueFormat]: ({
    t,
    containerValue,
    mappingTranslation,
    keynamesExistedValue,
    separatorValue = ', ',
  }) => {
    if (isEmpty(containerValue)) return null;

    keynamesExistedValue = objHelper.filteringExistedValue(
      pick(containerValue, keynamesExistedValue),
      [],
      true,
    );

    if (isEmpty(keynamesExistedValue)) return null;

    return Object.keys(keynamesExistedValue)
      .map((keyname) => {
        if (!Object.keys(mappingTranslation).includes(keyname)) return '';

        return t(mappingTranslation[keyname]);
      })
      .join(separatorValue);
  },
  [numberOrderFormat]: ({ index, suffix = '.' }) => {
    if (!['string', 'number'].includes(typeof index)) return index;

    return strHelp.templateString('{0}{1}', [Number(index) + 1, suffix]);
  },
  [prefixNumberFormat]: ({ value }) => {
    if (!['string', 'number'].includes(typeof value)) return value;

    value = Number(value);

    return value > 0 ? `+${value}` : value.toString();
  },
  [additionalAffixesFormat]: ({ prefix, suffix, value }) => {
    if (!['string', 'number'].includes(typeof value)) return value;

    return strHelp.addPrefixSuffix(value, prefix, suffix);
  },
  [currencyCalculationKeynameWithSignFormat]: ({
    containerValue,
    calculationKeynameNsignConfigs,
    emptyValueSubtitute,
  }) => {
    if (isEmpty(calculationKeynameNsignConfigs) || isEmpty(containerValue))
      return emptyValueSubtitute;

    return formatHelp.wrapperCurrencyWithAutoCommaV1(
      objHelper.calculateObjValueBasedKeyNamesNsign(containerValue, calculationKeynameNsignConfigs),
    );
  },
};

export default function useValueFormatter() {
  const { t, i18n } = useTranslation();
  const availableKeyValueFormatter = Object.keys(mappingValueFormatter);

  function valueFormatterV1({
    index,
    format = commonFormat,
    value = null,
    containerValue = {},
    emptyValueSubtitute = '',
    mappingTranslation = {},
    keynamesExistedValue = [],
    separatorValue = undefined,
    suffix = undefined,
    prefix = undefined,
    calculationKeynameNsignConfigs = [],
  }) {
    if (!canFormatted(format)) return value;

    if (isEmpty(keynamesExistedValue)) keynamesExistedValue = Object.keys(containerValue);

    return (
      mappingValueFormatter[format]({
        t,
        i18n,
        value,
        index,
        mappingTranslation,
        containerValue,
        keynamesExistedValue,
        separatorValue,
        suffix,
        prefix,
        calculationKeynameNsignConfigs,
      }) || emptyValueSubtitute
    );
  }

  function canFormatted(format) {
    return availableKeyValueFormatter.includes(format);
  }

  return {
    valueFormatterV1,
    canFormatted,
  };
}
