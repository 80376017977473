import { cloneDeep, isArray, isEmpty, omit, pick } from 'lodash';

import LocalStorage from '../../services/modules/LocalStorage/LocalStorage.service';
import { DEFAULT_DATA_MAX_LENGTH_ON_LOCAL_STORAGE } from '../../utils/default/max-length-data.default';
import {
  DEFAULT_KEY_NAME_CONTAINER_COMBINE_RESPONSE,
  DEFAULT_KEY_NAME_REQUEST_STATUS_FAILED,
  DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING,
  DEFAULT_KEY_NAME_REQUEST_STATUS_SUCCESS,
  DEFAULT_KEY_NAME_RESPONSE_DATA_API_REF,
  DEFAULT_KEY_NAME_SUB_RESPONSE_DATA_API_REF,
  DEFAULT_KEY_NAME_SUCESS_API_REF,
  DEFAULT_KEY_NAME_USE_REQUEST_STATUS_CUSTOM,
} from '../../utils/default/object-keyname.default';
import {
  DEFAULT_ACTION_PARAMS,
  DEFAULT_PARAMS_PAGINATE_PAGE,
  DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
} from '../../utils/default/params.default';
import arrHelp from '../../utils/helpers/array.helpers';
import objHelper from '../../utils/helpers/object.helper';
import { objectToQueryString } from '../../utils/helpers/url.helpers';
import messageHelper from '../message/message.helper';

/**
 * @param   { function }         thunkAPI, function from thunk api, that able to reject, or fullfilled
 * @param   { object }           paramsList, params object for hitting endpoint
 * @param   { function }         serviceFunc, function hitting endpoint, from service
 * @param   { array }            actionParams, list of parameter for action
 * @param   { array }            paramsPaginatePage, name params for pagination and page, for adding into params list default data
 * @param   { string }           nameResponseDataKey, name key object of response data for get data from response
 * @param   { string }           nameStoreDataKey, name key object, where data store in response data
 * @param   { string }           namePrefixSearch, name search prefix, that for changing into FE, search key
 * @param   { string }           localStorageName, name local storage
 * @param   { string }           nameKeySlicePayload, name key for payload, require for forwarding ( returning ) into [ slice store data ]
 * @param   { string }           uniqueKey, unique key that different with another data, before or after data
 * @param   { string }           errorMessage, error message, make sure write in translation
 *
 * @param   { array }           omitKeynameParamsWhenStoreSearchParams
 * @returns
 *  complete async function for create async thunk, with handling data and catch error
 */
export const creatorListAction = async (
  thunkAPI,
  paramsList,
  serviceFunc,
  localStorageName,
  nameKeySlicePayload,
  uniqueKey,
  errorMessage,
  paramsPaginatePage = DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
  actionParams = DEFAULT_ACTION_PARAMS,
  nameResponseDataKey = 'data',
  nameStoreDataKey = 'data',
  namePrefixSearch = 'search_',
  isUseSimplyfy = true,
  omitKeynameParamsWhenStoreSearchParams = [],
  successResponseKeyname = 'sucess',
) => {
  paramsList = objHelper.withDefaultValue(paramsList, paramsPaginatePage);

  const { isRewriteAll, isShowMessage } = paramsList;

  try {
    const response = await serviceFunc(paramsList);
    if (!response) throw { response };

    const { [nameStoreDataKey]: data, [successResponseKeyname]: success } =
      response[nameResponseDataKey];

    // adding search params when failed response
    let searchParams = objHelper.filterKeyObj(paramsList, actionParams);

    if (!isEmpty(omitKeynameParamsWhenStoreSearchParams)) {
      searchParams = omit(searchParams, omitKeynameParamsWhenStoreSearchParams);
    }

    // adding prefix into search params
    if (!isEmpty(searchParams)) {
      searchParams = objHelper.changeSuffixKey(searchParams, namePrefixSearch, false);
    }

    // handling on error get name store data key
    if (isEmpty(data) && !success) {
      if (!isEmpty(searchParams) && !!localStorageName) {
        LocalStorage.set(localStorageName, searchParams);
      }

      throw {
        response,
        isExistedSearchParams: !isEmpty(searchParams),
      };
    }

    let responseData = cloneDeep(data);

    // force for alwways name store data on list
    if (Array.isArray(responseData)) {
      responseData = {
        [nameStoreDataKey]: responseData,
      };
    } else if (!success || !Array.isArray(responseData[nameStoreDataKey])) {
      responseData = {
        ...responseData,
        [nameStoreDataKey]: [],
      };
    }

    if (searchParams) {
      responseData = {
        ...responseData,
        ...searchParams,
      };
    }

    if (isRewriteAll || (responseData && !Array.isArray(responseData[nameStoreDataKey]))) {
      if (localStorageName) {
        const storeDataOnLocalStorage = isUseSimplyfy
          ? simplifyStoragedDataOnLocalStorage({
              primaryLocalStorageData: cloneDeep(responseData),
              keyNameForLocalStorageData: nameStoreDataKey,
            })
          : cloneDeep(responseData);

        LocalStorage.set(localStorageName, storeDataOnLocalStorage);
      }

      return {
        [nameKeySlicePayload]: responseData,
      };
    }

    const currentData = LocalStorage.get(localStorageName);

    let newCurrentDataList = responseData[nameStoreDataKey] || [];

    // adding new data on old result
    // combine old data list with response data
    if (
      !isEmpty(currentData[nameStoreDataKey]) &&
      !isEmpty(responseData[nameStoreDataKey]) &&
      Array.isArray(currentData[nameStoreDataKey]) &&
      Array.isArray(responseData[nameStoreDataKey])
    ) {
      const currUniqueKey = currentData[nameStoreDataKey].map((currData) => {
        return currData[uniqueKey];
      });

      const resUniqueKey = responseData[nameStoreDataKey].map((resData) => {
        return resData[uniqueKey];
      });

      const isDuplicateUniqueKey = resUniqueKey.some((uniqKeyRes) => {
        return currUniqueKey.includes(uniqKeyRes);
      });

      if (!isDuplicateUniqueKey) {
        newCurrentDataList = currentData.data.concat(responseData[nameStoreDataKey]);
      } else if (isDuplicateUniqueKey) {
        newCurrentDataList = arrHelp.reduceUniqueInObj(
          currentData[nameStoreDataKey].concat(responseData[nameStoreDataKey]),
          uniqueKey,
        );
      }
    }

    const newResponseData = Object.assign(responseData, { [nameStoreDataKey]: newCurrentDataList });

    if (localStorageName) {
      const storeDataOnLocalStorage = isUseSimplyfy
        ? simplifyStoragedDataOnLocalStorage({
            primaryLocalStorageData: cloneDeep(newResponseData),
            keyNameForLocalStorageData: nameStoreDataKey,
          })
        : cloneDeep(newResponseData);

      LocalStorage.set(localStorageName, storeDataOnLocalStorage);
    }

    return { [nameKeySlicePayload]: newResponseData };
  } catch (error) {
    const { response, isExistedSearchParams } = error;

    if (!response) {
      messageHelper.serverInternalError(thunkAPI.dispatch);

      return thunkAPI.rejectWithValue(response);
    }

    const { data } = response;

    const responseMessage = messageHelper.getMessageFromResponseData(data);

    if (isExistedSearchParams && isShowMessage) {
      if (responseMessage) {
        messageHelper.failedMessage(thunkAPI.dispatch, responseMessage, errorMessage, false);
      } else {
        messageHelper.failedMessage(thunkAPI.dispatch, errorMessage, errorMessage, false);
      }
    }

    return thunkAPI.rejectWithValue(data);
  }
};

/**
 * @param   { function }         thunkAPI, function from thunk api, that able to reject, or fullfilled
 * @param   { object }           paramsList, params object for hitting endpoint
 * @param   { function }         serviceFunc, function hitting endpoint, from service
 * @param   { array }            actionParams, list of parameter for action
 * @param   { array }            paramsPaginatePage, name params for pagination and page, for adding into params list default data
 * @param   { string }           nameResponseDataKey, name key object of response data for get data from response
 * @param   { string }           nameStoreDataKey, name key object, where data store in response data
 * @param   { string }           namePrefixSearch, name search prefix, that for changing into FE, search key
 * @param   { string }           localStorageName, name local storage
 * @param   { string }           nameKeySlicePayload, name key for payload, require for forwarding ( returning ) into [ slice store data ]
 * @param   { string }           uniqueKey, unique key that different with another data, before or after data
 * @param   { string }           errorMessage, error message, make sure write in translation
 * @returns
 *      complete async function for create async thunk in list endpoint with dynamic, with handling data and catch error
 *      object that contains
 *      {
 *          sliceName: [slice name that you assign before],
 *          [sliceName]: [data return from backend for store on slice]
 *      }
 *
 * @example return
 *      {
 *          sliceName: 'dataListFnia',
 *          dataListFnia: {
 *              [...list response from API]
 *          }
 *      }
 */
export const creatorListActionWithDynamicSliceName = async (
  thunkAPI,
  paramsList,
  serviceFunc,
  localStorageName,
  nameKeySlicePayload,
  uniqueKey,
  errorMessage,
  paramsPaginatePage = DEFAULT_PARAMS_PAGINATE_PAGE_IN_ACTION,
  actionParams = DEFAULT_ACTION_PARAMS,
  nameResponseDataKey = 'data',
  nameStoreDataKey = 'data',
  namePrefixSearch = 'search_',
) => {
  paramsList = objHelper.withDefaultValue(paramsList, paramsPaginatePage);

  const { isRewriteAll, isShowMessage } = paramsList;

  try {
    const response = await serviceFunc(paramsList);

    if (!response) throw { response };

    const dataResponseBE = response[nameResponseDataKey];

    // adding search params when failed response
    let searchParams = objHelper.filterKeyObj(paramsList, actionParams);

    // adding prefix into search params
    if (!isEmpty(searchParams)) {
      searchParams = objHelper.changeSuffixKey(searchParams, namePrefixSearch, false);
    }

    // handling on error get name store data key
    if (isEmpty(dataResponseBE[nameStoreDataKey])) {
      if (!isEmpty(searchParams) && !isEmpty(localStorageName)) {
        LocalStorage.set(localStorageName, searchParams);
      }

      throw {
        response,
        isExistedSearchParams: !isEmpty(searchParams),
      };
    }

    let { data: responseData, sucess } = response[nameResponseDataKey];

    if (!Array.isArray(responseData)) {
      if (!sucess || !Array.isArray(responseData[nameStoreDataKey])) {
        responseData = {
          ...responseData,
          [nameStoreDataKey]: [],
        };
      }
    }

    if (searchParams && !Array.isArray(responseData)) {
      responseData = {
        ...responseData,
        ...searchParams,
      };
    }

    if (isRewriteAll || isEmpty(localStorageName)) {
      if (!isEmpty(localStorageName)) {
        LocalStorage.set(localStorageName, responseData);
      }

      return {
        sliceName: nameKeySlicePayload,
        [nameKeySlicePayload]: responseData,
      };
    }

    const currentData = LocalStorage.get(localStorageName);

    let newCurrentDataList = responseData[nameStoreDataKey] || [];

    // adding new data on old result
    // combine old data list with response data
    if (!isEmpty(currentData[nameStoreDataKey]) && !isEmpty(responseData[nameStoreDataKey])) {
      if (
        Array.isArray(currentData[nameStoreDataKey]) &&
        Array.isArray(responseData[nameStoreDataKey])
      ) {
        const currUniqueKey = currentData[nameStoreDataKey].map((currData) => {
          return currData[uniqueKey];
        });

        const resUniqueKey = responseData[nameStoreDataKey].map((resData) => {
          return resData[uniqueKey];
        });

        const isDuplicateUniqueKey = resUniqueKey.some((uniqKeyRes) => {
          return currUniqueKey.includes(uniqKeyRes);
        });

        if (!isDuplicateUniqueKey) {
          newCurrentDataList = currentData.data.concat(responseData[nameStoreDataKey]);
        }
      }
    }

    const newResponseData = Object.assign(responseData, { [nameStoreDataKey]: newCurrentDataList });

    if (!isEmpty(localStorageName)) {
      LocalStorage.set(localStorageName, newResponseData);
    }

    return {
      sliceName: nameKeySlicePayload,
      [nameKeySlicePayload]: newResponseData,
    };
  } catch (error) {
    const { response, isExistedSearchParams } = error;

    if (!response) {
      messageHelper.serverInternalError(thunkAPI.dispatch);

      return thunkAPI.rejectWithValue({
        response,
        sliceName: nameKeySlicePayload,
      });
    }

    const { data } = response;

    const responseMessage = messageHelper.getMessageFromResponseData(data);

    if (isExistedSearchParams && isShowMessage) {
      if (responseMessage) {
        messageHelper.failedMessage(thunkAPI.dispatch, responseMessage, errorMessage, false);
      } else {
        messageHelper.failedMessage(thunkAPI.dispatch, errorMessage, errorMessage, false);
      }
    }

    return thunkAPI.rejectWithValue({
      data,
      sliceName: nameKeySlicePayload,
    });
  }
};

/**
 * @param   { function }    thunkAPI                        default thunk api from create action
 * @param   { object }      params                          params hit api service
 * @param   { function }    apiService                      function handling service for api
 * @param   { string }      localStorageName                store response to local storage name
 * @param   { string }      nameKeySlicePayload             store slice name
 * @param   { string }      uniqueKeyname                   reduce unique keyname
 * @param   { string }      errorMessage                    reponse failed message
 * @param   { array }       paginatePageParams              params for paginate page
 * @param   { array }       actionParams                    actions params on listing
 * @param   { string }      responseDataKeyname             keyname response data API
 * @param   { string }      storeDataKeyname                keyname for storing response data
 * @param   { string }      perfixSearchName                prefix for search
 * @param   { boolean }     useSimplifyStorage              determine for reduce data array when store data
 * @param   { string }      requestStatusPendingName        request status store pending name
 * @param   { string }      requestStatusSuccessName        request status store success name
 * @param   { string }      requestStatusFailedName         request status store failed name
 * @param   { string }      requestStatusPending            request status store pending action
 * @param   { string }      requestStatusSuccess            request status store success action
 * @param   { string }      requestStatusFailed             request status store failed action
 *
 * @returns
 *      complete async function for create async thunk in list endpoint with dynamic, with handling data and catch error
 *      object that contains
 *      {
 *          sliceName: [slice name that you assign before],
 *          [sliceName]: [data return from backend for store on slice]
 *      }
 *
 *
 *  usage for separate params and properties for slice
 *      properties is including all requests status
 *      params usage for throw for api
 *
 * @example return
 *      {
 *          sliceName: 'dataListFnia',
 *          useRequestStatusResponse: true,
 *          requestStatusPendingName: '',
 *          requestStatusSuccessName: '',
 *          requestStatusFailedName: '',
 *          requestStatusLoading: '',
 *          requestStatusSuccess: '',
 *          requestStatusFailed: '',
 *          ['dataListFnia']: {
 *              [...API response]
 *          },
 *      }
 */
export const creatorListActionDynamicSliceNameNproperties = async ({
  thunkAPI,
  params,
  apiService,
  localStorageName,
  slicePayloadKeyname,
  uniqueKeyname,
  errorMessage,
  paginatePageParams = {},
  actionParams = DEFAULT_ACTION_PARAMS,
  responseDataKeyname = 'data',
  storeDataKeyname = 'data',
  perfixSearchName = 'search_',
  useSimplifyStorage = true,
  requestStatusPendingName = DEFAULT_KEY_NAME_REQUEST_STATUS_PENDING,
  requestStatusSuccessName = DEFAULT_KEY_NAME_REQUEST_STATUS_SUCCESS,
  requestStatusFailedName = DEFAULT_KEY_NAME_REQUEST_STATUS_FAILED,
  requestStatusPending = '',
  requestStatusSuccess = '',
  requestStatusFailed = '',
  paginateKeyname = 'paginate',
  useDefaultPaginate = true,
} = {}) => {
  params = {
    isRewriteAll: false,
    isShowMessage: true,
    ...paginatePageParams,
    ...params,
  };

  if (useDefaultPaginate && params[paginateKeyname] === undefined) {
    params = {
      ...params,
      [paginateKeyname]: paginatePageParams[paginateKeyname],
    };
  }

  const { isRewriteAll, isShowMessage } = params;

  const sliceProps = {
    [DEFAULT_KEY_NAME_USE_REQUEST_STATUS_CUSTOM]: true,
    requestStatusPendingName,
    requestStatusSuccessName,
    requestStatusFailedName,
    [requestStatusPendingName]: requestStatusPending,
    [requestStatusSuccessName]: requestStatusSuccess,
    [requestStatusFailedName]: requestStatusFailed,
  };

  // adding search params when failed response
  // can lead bug, where only filtering not actions params, such paginate, page
  let searchParams = objHelper.filterKeyObj(params, actionParams);

  // adding prefix into search params
  if (!isEmpty(searchParams)) {
    searchParams = objHelper.changeSuffixKey(searchParams, perfixSearchName, false);
  }

  try {
    const response = await apiService(params);

    if (!response) throw { response };

    const apiResponseData = response[responseDataKeyname];

    // handling on error get name store data key
    if (isEmpty(apiResponseData[storeDataKeyname])) {
      if (!isEmpty(searchParams) && !isEmpty(LocalStorage.get(localStorageName))) {
        LocalStorage.set(localStorageName, searchParams);
      }

      throw {
        response,
        isExistedSearchParams: !isEmpty(searchParams),
      };
    }

    let { data: responseData, sucess } = apiResponseData;

    if (
      !Array.isArray(responseData) &&
      (!sucess || !Array.isArray(responseData[storeDataKeyname]))
    ) {
      responseData = {
        ...responseData,
        [storeDataKeyname]: [],
      };
    }

    if (searchParams && !Array.isArray(responseData)) {
      responseData = {
        ...responseData,
        ...searchParams,
      };
    }

    if (isRewriteAll || (localStorageName && isEmpty(LocalStorage.get(localStorageName)))) {
      const storeDataOnLocalStorage =
        useSimplifyStorage && !isEmpty(LocalStorage.get(localStorageName))
          ? simplifyStoragedDataOnLocalStorage({
              primaryLocalStorageData: cloneDeep(responseData),
              keyNameForLocalStorageData: localStorageName,
            })
          : cloneDeep(responseData);

      LocalStorage.set(localStorageName, storeDataOnLocalStorage);

      return {
        ...sliceProps,
        sliceName: slicePayloadKeyname,
        [slicePayloadKeyname]: responseData,
      };
    }

    const currentData = LocalStorage.get(localStorageName);

    let newCurrentDataList = responseData[storeDataKeyname] || [];

    // adding new data on old result
    // combine old data list with response data
    if (
      !isEmpty(currentData[storeDataKeyname]) &&
      !isEmpty(responseData[storeDataKeyname]) &&
      Array.isArray(currentData[storeDataKeyname]) &&
      Array.isArray(responseData[storeDataKeyname])
    ) {
      newCurrentDataList = arrHelp.reduceUniqueInObj(
        currentData[storeDataKeyname].concat(responseData[storeDataKeyname]),
        uniqueKeyname,
      );
    }

    const newResponseData = Object.assign(responseData, {
      [storeDataKeyname]: newCurrentDataList,
    });

    if (localStorageName) {
      const storeDataOnLocalStorage = useSimplifyStorage
        ? simplifyStoragedDataOnLocalStorage({
            primaryLocalStorageData: cloneDeep(newResponseData),
            keyNameForLocalStorageData: localStorageName,
          })
        : cloneDeep(newResponseData);

      LocalStorage.set(localStorageName, storeDataOnLocalStorage);
    }

    return {
      ...sliceProps,
      sliceName: slicePayloadKeyname,
      [slicePayloadKeyname]: newResponseData,
    };
  } catch (error) {
    const { response, isExistedSearchParams } = error;

    if (!response) {
      messageHelper.serverInternalError(thunkAPI.dispatch);

      return thunkAPI.rejectWithValue({
        ...sliceProps,
        searchParams,
        response,
        sliceName: slicePayloadKeyname,
      });
    }

    const { data } = response;

    const responseMessage = messageHelper.getMessageFromResponseData(data);

    if (isExistedSearchParams && isShowMessage) {
      if (responseMessage) {
        messageHelper.failedMessage(thunkAPI.dispatch, responseMessage, errorMessage, false);
      } else {
        messageHelper.failedMessage(thunkAPI.dispatch, errorMessage, errorMessage, false);
      }
    }

    return thunkAPI.rejectWithValue({
      ...sliceProps,
      searchParams,
      data,
      sliceName: slicePayloadKeyname,
    });
  }
};

/**
 *
 * @param { function } thunkAPI build-in function from creator async thunk
 * @param { string } paramsDetail params detail, such as id
 * @param { function } serviceFunc function service that for hitting API
 * @param { string } localStorageName name local storage for store data after hit API
 * @param { string } nameKeySlicePayload name key of slice that data store on slice thunk
 * @param { string } errorMessage error message
 * @param { string } nameResponseDataKey key that where data was store on response API
 * @returns
 *  complete async function for detail data with create async thunk, and adding handling error message
 */
export const creatorDetailAction = async (
  thunkAPI,
  paramsDetail,
  serviceFunc,
  localStorageName,
  nameKeySlicePayload,
  errorMessage,
  nameResponseDataKey = 'data',
) => {
  const { isShowMessage = true } = paramsDetail;

  try {
    const response = await serviceFunc(paramsDetail);
    if (!response) throw { response };

    let { data: dataResponse, sucess } = response[nameResponseDataKey];
    if (
      !sucess ||
      (typeof dataResponse === 'number' && !(dataResponse >= 0)) ||
      (typeof dataResponse === 'object' && isEmpty(dataResponse))
    )
      throw { response };
    else if (isArray(dataResponse)) {
      dataResponse = {
        ...dataResponse[0],
      };
    } else if (isArray(dataResponse.data)) {
      dataResponse = {
        ...dataResponse.data[0],
      };
    }

    if (localStorageName) {
      LocalStorage.set(localStorageName, dataResponse);
    }

    return { [nameKeySlicePayload]: dataResponse };
  } catch (error) {
    const { response } = error;

    if (!response) {
      messageHelper.serverInternalError(thunkAPI.dispatch);

      return thunkAPI.rejectWithValue(response);
    }

    const data = response[nameResponseDataKey];

    const responseMessage = messageHelper.getMessageFromResponseData(data);

    if (!responseMessage && errorMessage && isShowMessage) {
      messageHelper.failedMessage(thunkAPI.dispatch, errorMessage, errorMessage, false);
    } else if (responseMessage && errorMessage && isShowMessage) {
      messageHelper.failedMessage(thunkAPI.dispatch, responseMessage, errorMessage, false);
    }

    return thunkAPI.rejectWithValue(data);
  }
};

/**
 *
 * @param   { object }       params0
 *
 * @param   { function }     thunkAPI                       build-in function from creator async thunk
 * @param   { string }       paramsDetail                   params detail, such as id
 * @param   { function }     serviceFunc                    function service that for hitting API
 * @param   { string }       localStorageName               name local storage for store data after hit API
 * @param   { string }       nameKeySlicePayload            name key of slice that data store on slice thunk
 * @param   { string }       errorMessage                   error message
 * @param   { bool }         allowArrayReturn               determine store list instead of object
 * @param   { bool }         isMessageShow                  determine is showing message or not
 * @param   { bool }         usageStoreOnLocalStorage       determine data that store on local storage or not
 * @param   { string }       keyNameResponseDataKey         key that where data was store on response API
 * @param   { string }       keyNameSubResponseDataKey      key name that getting pointed data from API
 * @returns
 *  complete async function for getting list when hit detail API
 */
export const creatorListDetailAction = async ({
  thunkAPI,
  paramsDetail,
  serviceFunc,
  localStorageName,
  nameKeySlicePayload,
  errorMessage,
  allowArrayReturn = true,
  isMessageShow = false,
  usageStoreOnLocalStorage = false,
  keyNameResponseDataKey = DEFAULT_KEY_NAME_RESPONSE_DATA_API_REF,
  keyNameSubResponseData = DEFAULT_KEY_NAME_SUB_RESPONSE_DATA_API_REF,
}) => {
  try {
    const response = await serviceFunc(paramsDetail);
    if (isEmpty(response)) throw { response };

    const dataResponse = response[keyNameResponseDataKey];
    let subResponseData = dataResponse[keyNameSubResponseData];

    const isRequestSuccess = dataResponse[DEFAULT_KEY_NAME_SUCESS_API_REF];
    if (!isRequestSuccess || isEmpty(dataResponse)) throw { response };

    if (Array.isArray(subResponseData) && subResponseData.length && !allowArrayReturn) {
      subResponseData = {
        ...subResponseData[0],
      };
    }

    if (usageStoreOnLocalStorage && localStorageName) {
      LocalStorage.set(localStorageName, subResponseData);
    }

    return {
      [nameKeySlicePayload]: subResponseData,
    };
  } catch (error) {
    const { response } = error;

    if (isEmpty(response)) {
      messageHelper.serverInternalError(thunkAPI.dispatch);

      return thunkAPI.rejectWithValue(response);
    }

    const dataResponse = response[keyNameResponseDataKey];
    if (isMessageShow) {
      const responseMessage = messageHelper.getMessageFromResponseData(dataResponse);
      let failedMessageParams = [thunkAPI.dispatch, errorMessage, errorMessage, false];

      if (responseMessage && errorMessage) {
        failedMessageParams = [thunkAPI.dispatch, responseMessage, errorMessage, false];
      }

      messageHelper.failedMessage(...failedMessageParams);
    }

    return thunkAPI.rejectWithValue(dataResponse);
  }
};

/**
 *
 * @param   { string }      localStorageName, localStorage name where data is stored
 * @param   { array }       discardSearchParams, discard params, name key search for removing from keys data
 * @param   { array }       allowSearchParams, allow params, allowing name key search param
 * @param   { string }      nameKeyPagination, name keys pagination, name key pagination from BE, such as per_page
 * @param   { string }      nonNameKeySearchParams, outside name key search params
 * @param   { array }       removingPrevixParams, name previx params, for normalize search current params
 * @returns
 *      object { filterNextSearch, filterCurrentSearch }
 *      filterNextSearch, for stored in data again
 *      filterCurrentSearch, for hitting api again with search data
 */
export const getParamsSearch = (
  localStorageName = '',
  discardSearchParams = [],
  allowSearchParams = [],
  nameKeyPagination = 'per_page',
  nonNameKeySearchParams = ['per_page', 'current_page'],
  removingPrevixParams = ['search_', 'current_'],
) => {
  const currentData = LocalStorage.get(localStorageName);

  let filterCurrentSearch = {};

  if (currentData) {
    filterCurrentSearch = objHelper.filterKeyObj(
      currentData,
      discardSearchParams,
      allowSearchParams,
    );
  }

  let filterNextSearch = objHelper.filterKeyObj(filterCurrentSearch, nonNameKeySearchParams);

  filterNextSearch = objHelper.filteringExistedValue(filterNextSearch);

  filterCurrentSearch = objHelper.changeMultipleNameKey(filterCurrentSearch, removingPrevixParams);

  filterCurrentSearch = {
    ...filterCurrentSearch,
    paginate: filterCurrentSearch
      ? filterCurrentSearch[nameKeyPagination]
      : DEFAULT_PARAMS_PAGINATE_PAGE.paginate,
  };

  filterCurrentSearch = objHelper.filteringExistedValue(filterCurrentSearch);

  filterCurrentSearch = objHelper.filterKeyObj(filterCurrentSearch, [nameKeyPagination]);

  return {
    filterNextSearch,
    filterCurrentSearch,
  };
};

/**
 *
 * @param   { function }  thunkAPI, function from create async thunk
 * @param   { function }  serviceFunc, function that hitting server API
 * @param   { string }    localStorageName, name of localstorage, for store data
 * @param   { string }    nameKeySlicePayload, name payload key for store data in state global
 * @param   { array }     discardSearchParams, removing params search key
 * @param   { array }     allowSearchParams, keep params search key
 * @param   { string }    errorMessage, error message primary
 * @param   { bool }      showErrorMessage      decession for showing error message or not
 * @param   { string }    nameKeyPagination, name pagination key from BE
 * @param   { array }     nonNameKeySearchParams, which not search params in FE
 * @param   { array }     removingPrevixParams, name key params for removing, or generalize params key name
 * @param   { string }    nameResponseDataKey, name key response data, get data response from BE, data response
 * @param   { string }    nameStoreDataKey, where data is store, data list
 * @returns
 *      async function for refresh list data
 */
export const creatorListRefreshAction = async (
  thunkAPI,
  serviceFunc,
  localStorageName,
  nameKeySlicePayload,
  discardSearchParams = [],
  allowSearchParams = [],
  errorMessage,
  showErrorMessage = false,
  nameKeyPagination = 'per_page',
  nonNameKeySearchParams = ['per_page', 'current_page'],
  removingPrevixParams = ['search_', 'current_'],
  nameResponseDataKey = 'data',
  nameStoreDataKey = 'data',
) => {
  if (!Array.isArray(discardSearchParams)) {
    discardSearchParams = [];
  }

  const { filterNextSearch: filterNextSearchGen, filterCurrentSearch: filterCurrentSearchGen } =
    getParamsSearch(
      localStorageName,
      discardSearchParams,
      allowSearchParams,
      nameKeyPagination,
      nonNameKeySearchParams,
      removingPrevixParams,
    );

  const filterNextSearch = filterNextSearchGen || {};
  const filterCurrentSearch = filterCurrentSearchGen || {};

  try {
    const response = await serviceFunc(filterCurrentSearch);

    if (!response) throw { response };

    let { data: dataResponse, sucess } = response[nameResponseDataKey];

    if (!sucess) throw { response };

    if (!sucess || !Array.isArray(dataResponse[nameStoreDataKey])) {
      dataResponse = {
        ...dataResponse,
        [nameStoreDataKey]: [],
      };
    }

    // adding parameter search for next search
    const dataResWithSearch = Object.assign(dataResponse, { ...filterNextSearch });

    LocalStorage.set(localStorageName, dataResWithSearch);

    return { [nameKeySlicePayload]: dataResWithSearch };
  } catch (error) {
    if (!error.response) {
      messageHelper.serverInternalError(thunkAPI.dispatch);

      return thunkAPI.rejectWithValue(error.response);
    }

    const data = error.response[nameResponseDataKey];

    const responseMessage = messageHelper.getMessageFromResponseData(data);

    if (!responseMessage && showErrorMessage) {
      messageHelper.failedMessage(thunkAPI.dispatch, errorMessage, errorMessage, false);
    } else if (responseMessage && showErrorMessage) {
      messageHelper.failedMessage(
        thunkAPI.dispatch,
        responseMessage,
        errorMessage,
        errorMessage,
        false,
      );
    }

    return thunkAPI.rejectWithValue(data);
  }
};

/**
 *
 * @param   { function }  thunkAPI, function from create async thunk
 * @param   { function }  serviceFunc, function that hitting server API
 * @param   { string }    localStorageName, name of localstorage, for store data
 * @param   { string }    nameKeySlicePayload, name payload key for store data in state global
 * @param   { array }     discardSearchParams, removing params search key
 * @param   { array }     allowSearchParams, keep params search key
 * @param   { string }    errorMessage, error message primary
 * @param   { bool }      showErrorMessage      determine for showing error message or not
 * @param   { string }    nameKeyPagination, name pagination key from BE
 * @param   { array }     nonNameKeySearchParams, which not search params in FE
 * @param   { array }     removingPrevixParams, name key params for removing, or generalize params key name
 * @param   { string }    nameResponseDataKey, name key response data, get data response from BE, data response
 * @param   { string }    nameStoreDataKey, where data is store, data list
 * @returns
 *      async function for refresh list data
 *      where enhancement from top action, can store data with dynamic name storage and slice name
 *
 *      object that contains
 *      {
 *          sliceName: [slice name that you assign before],
 *          [sliceName]: [data return from backend for store on slice]
 *      }
 *
 * @example return
 *      {
 *          sliceName: 'dataListFnia',
 *          dataListFnia: {
 *              [...list response from API]
 *          }
 *      }
 */
export const creatorListRefreshActionWithDynamicStore = async (
  thunkAPI,
  serviceFunc,
  localStorageName,
  nameKeySlicePayload,
  discardSearchParams = [],
  allowSearchParams = [],
  errorMessage,
  showErrorMessage = true,
  nameKeyPagination = 'per_page',
  nonNameKeySearchParams = ['per_page', 'current_page'],
  removingPrevixParams = ['search_', 'current_'],
  nameResponseDataKey = 'data',
  nameStoreDataKey = 'data',
) => {
  const { filterNextSearch: filterNextSearchGen, filterCurrentSearch: filterCurrentSearchGen } =
    getParamsSearch(
      localStorageName,
      discardSearchParams,
      allowSearchParams,
      nameKeyPagination,
      nonNameKeySearchParams,
      removingPrevixParams,
    );

  const filterNextSearch = filterNextSearchGen || {};
  const filterCurrentSearch = filterCurrentSearchGen || {};

  try {
    const response = await serviceFunc(filterCurrentSearch);

    if (!response) throw { response };

    let { data: dataResponse, sucess } = response[nameResponseDataKey];

    if (!sucess) throw { response };

    if (!sucess || !Array.isArray(dataResponse[nameStoreDataKey])) {
      dataResponse = {
        ...dataResponse,
        [nameStoreDataKey]: [],
      };
    }

    // adding parameter search for next search
    const dataResWithSearch = Object.assign(dataResponse, { ...filterNextSearch });

    if (localStorageName) {
      LocalStorage.set(localStorageName, dataResWithSearch);
    }

    return {
      sliceName: nameKeySlicePayload,
      [nameKeySlicePayload]: dataResWithSearch,
    };
  } catch (error) {
    if (!error.response) {
      messageHelper.serverInternalError(thunkAPI.dispatch);

      return thunkAPI.rejectWithValue({
        sliceName: nameKeySlicePayload,
        [nameKeySlicePayload]: error.response,
      });
    }

    const data = error.response[nameResponseDataKey];

    const responseMessage = messageHelper.getMessageFromResponseData(data);

    if (!responseMessage && showErrorMessage) {
      messageHelper.failedMessage(thunkAPI.dispatch, errorMessage, errorMessage, false);
    } else if (responseMessage && showErrorMessage) {
      messageHelper.failedMessage(
        thunkAPI.dispatch,
        responseMessage,
        errorMessage,
        errorMessage,
        false,
      );
    }

    return thunkAPI.rejectWithValue({
      sliceName: nameKeySlicePayload,
      [nameKeySlicePayload]: data,
    });
  }
};

/**
 *
 * @param   { function }      thunkAPI                      function from create async thunk
 * @param   { function }      bodyDataAction                body data for action hit api
 * @param   { function }      serviceFunc                   service for sending body data action, in case hit api backend
 * @param   { string }        successMessage                success message string
 * @param   { string }        errorMessage                  error message string
 * @param   { string }        paramsTextPrimary             params text primary message for passing data
 * @param   { string }        paramsTextSecondary           params text secondary message for passing data
 * @param   { bool }          showFailedMessage             determine showing message or not
 * @returns
 *      async function for hitting api
 */
export const creatorAddAction = async (
  thunkAPI,
  bodyDataAction,
  serviceFunc,
  successMessage,
  errorMessage,
  paramsTextPrimary = {},
  paramsTextSecondary = {},
  showSuccessMessage = true,
  showFailedMessage = true,
) => {
  try {
    const response = await serviceFunc(bodyDataAction);

    if (!response) throw { response };

    const { data } = response;

    const { sucess } = data;

    if (!sucess) throw { response };

    if (showSuccessMessage) {
      const responseMessage = messageHelper.getMessageFromResponseData(data);

      if (!responseMessage) {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          successMessage,
          successMessage,
          paramsTextPrimary,
          paramsTextSecondary,
        );
      } else {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          responseMessage,
          successMessage,
          paramsTextPrimary,
          paramsTextSecondary,
        );
      }
    }

    return thunkAPI.fulfillWithValue(data);
  } catch (error) {
    const { response } = error;

    if (!response) {
      messageHelper.serverInternalError(thunkAPI.dispatch);

      return thunkAPI.rejectWithValue(response);
    }

    const { data } = response;
    const usageGettingResponseData = !data || isEmpty(data) ? response : data;

    const responseMessage = messageHelper.getMessageFromResponseData(usageGettingResponseData);

    if (showFailedMessage) {
      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          errorMessage,
          errorMessage,
          false,
          paramsTextPrimary,
          paramsTextSecondary,
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          errorMessage,
          false,
          paramsTextPrimary,
          paramsTextSecondary,
        );
      }
    }

    return thunkAPI.rejectWithValue(usageGettingResponseData);
  }
};

/**
 *
 * @param   { function }      thunkAPI, function from create async thunk
 * @param   { function }      bodyDataAction, body data for action hit api
 * @param   { function }      serviceFunc, service for sending body data action, in case hit api backend
 * @param   { string }        sliceName slice name where data response stored
 * @param   { string }        successMessage, success message string
 * @param   { string }        errorMessage, error message string
 * @param   { string }        paramsTextPrimary, params text primary message for passing data
 * @param   { string }        paramsTextSecondary, params text secondary message for passing data
 * @returns
 *      async function for hitting add api with store data on option
 */
export const creatorAddActionWithStoreDataOnSlice = async (
  thunkAPI,
  bodyDataAction,
  serviceFunc,
  sliceName,
  successMessage,
  errorMessage,
  paramsTextPrimary = {},
  paramsTextSecondary = {},
  isCombineResponseWithBodyDataAction = false,
  filterKeyBodyDataAction = [],
  keyNameCombineResponse = '',
  keyNameContainerCombineResponse = DEFAULT_KEY_NAME_CONTAINER_COMBINE_RESPONSE,
  additionalDispatchActions = [],
) => {
  try {
    const response = await serviceFunc(bodyDataAction);

    if (!response) throw { response };

    const { data } = response;

    const { sucess, data: dataResponseAPI } = data;

    let resultCombineResponseAPI = cloneDeep(dataResponseAPI);

    if (isCombineResponseWithBodyDataAction && !isEmpty(filterKeyBodyDataAction)) {
      if (typeof bodyDataAction === 'object') {
        filterKeyBodyDataAction = Array.isArray(filterKeyBodyDataAction)
          ? filterKeyBodyDataAction
          : typeof filterKeyBodyDataAction === 'string'
          ? [filterKeyBodyDataAction]
          : [filterKeyBodyDataAction[Object.keys(filterKeyBodyDataAction)[0]]];

        const selectedKeyBodyDataAction = pick(bodyDataAction, filterKeyBodyDataAction);

        resultCombineResponseAPI = {
          ...dataResponseAPI,
          [keyNameContainerCombineResponse]: {
            ...selectedKeyBodyDataAction,
          },
        };
      } else if (typeof bodyDataAction === 'string') {
        resultCombineResponseAPI = {
          ...dataResponseAPI,
          [keyNameContainerCombineResponse]: {
            [keyNameCombineResponse]: bodyDataAction,
          },
        };
      }
    }

    if (!sucess) throw { response };

    const responseMessage = messageHelper.getMessageFromResponseData(data);

    if (!isEmpty(successMessage)) {
      if (!responseMessage) {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          successMessage,
          successMessage,
          paramsTextPrimary,
          paramsTextSecondary,
        );
      } else {
        messageHelper.successMessageAuthorizated(
          thunkAPI.dispatch,
          responseMessage,
          successMessage,
          paramsTextPrimary,
          paramsTextSecondary,
        );
      }
    }

    if (!isEmpty(additionalDispatchActions) && Array.isArray(additionalDispatchActions)) {
      const dispatchActions = additionalDispatchActions.map((dispatchAction) => {
        return () => thunkAPI.dispatch(dispatchAction({ data: dataResponseAPI, bodyDataAction }));
      });

      await Promise.all(
        dispatchActions.map((singleDispatchAction) => {
          return singleDispatchAction();
        }),
      );
    }

    if (sliceName) {
      return {
        [sliceName]: resultCombineResponseAPI,
      };
    }

    return thunkAPI.fulfillWithValue(data);
  } catch (error) {
    const { response } = error;

    if (!response && !isEmpty(errorMessage)) {
      messageHelper.serverInternalError(thunkAPI.dispatch);

      return thunkAPI.rejectWithValue(response);
    }

    const { data } = response;
    const usageGettingResponseData = isEmpty(data) || !data ? response : data;

    const responseMessage = messageHelper.getMessageFromResponseData(usageGettingResponseData);

    if (!isEmpty(errorMessage)) {
      if (!responseMessage) {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          errorMessage,
          errorMessage,
          false,
          paramsTextPrimary,
          paramsTextSecondary,
        );
      } else {
        messageHelper.failedMessage(
          thunkAPI.dispatch,
          responseMessage,
          errorMessage,
          false,
          paramsTextPrimary,
          paramsTextSecondary,
        );
      }
    }

    return thunkAPI.rejectWithValue(usageGettingResponseData);
  }
};

/**
 *
 * @param   { function }      thunkAPI, function from create async thunk
 * @param   { function }      bodyDataAction, body data for action hit api
 * @param   { function }      serviceFunc, service for sending body data action, in case hit api backend
 * @param   { string }        successMessage, success message string
 * @param   { string }        errorMessage, error message string
 * @param   { string }        paramsTextPrimary, params text primary message for passing data
 * @param   { string }        paramsTextSecondary, params text secondary message for passing data
 * @param   { string }        uniqueKeyDataResponse params response that distinguish with another data respose
 * @param   { string }        baseContentUrl where last time, content url on this time, this is for base url when link with additionalSearchParamsUrl
 * @param   { object }        additionalSearchParamsUrl when using link, you must adding search params url
 * @param   { bool }          isShowPreviewButton choose for showing link or not in button
 * @returns
 *      async function for hitting api add action, usually with preview button on bottom
 */
export const creatorAddActionWithPreviewButton = async (
  thunkAPI,
  bodyDataAction,
  serviceFunc,
  successMessage,
  errorMessage,
  paramsTextPrimary = {},
  paramsTextSecondary = {},
  uniqueKeyDataResponse = '',
  baseContentUrl = '',
  additionalSearchParamsUrl = {},
  isShowPreviewButton = true,
) => {
  try {
    const response = await serviceFunc(bodyDataAction);

    if (!response) throw { response };

    const { data, sucess } = response.data;

    if (!sucess) throw { response };

    const selectedUniqueResponseData = objHelper.findAllValuesByKey(data, uniqueKeyDataResponse);

    const searchParamsUrlInObject = {
      ...additionalSearchParamsUrl,
      [uniqueKeyDataResponse]: selectedUniqueResponseData[0],
    };

    const searchParamsUrlInString = objectToQueryString(searchParamsUrlInObject);

    const linkNavigatePreviewButton = baseContentUrl + '?' + searchParamsUrlInString;

    const responseMessage = messageHelper.getMessageFromResponseData(response.data);

    if (!responseMessage) {
      messageHelper.successMessageAuthorizatedWithPreviewButton(
        thunkAPI.dispatch,
        successMessage,
        successMessage,
        paramsTextPrimary,
        paramsTextSecondary,
        linkNavigatePreviewButton,
        isShowPreviewButton,
      );
    } else {
      messageHelper.successMessageAuthorizatedWithPreviewButton(
        thunkAPI.dispatch,
        responseMessage,
        successMessage,
        paramsTextPrimary,
        paramsTextSecondary,
        linkNavigatePreviewButton,
        isShowPreviewButton,
      );
    }

    return thunkAPI.fulfillWithValue(data);
  } catch (error) {
    const { response } = error;

    if (!response) {
      messageHelper.serverInternalError(thunkAPI.dispatch);

      return thunkAPI.rejectWithValue(response);
    }

    const { data } = response;
    const usageGettingResponseData = isEmpty(data) || !data ? response : data;

    const responseMessage = messageHelper.getMessageFromResponseData(usageGettingResponseData);

    if (!responseMessage) {
      messageHelper.failedMessage(
        thunkAPI.dispatch,
        errorMessage,
        errorMessage,
        false,
        paramsTextPrimary,
        paramsTextSecondary,
      );
    } else {
      messageHelper.failedMessage(
        thunkAPI.dispatch,
        responseMessage,
        errorMessage,
        false,
        paramsTextPrimary,
        paramsTextSecondary,
      );
    }

    return thunkAPI.rejectWithValue(usageGettingResponseData);
  }
};

/**
 *
 * @param   {0}
 * each object use on key name
 *
 * @param   { function }      thunkAPI                  function from create async thunk
 * @param   { function }      bodyDataAction            body data for action hit api
 * @param   { function }      serviceFunc               service for sending body data action, in case hit api backend
 * @param   { string }        successMessage            success message string
 * @param   { string }        errorMessage              error message string
 * @param   { object }        paramsTextPrimary         params text primary message for passing data
 *      @default    {}
 *
 * @param   { object }        paramsTextSecondary       params text secondary message for passing data
 *      @default    {}
 *
 * @param   { function }      generateButtonLink        function generate button link, callback from sub response data
 * @param   { string }        baseContentUrl            where last time, content url on this time, this is for base url when link with additionalSearchParamsUrl
 * @param   { string }        keyNameResponseData       getting response key name from API
 *      @default    'data'
 *
 * @param   { string }        keyNameSubResponseData    key name sub response from API after response data
 *      @default    'data'
 *
 * @param   { bool }          isShowPreviewButton       choose for showing link or not in button
 *      @default    true
 * @returns
 *      async function for hitting api add action, usually with preview bottom button with generating link
 */
export const creatorAddActionWithPreviewButtonGenerateLink = async ({
  thunkAPI,
  bodyDataAction,
  serviceFunc,
  successMessage,
  errorMessage,
  paramsTextPrimary = {},
  paramsTextSecondary = {},
  generateButtonLink,
  baseContentUrl,
  keyNameResponseData = DEFAULT_KEY_NAME_RESPONSE_DATA_API_REF,
  keyNameSubResponseData = DEFAULT_KEY_NAME_SUB_RESPONSE_DATA_API_REF,
  isShowPreviewButton = true,
}) => {
  try {
    const response = await serviceFunc(bodyDataAction);

    if (!response) throw { response };

    const { data, sucess } = response.data;

    if (!sucess) throw { response };

    const subResponseData =
      keyNameResponseData && keyNameSubResponseData
        ? response[keyNameResponseData][keyNameSubResponseData]
        : null;

    let linkNavigatePreviewButton = baseContentUrl;
    if (!isEmpty(subResponseData) && typeof generateButtonLink === 'function') {
      linkNavigatePreviewButton = generateButtonLink({
        ...bodyDataAction,
        ...subResponseData,
      });
    }

    const responseMessage = messageHelper.getMessageFromResponseData(response.data);

    if (!responseMessage) {
      messageHelper.successMessageAuthorizatedWithPreviewButton(
        thunkAPI.dispatch,
        successMessage,
        successMessage,
        paramsTextPrimary,
        paramsTextSecondary,
        linkNavigatePreviewButton,
        isShowPreviewButton,
      );
    } else {
      messageHelper.successMessageAuthorizatedWithPreviewButton(
        thunkAPI.dispatch,
        responseMessage,
        successMessage,
        paramsTextPrimary,
        paramsTextSecondary,
        linkNavigatePreviewButton,
        isShowPreviewButton,
      );
    }

    return thunkAPI.fulfillWithValue(data);
  } catch (error) {
    const { response } = error;

    if (!response) {
      messageHelper.serverInternalError(thunkAPI.dispatch);

      return thunkAPI.rejectWithValue(response);
    }

    const { data } = response;
    const usageGettingResponseData = isEmpty(data) || !data ? response : data;

    const responseMessage = messageHelper.getMessageFromResponseData(usageGettingResponseData);

    if (!responseMessage) {
      messageHelper.failedMessage(
        thunkAPI.dispatch,
        errorMessage,
        errorMessage,
        false,
        paramsTextPrimary,
        paramsTextSecondary,
      );
    } else {
      messageHelper.failedMessage(
        thunkAPI.dispatch,
        responseMessage,
        errorMessage,
        false,
        paramsTextPrimary,
        paramsTextSecondary,
      );
    }

    return thunkAPI.rejectWithValue(usageGettingResponseData);
  }
};

/**
 *
 * @param   { function }      thunkAPI, function from create async thunk
 * @param   { function }      bodyDataAction, body data for action hit api
 * @param   { function }      serviceFunc, service for sending body data action, in case hit api backend
 * @param   { string }        successMessage, success message string
 * @param   { string }        errorMessage, error message string
 * @param   { string }        paramsTextPrimary, params text primary message for passing data
 * @param   { string }        paramsTextSecondary, params text secondary message for passing data
 * @param   { array }         additionalDispatchActions     additional for actions that for dispatching when successully
 * @returns
 *      async function for hitting update hit api
 */
export const creatorUpdateAction = async (
  thunkAPI,
  bodyDataAction,
  serviceFunc,
  successMessage,
  errorMessage,
  paramsTextPrimary = {},
  paramsTextSecondary = {},
  additionalDispatchActions = [],
) => {
  try {
    const response = await serviceFunc(bodyDataAction);

    if (!response) throw { response };

    const { data, sucess } = response.data;

    if (!sucess) throw { response };

    const responseMessage = messageHelper.getMessageFromResponseData(response.data);

    if (!responseMessage) {
      messageHelper.successMessageAuthorizated(
        thunkAPI.dispatch,
        successMessage,
        successMessage,
        paramsTextPrimary,
        paramsTextSecondary,
      );
    } else {
      messageHelper.successMessageAuthorizated(
        thunkAPI.dispatch,
        responseMessage,
        successMessage,
        paramsTextPrimary,
        paramsTextSecondary,
      );
    }

    if (!isEmpty(additionalDispatchActions) && Array.isArray(additionalDispatchActions)) {
      const dispatchActions = additionalDispatchActions.map((dispatchAction) => {
        return () => thunkAPI.dispatch(dispatchAction({ data, bodyDataAction }));
      });

      await Promise.all(
        dispatchActions.map((singleDispatchAction) => {
          return singleDispatchAction();
        }),
      );
    }

    return thunkAPI.fulfillWithValue(data);
  } catch (error) {
    const { response } = error;

    if (!response) {
      messageHelper.serverInternalError(thunkAPI.dispatch);

      return thunkAPI.rejectWithValue(response);
    }

    const { data } = response;

    const usageGettingResponseData = !data || isEmpty(data) ? response : data;
    const responseMessage = messageHelper.getMessageFromResponseData(usageGettingResponseData);

    if (!responseMessage) {
      messageHelper.failedMessage(
        thunkAPI.dispatch,
        errorMessage,
        errorMessage,
        false,
        paramsTextPrimary,
        paramsTextSecondary,
      );
    } else {
      messageHelper.failedMessage(
        thunkAPI.dispatch,
        responseMessage,
        errorMessage,
        false,
        paramsTextPrimary,
        paramsTextSecondary,
      );
    }

    return thunkAPI.rejectWithValue(usageGettingResponseData);
  }
};

/**
 *
 * @param   { function }      thunkAPI, function from create async thunk
 * @param   { object }        paramsDeleteAction, params data for action hit api
 * @param   { function }      serviceFunc, service for sending body data action, in case hit api backend
 * @param   { string }        successMessage, success message string
 * @param   { string }        errorMessage, error message string
 * @param   { string }        paramsTextPrimary, params text primary message for passing data
 * @param   { string }        paramsTextSecondary, params text secondary message for passing data
 * @returns
 *      async delete function for hit api
 */
export const creatorDeleteAction = async (
  thunkAPI,
  paramsDeleteAction,
  serviceFunc,
  successMessage,
  errorMessage,
  paramsTextPrimary = {},
  paramsTextSecondary = {},
) => {
  try {
    const response = await serviceFunc(paramsDeleteAction);

    if (!response) throw { response };

    const { data, sucess } = response.data;

    if (!sucess) throw { response };

    const responseMessage = messageHelper.getMessageFromResponseData(data);

    if (!responseMessage) {
      messageHelper.successMessageAuthorizated(
        thunkAPI.dispatch,
        successMessage,
        successMessage,
        paramsTextPrimary,
        paramsTextSecondary,
      );
    } else {
      messageHelper.successMessageAuthorizated(
        thunkAPI.dispatch,
        responseMessage,
        successMessage,
        paramsTextPrimary,
        paramsTextSecondary,
      );
    }

    return thunkAPI.fulfillWithValue(data);
  } catch (error) {
    const { response } = error;

    if (!response) {
      messageHelper.serverInternalError(thunkAPI.dispatch);

      return thunkAPI.rejectWithValue(response);
    }

    const { data } = response;

    const responseMessage = messageHelper.getMessageFromResponseData(data);

    if (!responseMessage) {
      messageHelper.failedMessage(
        thunkAPI.dispatch,
        errorMessage,
        errorMessage,
        false,
        paramsTextPrimary,
        paramsTextSecondary,
      );
    } else {
      messageHelper.failedMessage(
        thunkAPI.dispatch,
        responseMessage,
        errorMessage,
        false,
        paramsTextPrimary,
        paramsTextSecondary,
      );
    }

    return thunkAPI.rejectWithValue(data);
  }
};

/**
 *
 * @param   { object }        params0                       contained as below
 *
 * @param   { function }      thunkAPI                      function from create async thunk
 * @param   { object }        paramsDeleteAction            params data for action hit api
 * @param   { function }      serviceFunc                   service for sending body data action, in case hit api backend
 * @param   { string }        successMessage                success message string
 * @param   { string }        errorMessage                  error message string
 * @param   { string }        paramsTextPrimary             params text primary message for passing data
 * @param   { string }        paramsTextSecondary           params text secondary message for passing data
 * @param   { boolean }       isMessageShow                 determine on success or error is showing message or not
 * @returns
 *      async delete function for hit api with enhancement for params where changed to object
 */
export const creatorDeleteActionV2 = async ({
  thunkAPI,
  paramsDeleteAction,
  serviceFunc,
  successMessage,
  errorMessage,
  paramsTextPrimary = {},
  paramsTextSecondary = {},
  isMessageShow = true,
}) => {
  try {
    const response = await serviceFunc(paramsDeleteAction);

    if (!response) throw { response };

    const responseData = response[DEFAULT_KEY_NAME_RESPONSE_DATA_API_REF];
    const subResponseData = responseData[DEFAULT_KEY_NAME_SUB_RESPONSE_DATA_API_REF];
    const indicatorSuccessResponse = responseData[DEFAULT_KEY_NAME_SUCESS_API_REF];

    if (!indicatorSuccessResponse) throw { response };

    if (isMessageShow) {
      const responseMessage = messageHelper.getMessageFromResponseData(subResponseData);

      let messageParams = [
        thunkAPI.dispatch,
        successMessage,
        successMessage,
        paramsTextPrimary,
        paramsTextSecondary,
      ];

      if (responseMessage) {
        messageParams = [
          thunkAPI.dispatch,
          responseMessage,
          successMessage,
          paramsTextPrimary,
          paramsTextSecondary,
        ];
      }

      messageHelper.successMessageAuthorizated(...messageParams);
    }

    return thunkAPI.fulfillWithValue(subResponseData);
  } catch (error) {
    const { response } = error;

    if (!response) {
      if (isMessageShow) {
        messageHelper.serverInternalError(thunkAPI.dispatch);
      }

      return thunkAPI.rejectWithValue(response);
    }

    const responseData = response[DEFAULT_KEY_NAME_RESPONSE_DATA_API_REF];

    if (isMessageShow) {
      const responseMessage = messageHelper.getMessageFromResponseData(responseData);

      let messageParams = [
        thunkAPI.dispatch,
        errorMessage,
        errorMessage,
        false,
        paramsTextPrimary,
        paramsTextSecondary,
      ];

      if (responseMessage) {
        messageParams = [
          thunkAPI.dispatch,
          responseMessage,
          errorMessage,
          false,
          paramsTextPrimary,
          paramsTextSecondary,
        ];
      }

      messageHelper.failedMessage(...messageParams);
    }

    return thunkAPI.rejectWithValue(responseData);
  }
};

/**
 *
 * @param { object } param0    contains
 *      {
 *          primaryLocalStorageData     {object}           data for primary when store on localstorage
 *          keyNameForLocalStorageData  { string }      name key where data is store on object primary local storage data
 *      }
 * @returns
 *      reducing array when data are going to store on local storage
 */
function simplifyStoragedDataOnLocalStorage({
  primaryLocalStorageData,
  keyNameForLocalStorageData,
}) {
  if (typeof primaryLocalStorageData !== 'object' || typeof keyNameForLocalStorageData !== 'string')
    return primaryLocalStorageData;

  let storagedDataOnLocalStore = primaryLocalStorageData[keyNameForLocalStorageData];

  if (Array.isArray(storagedDataOnLocalStore)) {
    storagedDataOnLocalStore = arrHelp.simplifyItem({
      arr: storagedDataOnLocalStore,
      dataMaxLength: DEFAULT_DATA_MAX_LENGTH_ON_LOCAL_STORAGE,
    });

    primaryLocalStorageData = {
      ...primaryLocalStorageData,
      [keyNameForLocalStorageData]: storagedDataOnLocalStore,
    };
  }

  return primaryLocalStorageData;
}

/**
 *
 * @param { object } resultPayload  result from action payload, from action function
 * @param { string } keyName        key name of result payload
 * @returns
 *      slice name that from result payload action
 */
export const getSliceNameFromDynamicStore = (resultPayload, keyName = 'sliceName') => {
  if (isEmpty(resultPayload)) return '';
  else if (isEmpty(resultPayload[keyName]) || !(keyName in resultPayload)) return '';

  return resultPayload[keyName];
};

/**
 *
 * @param { object } param0
 *
 * @param   { object }      childrenState           object that contained selected state
 * @param   { string }      searchParamsPrefix      prefix for on search params
 *
 * @returns
 */
export const getParamsSearchV2 = ({ childrenState = {}, searchParamsPrefix = 'search_' }) => {
  if (isEmpty(childrenState)) return childrenState;

  const keysChildrenState = Object.keys(childrenState);

  const keysContainedSearchParams = keysChildrenState.filter((keyChildrenState) => {
    return keyChildrenState.startsWith(searchParamsPrefix);
  });

  const childrenStateSearchParamsOnly = pick(childrenState, keysContainedSearchParams);

  return objHelper.changeSuffixKey(childrenStateSearchParamsOnly, searchParamsPrefix, true);
};
