import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Chip, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import clsx from 'clsx';
import { isEmpty, pick } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import withPermission from '../../../hoc/withPermission';
import { withRouter } from '../../../hoc/withRouter';
import withValueFormatter from '../../../hoc/withValueFormatter';
import { permissionFeatureType } from '../../../utils/default/permission.default';
import arrHelp from '../../../utils/helpers/array.helpers';
import formatHelp from '../../../utils/helpers/format.helpers';
import objHelper from '../../../utils/helpers/object.helper';
import TypographyLinkPrimary from '../../typography/TypographyLinkPrimary/TypographyLinkPrimary';

// format data on each column
export const FORMAT_COMMON_TYPOGRAPHY_COLUMN = 'common';
export const FORMAT_CURRENCY_AUTO_COMMA_COLUMN = 'currency-auto-comma';
export const FORMAT_MANUAL_FORMATTER_DATA = 'manual-format-data';
export const FORMAT_DATE_TIME_READABLE = 'date-time-readable';
export const FORMAT_TRANSLATION_COLUMN = 'translation-column';
export const FORMAT_CALCULATE_BASED_NAMES_N_SIGN_AUTO_COMMA =
  'calculate-based-names-n-sign-auto-comma';

// formatting on single row of data,
// and passing on formatterSingleRowData for function
export const FORMAT_MANUAL_FORMATTER_SINGLE_ROW_DATA = 'manual-formatter-single-row-data';

export const REPRESENTATION_DATA_TYPOGRAPHY_TYPE = 'typography';
export const REPRESENTATION_DATA_LINK_TYPE = 'link';
export const REPRESENTATION_DATA_LABEL_TYPE = 'label';
export const REPRESENTATION_DATA_CHIP_WITH_SELECT_ON_ITEM_LIST_OBJECT_KEYNAME_TYPE =
  'chip-with-select-on-item-list-object-key-name';
export const REPRESENTATION_DATA_RENDER_BUTTON = 'render-button';

/**
 * @prop        { array }       dataList                data that for creating list
 * @prop        { array }       tableBodyColumn         column for table body
 *          each items on table body column minimum contain
 *              name                        { string }          name key that from item data list
 *              formatData                  { string }          formatting data before view
 *              formatterData               { function }        function that formatting current data, you can getting current data as callback
 *              formatterSingleRowData      { function }        function that formatting on single row data, that you getting on single row for formatting data as callback
 *
 *              generateListWithTranslationForSelectItems       { function }        function for generating list with translation
 *
 *              selectObjectValueOnListItemProps        { object }      that property for getting identity, label, and color for chip
 *                  @default  {identity:'value',labelKeyName:'label',colorKeyname:'colorChip',usageTranslationWhenGenerateList:true}
 *
 *              isUseMultipleNames          { bool }            determine you can multiple name column in one table data
 *              multipleNames               { array }           multiple configuration for getting key name
 *                      each item contain atleast
 *                          name                { string }      key name of items list
 *
 *                          typographyProps     { object }      additional typography on each data
 *
 *              usageMultiple2Dnames        { bool }            determine for using multiple 2D with names
 *              multiple2Dnames             { array }           multiple 2D configuraton for getting all key names
 *                      each item array contain at-least
 *                          name                { string }      getting value from data row
 *
 *                      additional:
 *                          formatData                    { string }        format data where came from selected by key name
 *                          formatterData                 { function }      formatter of data from selected keyname
 *                          formatterSingleRowData        { function }      formatter data of single row
 *                          substituteNotExistValue       { string }        subtitue when value is not existed
 *                          emptyValues                   { array }         re checking for empty values
 *                          representationDataType        { string }        data can representation for, available: typography, label, and link
 *                          generateLink                  { function }      generate link for representation data link, callback from single row data
 *                          defaultLink                   { string }        default when link not available or initialize it
 *                          typographyProps[deprecated] -> changes to representationDataProps       { object }      inject for representation data props
 *
 *
 *
 *              align                       { string }          alignment on each table cell
 *              substituteNotExistValue     { string }          subtitue value when not existed
 *              emptyValues                 { array }           items can indicate empty of current value
 *
 * @prop        { object }      additionalTableCellProps        additional props for each table cell
 * @prop        { object }      additionalTableRowProps         additional props for each table row
 * @prop        { bool }        usageClickEntireRow             determine for clicked entire row action
 *          @default        false
 *
 * @prop        { function }    onClickEntireRow                function for handling click entire row
 *                                                              throw event and current data list on row
 *
 * @prop        { bool }        usageDoubleClickEntireRow       determine usaged for double clicked entire row action
 *          @default        false
 *
 * @prop        { function }    onDoubleClickEntireRow          function for handling double click entire row
 *                                                              throw event and current data list on row
 */

// todo: enhacement on multiple 2d names
// suitable another multiple2Dnames for getting for component and rendering value
class TableRowPrimary extends Component {
  constructor(props) {
    super(props);

    this.defaultSelectObjectValueOnListItemProps = {
      identity: 'value',
      labelKeyName: 'label',
      colorKeyname: 'colorChip',
      usageTranslationWhenGenerateList: true,
    };

    this.generateRepresentationData = this.generateRepresentationData.bind(this);
  }

  formattingViewValue({
    index = 0,
    value,
    valueSingleRowData,
    formatData,
    formatterData,
    formatterSingleRowData,
    substituteNotExistValue,
    emptyValues,
    translationValue,
    translationKeynameProps = [],
    translationValuePropsFormatter,
    correspondKeynameAndSign,
    ...props
  }) {
    const { t, i18n, withValueFormatterMethod } = this.props;

    const { language } = i18n;

    if (!Number.isNaN(Number(value)) && formatData === FORMAT_CURRENCY_AUTO_COMMA_COLUMN) {
      value = formatHelp.currencyWithAutoComma(Number(value));
    } else if (formatData === FORMAT_CURRENCY_AUTO_COMMA_COLUMN) {
      value = formatHelp.reverseCurrencyFormatWithRegex(value);

      if (!Number.isNaN(Number(value))) {
        value = formatHelp.currencyWithAutoComma(Number(value));
      }
    }

    if (formatData === FORMAT_DATE_TIME_READABLE && !Number.isNaN(new Date(value).getTime())) {
      value = formatHelp.getReadableDateV2(value);
    }

    if (typeof formatterData === 'function' && formatData === FORMAT_MANUAL_FORMATTER_DATA) {
      value = formatterData(value);
    }

    if (
      typeof formatterSingleRowData === 'function' &&
      formatData === FORMAT_MANUAL_FORMATTER_SINGLE_ROW_DATA
    ) {
      value = formatterSingleRowData(valueSingleRowData, language);
    }

    if (formatData === FORMAT_TRANSLATION_COLUMN) {
      let translationValues = pick(valueSingleRowData, translationKeynameProps);

      if (typeof translationValuePropsFormatter === 'function') {
        translationValues = translationValuePropsFormatter(translationValues);
      }

      value = t(translationValue, translationValues);
    }

    if (
      formatData === FORMAT_CALCULATE_BASED_NAMES_N_SIGN_AUTO_COMMA &&
      !isEmpty(correspondKeynameAndSign)
    ) {
      value = objHelper.calculateObjValueBasedKeyNamesNsign(
        valueSingleRowData,
        correspondKeynameAndSign,
      );

      value = formatHelp.wrapperCurrencyWithAutoCommaV1(value);
    }

    if (withValueFormatterMethod.canFormatted(formatData)) {
      value = withValueFormatterMethod.valueFormatterV1({
        t,
        i18n,
        index,
        format: formatData,
        value,
        containerValue: valueSingleRowData,
        emptyValueSubtitute: substituteNotExistValue,
        ...props,
      });
    }

    const isvalueEmpty = Array.isArray(emptyValues) ? emptyValues.includes(value) : false;

    value = isvalueEmpty || !value ? substituteNotExistValue : value;

    return value;
  }

  generateRepresentationData({
    viewValue = '',
    valueSingleRowData,
    representationDataType = REPRESENTATION_DATA_TYPOGRAPHY_TYPE,
    representationDataProps,
    generateLink,
    generateLinkFromCurrentLocation,
    defaultLink,
    generateListWithTranslationForSelectItems,
    selectObjectValueOnListItemProps = this.defaultSelectObjectValueOnListItemProps,
    renderButton,
  }) {
    const { t } = this.props;

    if (representationDataType === REPRESENTATION_DATA_TYPOGRAPHY_TYPE) {
      const { className: additionalClassName } = representationDataProps;

      return (
        <Typography
          variant={'body6'}
          {...representationDataProps}
          className={clsx(['limitWord', additionalClassName])}
        >
          {viewValue}
        </Typography>
      );
    }

    if (representationDataType === REPRESENTATION_DATA_LINK_TYPE) {
      let usageLink = defaultLink;
      if (typeof generateLink === 'function') {
        usageLink = generateLink(valueSingleRowData);
      } else if (typeof generateLinkFromCurrentLocation === 'function') {
        usageLink = generateLinkFromCurrentLocation({
          currentLocation: this.props.location,
          singleRowValue: valueSingleRowData,
        });
      }

      return (
        <TypographyLinkPrimary
          baseUrl={usageLink}
          typographyValue={viewValue}
          {...representationDataProps}
        />
      );
    }

    if (representationDataType === REPRESENTATION_DATA_LABEL_TYPE) {
      return <label {...representationDataProps}>{viewValue}</label>;
    }

    if (
      representationDataType ===
        REPRESENTATION_DATA_CHIP_WITH_SELECT_ON_ITEM_LIST_OBJECT_KEYNAME_TYPE &&
      typeof generateListWithTranslationForSelectItems === 'function' &&
      !isEmpty(selectObjectValueOnListItemProps)
    ) {
      const { usageTranslationWhenGenerateList = true } = selectObjectValueOnListItemProps;

      let listSelectItems = generateListWithTranslationForSelectItems();
      if (usageTranslationWhenGenerateList)
        listSelectItems = generateListWithTranslationForSelectItems(t);

      if (isEmpty(listSelectItems)) return viewValue;

      const { identity, labelKeyName, colorKeyname } = selectObjectValueOnListItemProps;

      let selectedObjectItemsOnArr = arrHelp.filterObjDataWithID(
        listSelectItems,
        identity,
        viewValue,
      );
      if (isEmpty(selectedObjectItemsOnArr)) selectedObjectItemsOnArr = listSelectItems[0];

      return (
        <Chip
          size='small'
          label={selectedObjectItemsOnArr[labelKeyName]}
          color={selectedObjectItemsOnArr[colorKeyname]}
        />
      );
    }

    if (
      representationDataType === REPRESENTATION_DATA_RENDER_BUTTON &&
      typeof renderButton === 'function'
    ) {
      return renderButton(valueSingleRowData);
    }

    return viewValue;
  }

  render() {
    const {
      dataList,
      tableBodyColumn,
      additionalTableCellProps = {},
      additionalTableRowProps = {},
      usageClickEntireRow = false,
      onClickEntireRow,
      usageDoubleClickEntireRow = false,
      onDoubleClickEntireRow,
      withPermissionMethod,
      doubleClickPermissionProps = {},
    } = this.props;

    if (isEmpty(dataList) || !Array.isArray(dataList)) return null;
    if (isEmpty(tableBodyColumn) || !Array.isArray(tableBodyColumn)) return null;

    const { isGranted } = withPermissionMethod;
    const isDoubleClickGranted = isGranted({
      permissionType: permissionFeatureType,
      ...doubleClickPermissionProps,
    });

    return (
      <>
        {dataList.map((datum, indexDatum) => {
          return (
            <TableRow
              hover
              key={indexDatum}
              tabIndex={-1}
              className={clsx({
                hoverEntireRow:
                  usageClickEntireRow || (usageDoubleClickEntireRow && isDoubleClickGranted),
              })}
              onClick={usageClickEntireRow ? (event) => onClickEntireRow(event, datum) : null}
              onDoubleClick={
                usageDoubleClickEntireRow && isDoubleClickGranted
                  ? (event) => onDoubleClickEntireRow(event, datum)
                  : null
              }
              {...additionalTableRowProps}
            >
              {tableBodyColumn.map((singleTableBodyColumn, indexTableBodyColumn) => {
                const {
                  name: columnName,
                  isUseMultipleNames = false,
                  usageMultiple2Dnames = false,
                  multipleNames = [],
                  multiple2Dnames = [],
                  align = 'left',
                  substituteNotExistValue = '-',
                  formatData = FORMAT_COMMON_TYPOGRAPHY_COLUMN,
                  formatterData,
                  formatterSingleRowData,
                  emptyValues = [],
                  representationDataType,
                  generateLink,
                  generateLinkFromCurrentLocation,
                  defaultLink,
                  representationDataProps,
                  selectObjectValueOnListItemProps,
                  generateListWithTranslationForSelectItems,
                  colSpan = 1,
                  translationValue = null,
                  translationKeynameProps = null,
                  translationValuePropsFormatter = null,
                  renderButton = null,
                  correspondKeynameAndSign = {},
                  ...props
                } = singleTableBodyColumn;

                let viewValue = datum[columnName],
                  columnNames = [];

                if (isUseMultipleNames) {
                  columnNames = arrHelp.mappingChildrenKeyOnObject(multipleNames, 'name');

                  if (!isEmpty(columnNames)) {
                    return (
                      <TableCell
                        align={align}
                        key={indexTableBodyColumn + name}
                        colSpan={colSpan}
                        {...additionalTableCellProps}
                      >
                        <Stack direction={'column'}>
                          {!isEmpty(multipleNames) &&
                            multipleNames.map((singleName, index) => {
                              const { name, typographyProps = {} } = singleName;

                              let viewValue = datum[name];

                              if (!viewValue) return null;

                              return (
                                <Typography
                                  key={index}
                                  variant={'body6'}
                                  color={!index ? 'black' : grey[600]}
                                  {...typographyProps}
                                >
                                  {viewValue || substituteNotExistValue}
                                </Typography>
                              );
                            })}
                        </Stack>
                      </TableCell>
                    );
                  }
                }

                if (
                  usageMultiple2Dnames &&
                  multiple2Dnames.length &&
                  Array.isArray(multiple2Dnames)
                ) {
                  const uniqueIDForMultiple2Dnames = arrHelp.generateArrWithFunc(
                    multiple2Dnames.length,
                    uuidv4,
                  );

                  return (
                    <TableCell
                      align={align}
                      key={uniqueIDForMultiple2Dnames[indexTableBodyColumn] + name}
                      colSpan={colSpan}
                      {...additionalTableCellProps}
                    >
                      <Stack direction={'column'}>
                        {!isEmpty(multiple2Dnames) &&
                          multiple2Dnames.map((multipleNames, indexMultipleNames) => {
                            const uniqueIDForMultipleNames = arrHelp.generateArrWithFunc(
                              multipleNames.length,
                              uuidv4,
                            );

                            multipleNames = Array.isArray(multipleNames)
                              ? multipleNames
                              : [multipleNames];

                            return (
                              <Stack
                                spacing={1}
                                direction={'row'}
                                alignItems={'end'} // fixing: trouble not centered on table cell
                                key={uniqueIDForMultiple2Dnames[indexTableBodyColumn] + uuidv4()}
                              >
                                {Array.isArray(multipleNames) &&
                                  multipleNames.map((singleName) => {
                                    const {
                                      name,
                                      representationDataProps = {},
                                      formatData: formatDataSingleName,
                                      formatterData: formatterDataSingleName,
                                      formatterSingleRowData: formatterSingleRowDataSingleName,
                                      substituteNotExistValue:
                                        substituteNotExistValueSingleName = '-',
                                      emptyValues: emptyValuesSingleName,
                                      representationDataType: representationDataTypeSingleName,
                                      generateLink: generateLinkSingleName,
                                      defaultLink: defaultLinkSingleName,
                                      ...props
                                    } = singleName;

                                    let viewValue =
                                      datum[name] || substituteNotExistValueSingleName;
                                    viewValue = this.formattingViewValue({
                                      index: indexDatum,
                                      value: viewValue,
                                      valueSingleRowData: datum,
                                      formatData: formatDataSingleName,
                                      formatterData: formatterDataSingleName,
                                      formatterSingleRowData: formatterSingleRowDataSingleName,
                                      substituteNotExistValue: substituteNotExistValueSingleName,
                                      emptyValues: emptyValuesSingleName,
                                      correspondKeynameAndSign,
                                      ...props,
                                    });

                                    return [
                                      this.generateRepresentationData({
                                        viewValue,
                                        valueSingleRowData: datum,
                                        representationDataType: representationDataTypeSingleName,
                                        generateLink: generateLinkSingleName,
                                        defaultLink: defaultLinkSingleName,
                                        representationDataProps: {
                                          variant: 'body6',
                                          color: !indexMultipleNames ? 'black' : grey[600],
                                          ...representationDataProps,
                                          key:
                                            uniqueIDForMultipleNames[indexMultipleNames] +
                                            indexMultipleNames,
                                        },
                                      }),
                                    ];
                                  })}
                              </Stack>
                            );
                          })}
                      </Stack>
                    </TableCell>
                  );
                }

                viewValue = this.formattingViewValue({
                  index: indexDatum,
                  value: viewValue,
                  valueSingleRowData: datum,
                  formatData,
                  formatterData,
                  formatterSingleRowData,
                  substituteNotExistValue,
                  emptyValues,
                  selectObjectValueOnListItemProps,
                  translationValue,
                  translationKeynameProps,
                  translationValuePropsFormatter,
                  correspondKeynameAndSign,
                  ...props,
                });

                return (
                  <TableCell
                    align={align}
                    key={indexTableBodyColumn + name}
                    colSpan={colSpan}
                    {...additionalTableCellProps}
                  >
                    {this.generateRepresentationData({
                      viewValue,
                      valueSingleRowData: datum,
                      representationDataType,
                      generateLink,
                      generateLinkFromCurrentLocation,
                      defaultLink,
                      representationDataProps: {
                        ...representationDataProps,
                        key: uuidv4(),
                      },
                      selectObjectValueOnListItemProps,
                      generateListWithTranslationForSelectItems,
                      renderButton,
                    })}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </>
    );
  }
}

const TableRowPrimaryWithRouter = withRouter(TableRowPrimary);
const TableRowPrimaryWithTranslation = withTranslation()(TableRowPrimaryWithRouter);
const TableRowPrimaryWithValueFormatter = withValueFormatter(TableRowPrimaryWithTranslation);
const TableRowPrimaryWithPermission = withPermission({})(TableRowPrimaryWithValueFormatter);

export default TableRowPrimaryWithPermission;
