import { cloneDeep, concat, isEmpty, omit, pick } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { decryptData, encryptData } from '../../services/modules/Crypto/Crypto.service';
import {
  DEFAULT_ALL_TYPES_CRYPTOGRAPHY,
  DEFAULT_TYPE_DECRYPT_CRYPTOHRAPHY,
  DEFAULT_TYPE_ENCRYPT_CRYPTOGRAPHY,
} from '../default/type-cryptography.default';

import arrHelp from './array.helpers';
import strHelp from './string.helpers';

function isObjHasOwnProperty(obj, nameProperty) {
  if (typeof obj !== 'object' || typeof nameProperty !== 'string') {
    return false;
  }

  // whether nameProperty is part property of obj
  return Object.prototype.hasOwnProperty.call(obj, nameProperty);
}

// returning only value in object
// when not found keys that provide value, return false
/**
 *
 * WARNING: please minimize usage this function, cause repoduce unreliable
 *
 */
function filteringExistedValue(
  obj,
  discard_keys = [],
  isAllowFalseValue = false,
  custom_discard_value = [],
) {
  if (typeof obj !== 'object') {
    return obj;
  }

  const resultObj = {};

  const objKeys = Object.keys(obj);

  for (let key of objKeys) {
    const valueTarget = obj[key];

    // non empty and non boolean data
    // not include from discard keys
    // not include from custom value
    if (
      typeof valueTarget !== 'boolean' &&
      // only not included from discard value
      valueTarget &&
      !custom_discard_value.includes(valueTarget) &&
      !discard_keys.includes(key)
    ) {
      // checking empty array of value
      if (Array.isArray(valueTarget) && !valueTarget.length) continue;

      resultObj[key] = valueTarget;
    } else if (
      typeof valueTarget !== 'boolean' &&
      !isEmpty(custom_discard_value) &&
      !custom_discard_value.includes(valueTarget)
    ) {
      // checking empty array of value
      if (Array.isArray(valueTarget) && !valueTarget.length) continue;

      resultObj[key] = valueTarget;
    }

    // feature for allow false on value
    if (typeof valueTarget === 'boolean' && isAllowFalseValue && !discard_keys.includes(key)) {
      // checking empty array of value
      if (Array.isArray(valueTarget) && !valueTarget.length) continue;

      resultObj[key] = valueTarget;
    }
  }

  if (!Object.keys(resultObj).length) return false;

  return resultObj;
}

/**
 *
 * usage filtering only existed values
 *
 * @param { object  } param0        object as params function
 *
 * @param   { object }  obj                     object target
 * @param   { array }   nonExistedValues            sequence of non existed values
 *
 * @returns
 */
function filteringExistedValueV2({
  obj,
  allowKeys = [],
  convert2booleanArray = false,
  nonExistedValues = [null, undefined, '', 0],
}) {
  if (isEmpty(obj)) return obj;

  if (!isEmpty(allowKeys)) obj = pick(obj, allowKeys);

  const filteredObj = Object.keys(obj).reduce((res, curr) => {
    const currVal = obj[curr];

    if (nonExistedValues.includes(currVal)) return res;

    return {
      ...res,
      [curr]: currVal,
    };
  }, {});

  if (!convert2booleanArray) return filteredObj;

  return Object.keys(obj).map((keyname) => keyname in filterKeyObj);
}

function filteringExistedValueWithAllowKeys(
  obj,
  discardKeys = [],
  allowKeys = [],
  isAllowBoolean = false,
  customDiscardValue = [],
) {
  if (typeof obj !== 'object') {
    return obj;
  }

  const objWithAllowKeys = filterKeyObj(obj, discardKeys, allowKeys);
  const objWithRemovingExistedValue = filteringExistedValue(
    obj,
    discardKeys,
    isAllowBoolean,
    customDiscardValue,
  );

  if (!objWithRemovingExistedValue) {
    return objWithRemovingExistedValue;
  }

  return {
    ...objWithRemovingExistedValue,
    ...objWithAllowKeys,
  };
}

// returning only value in object
// when not found keys that provide value, and otherwise
function filterKeyObj(obj, discard_keys = [], allow_keys = []) {
  if (typeof obj !== 'object' || !obj) {
    return obj;
  }

  const resultObj = {};

  const objKeys = Object.keys(obj);

  for (let key of objKeys) {
    if (!discard_keys.includes(key) && discard_keys.length) {
      resultObj[key] = obj[key];
    }

    if (allow_keys.includes(key) && allow_keys.length) {
      resultObj[key] = obj[key];
    }
  }

  const resultObjKeys = Object.keys(resultObj);

  if (!resultObjKeys.length) {
    return false;
  }

  return resultObj;
}

// @param: obj ,that include all object data for change value data
// @param: keyChanges, type string or array
// @param: funcChanges, type function, or array include function for change data
// when keyChanges is greater than funcChanges and all array, funcChanges is used only first index
// when keyChanges is same amount with funcChanges and all array, each key with corresponding funcChanges
// when keyChanges is string and funcChanges, key of object is change value
function changeFormatValue(obj, keyChanges, funcChanges) {
  if (typeof obj !== 'object') {
    return obj;
  }

  const keysObj = Object.keys(obj);

  if (Array.isArray(funcChanges)) {
    if (funcChanges.length !== keyChanges.length && funcChanges.length !== 1) {
      return obj;
    }

    for (let keyChange of keyChanges) {
      if (!keysObj.includes(keyChange)) {
        continue;
      }

      const indexKeyChange = keyChanges.indexOf(keyChange);
      const indexFunc = funcChanges.length !== keyChanges.length ? 0 : indexKeyChange;

      obj = {
        ...obj,
        [keyChange]: funcChanges[indexFunc](obj[keyChange]),
      };
    }
  } else if (typeof keyChanges === 'string' && typeof funcChanges === 'function') {
    if (!keysObj.includes(keyChanges)) {
      return obj;
    }

    obj = {
      ...obj,
      [keyChanges]: funcChanges(obj[keyChanges]),
    };
  }

  return obj;
}

/**
 *
 * @param { object } obj, target object that formatting new value
 * @param { string, array } keyChanges, name key that changing value
 * @param { string } valueChange, new value that replace old value, optional, you can pass with null
 * @param { function } funcChange, function that change into new value
 * @param { any } additionalParamsFunc, passing params into function change, optional
 * @param { bool }  isCanBypassNotExistValue    determine can passing value not exist when use false
 *      @default    false
 * @returns
 *      formatted obj with different format value
 */
function changeFormatValueV2(
  obj,
  keyChanges,
  valueChange,
  funcChange,
  additionalParamsFunc = null,
  isCanBypassNotExistValue = false,
) {
  if (typeof obj !== 'object') {
    return obj;
  }

  return JSON.parse(
    JSON.stringify(obj, (key, value) => {
      if (typeof keyChanges === 'string' || Array.isArray(keyChanges)) {
        if (keyChanges === key || (Array.isArray(keyChanges) && keyChanges.includes(key))) {
          if (value || isCanBypassNotExistValue) {
            if (typeof funcChange === 'function') {
              if (!isEmpty(additionalParamsFunc) && typeof additionalParamsFunc !== 'object') {
                return funcChange(value, additionalParamsFunc);
              } else if (!isEmpty(additionalParamsFunc) && Array.isArray(additionalParamsFunc)) {
                return funcChange(value, ...additionalParamsFunc);
              }

              return funcChange(value);
            }

            if (valueChange) {
              return valueChange;
            }
          }
        }
      }

      return value;
    }),
  );
}

/**
 *
 * @param { object }    obj             object that for changes key
 * @param { function }  funcChange      function for generalized key of object
 * @returns
 *      change format all keys on object that formatting on funcChange
 */
function changeFormatKeyObj(obj, funcChange) {
  const isObject = (o) => Object.prototype.toString.apply(o) === '[object Object]';
  const isArray = (o) => Object.prototype.toString.apply(o) === '[object Array]';

  let transformedObj = isArray(obj) ? [] : {};

  for (let key in obj) {
    // replace the following with any transform function
    let transformedKey = key;

    if (typeof funcChange === 'function') {
      transformedKey = funcChange(key);
    }

    if (isObject(obj[key]) || isArray(obj[key])) {
      transformedObj[transformedKey] = changeFormatKeyObj(obj[key], funcChange);
    } else {
      transformedObj[transformedKey] = obj[key];
    }
  }

  return transformedObj;
}

/**
 *
 * usage create default object values when not existed or existed key
 * but null or undefined, not boolean type, boolean type following origin value
 *
 * @param { object } obj            target object
 * @param { object } defaultObj     default changes object
 *
 * @returns
 */
function withDefaultValue(obj, defaultObj) {
  if (isEmpty(obj)) return defaultObj;

  if (typeof obj !== 'object' || typeof defaultObj !== 'object') return obj;

  for (let keyDefaultObj of Object.keys(defaultObj)) {
    const newValue =
      typeof obj[keyDefaultObj] === 'boolean'
        ? obj[keyDefaultObj]
        : obj[keyDefaultObj] || defaultObj[keyDefaultObj];

    obj[keyDefaultObj] = newValue;
  }

  return obj;
}

// input is object that has key length greater than 0
// and returning separate of object in array
// example { 1: 'hello', 2: 'hi' }
// return [ {1: 'hello'}, { 2: 'hi' }]
function separateSingleObjInArray(obj) {
  if (typeof obj !== 'object') {
    return obj;
  }

  const keysObj = Object.keys(obj);

  if (keysObj < 1) {
    return obj;
  }

  // separate object in key and value to array
  const entriesObj = Object.entries(obj);

  let resultArrayObj = [];

  for (let entryObj of entriesObj) {
    // single entri of object
    const singleObject = Object.fromEntries([entryObj]);

    resultArrayObj.push(singleObject);
  }

  return resultArrayObj;
}

// update key name, you can remove or adding suffix
// if add change isRemove to true
function changeSuffixKey(obj, suffix = '', isRemove = true) {
  if (typeof obj !== 'object' || isEmpty(obj)) return obj;

  const getChangeKeysObj = Object.keys(obj).map((key) => {
    if (!key.startsWith(suffix) && isRemove) {
      return key;
    }

    if (!isRemove) {
      return `${suffix}${key}`;
    }

    const lengthSuffix = suffix.length;
    const newKey = key.slice(lengthSuffix);

    return newKey;
  });

  let resultEntriesObj = [];

  const getValuesObj = Object.values(obj);

  for (let i = 0; i < getChangeKeysObj.length; i++) {
    resultEntriesObj.push([getChangeKeysObj[i], getValuesObj[i]]);
  }

  return Object.fromEntries(resultEntriesObj);
}

// changing name key from into name key
// @param: obj, object container that for changing key
// @param: nameFrom, origin name key object
// @param: nameTo, new name key for changing
function replaceNameKeyPrefix(obj, nameFrom = '', nameTo = '') {
  if (typeof obj !== 'object' || !nameFrom || !nameTo) {
    return obj;
  }

  obj = changeSuffixKey(obj, nameFrom, true);
  obj = changeSuffixKey(obj, nameTo, false);

  return obj;
}

/**
 *
 * @param { object } obj object for changes key name
 * @param { function } funcChanges  function change key of obj, you need for create function that only passing single params
 * @returns
 *      object that has been changed
 */
function changeMultipleKeysObjWithFunc(obj, funcChanges) {
  if (typeof obj !== 'object' || typeof funcChanges !== 'function') return obj;

  const allObjKeys = Object.keys(obj);
  let newObjKeys = [];

  for (let singleKeyObj of allObjKeys) {
    singleKeyObj = funcChanges(singleKeyObj);

    newObjKeys = newObjKeys.concat(singleKeyObj);
  }

  let newObj = {};

  for (let i = 0; i < newObjKeys.length; i++) {
    newObj[newObjKeys[i]] = cloneDeep(obj[allObjKeys[i]]);
  }

  return newObj;
}

// @param: obj, object for generating wih id
// @param: id, identifier each object
// @param: customNameKeyID, name key that for identifier
function combineWithID(obj, id, customNameKeyID = 'id') {
  if (typeof obj !== 'object' && !id) {
    return obj;
  }

  return {
    [customNameKeyID]: id,
    ...obj,
  };
}

/**
 *
 * @param { object } obj, object that for change name key multiple
 * @param { array } nameKey, multiple name key for changes key
 * @param { array } isRemove, multiple decesion for remove in single name key, each key has isRemove individually
 * @returns
 *  object that remove or adding key in object target
 */

function changeMultipleNameKey(obj, nameKey = [], isRemove = []) {
  if (typeof obj !== 'object' || isEmpty(nameKey) || !Array.isArray(nameKey)) {
    return obj;
  }

  let newObj = cloneDeep(obj);

  for (let i = 0; i < nameKey.length; i++) {
    const singleNameKey = nameKey[i];
    const singleIsRemove = isRemove[i];

    const singleChangeObjKey = changeSuffixKey(newObj, singleNameKey, singleIsRemove);

    let allKeysNewObj = Object.keys(newObj);

    if (singleIsRemove) {
      allKeysNewObj = allKeysNewObj.filter((key) => key !== singleNameKey);
    }

    newObj = filterKeyObj(newObj, allKeysNewObj);

    if (!newObj) {
      newObj = cloneDeep(singleChangeObjKey);

      continue;
    }

    newObj = Object.assign(newObj, singleChangeObjKey);
  }

  return newObj;
}

/**
 *
 * @param { any } name value name in event
 * @param { any } value value of event data
 * @param { string } nameKeyEvent key event, such as target or currentTarget
 * @returns
 *      generate event name and value data
 */
function createEventData(name, value, nameKeyEvent = 'target') {
  return {
    [nameKeyEvent]: {
      name,
      value,
    },
  };
}

/**
 *
 * @param { object } obj object that want to encrypt value
 * @param { string } typeCrypto string indicate for encrypt or decrpt data
 * @returns
 *      object was encrypted value or decrypt value
 */
function encryptDecryptValues(obj, typeCrypto = DEFAULT_TYPE_ENCRYPT_CRYPTOGRAPHY) {
  if (typeof obj !== 'object') {
    return obj;
  }

  if (!DEFAULT_ALL_TYPES_CRYPTOGRAPHY.includes(typeCrypto)) {
    typeCrypto = DEFAULT_TYPE_ENCRYPT_CRYPTOGRAPHY;
  }

  const keys = Object.keys(obj);

  return keys.reduce((objWithEncVal, key) => {
    if (!obj[key]) {
      return objWithEncVal;
    }

    if (typeCrypto === DEFAULT_TYPE_ENCRYPT_CRYPTOGRAPHY) {
      return {
        ...objWithEncVal,
        [key]: encryptData(obj[key]),
      };
    } else if (typeCrypto === DEFAULT_TYPE_DECRYPT_CRYPTOHRAPHY) {
      return {
        ...objWithEncVal,
        [key]: decryptData(obj[key]),
      };
    }
  }, cloneDeep(obj));
}

/**
 *
 * @param { number } amount amount data of data and validation
 * @param { function } funcGenerateInitialData function for generate initial data
 * @param { function } funcGenerateInitialValidation function geenrate initial validation
 * @param { string } customDataKey customing key of return data
 * @param { string } customValidationKey customing key of return validation
 * @param { function } funcGenenerateID function that handling generate id
 * @returns
 *      function creator for generating data and validation
 */
function creatorGenerateDataNValidation(
  amount,
  funcGenerateInitialData,
  funcGenerateInitialValidation,
  customDataKey,
  customValidationKey,
  funcGenenerateID = uuidv4,
) {
  if (!amount) {
    return {
      [customDataKey]: [],
      [customValidationKey]: [],
    };
  }

  if (typeof amount !== 'number') {
    amount = Number(amount);
  }

  const usedID = arrHelp.generateArrWithFunc(amount, funcGenenerateID);

  const dataGenerated = usedID.map((id) => {
    return funcGenerateInitialData('', id);
  });

  const validationGenerated = usedID.map((id) => {
    return funcGenerateInitialValidation('', id);
  });

  return {
    [customDataKey]: dataGenerated,
    [customValidationKey]: validationGenerated,
  };
}

/**
 * this function is enhance version of above function
 * key different is new id passing first on generated function
 */
function creatorGenerateDataNValidationV2(
  amount,
  funcGenerateInitialData,
  funcGenerateInitialValidation,
  customDataKey,
  customValidationKey,
  funcGenenerateID = uuidv4,
) {
  if (!amount) {
    return {
      [customDataKey]: [],
      [customValidationKey]: [],
    };
  }

  if (typeof amount !== 'number') {
    amount = Number(amount);
  }

  const usedID = arrHelp.generateArrWithFunc(amount, funcGenenerateID);

  let dataGenerated = [],
    validationGenerated = [];

  const isGeneratedFunction =
    typeof funcGenerateInitialData === 'function' &&
    typeof funcGenerateInitialValidation === 'function';

  if (isGeneratedFunction) {
    dataGenerated = usedID.map((id) => {
      return funcGenerateInitialData(id);
    });

    validationGenerated = usedID.map((id) => {
      return funcGenerateInitialValidation(id);
    });
  }

  return {
    [customDataKey]: dataGenerated,
    [customValidationKey]: validationGenerated,
  };
}

/**
 *
 * @param { object } obj object to find key
 * @param { string } keyToFind key for find value on object
 * @returns
 *      array that contain all values from obj with key keyToFind
 */
function findAllValuesByKey(obj, keyToFind) {
  return (
    Object.entries(obj).reduce(
      (acc, [key, value]) =>
        key === keyToFind
          ? acc.concat(value)
          : typeof value === 'object' && value
          ? acc.concat(findAllValuesByKey(value, keyToFind))
          : acc,
      [],
    ) || []
  );
}

/**
 *
 * @param { string } newID initial new id
 * @param { string } additionalID  additional id can add with initial id
 * @param { object } plainDataOrValidation plain data with out id
 * @param { object } overrideDataOrValidation data that can override, usually for data that contain not static
 * @returns
 *      initial data or validation with id
 */
function creatorInitializingDataOrValidation(
  newID = '',
  additionalID = '',
  plainDataOrValidation,
  overrideDataOrValidation,
) {
  const usedID = newID || uuidv4() + additionalID;

  return {
    id: usedID,
    ...plainDataOrValidation,
    ...overrideDataOrValidation,
  };
}

/**
 *
 * @param { object }             obj                 object with minimum contained array and on array contained object that contained nameChildrenKeyObj
 * @param { array }              allowKeysObj        determine object that getting data on children
 * @param { string || array }    nameChildrenKeyObj  name children on object that contained by array
 * @param { bool }               isUseFiltering      determine that data you mapping use filtering or not, that means data that you get is exist or not
 *      @default    false
 * @example
 *      input
 *          obj: { nameKeyObj: [{ nameChildrenKeyObj: 'value' }], nameAnotherKeyObj: [{ nameChildrenKeyObj: 'a', }, { nameChildrenKeyObj: 'b', }]}
 *          allowKeysObj: ['nameKeyObj', 'nameAnotherKeyObj']
 *          nameChildrenKeyObj: 'nameChildrenKeyObj'
 *
 *      output
 *          arr: ['value', 'a', 'b']
 *
 * @returns
 *      get all values on object that contained by array children object input
 */
function mappingMultipleChildrenKeyOnArray(
  obj,
  allowKeysObj = [],
  nameChildrenKeyObj = '',
  isUseFiltering = false,
) {
  if (typeof obj !== 'object') return obj;

  const keysChildrenObj = isEmpty(allowKeysObj) ? Object.keys(obj) : allowKeysObj;

  if (typeof nameChildrenKeyObj === 'string') {
    return keysChildrenObj.reduce((resultMapping, currKeyChildren) => {
      if (isEmpty(obj[currKeyChildren])) return resultMapping;

      const currentResultMapping = arrHelp.mappingChildrenKeyOnObject(
        obj[currKeyChildren],
        nameChildrenKeyObj,
        isUseFiltering,
      );

      if (!isEmpty(currentResultMapping)) {
        return resultMapping.concat(currentResultMapping);
      }

      return resultMapping;
    }, []);
  } else if (Array.isArray(nameChildrenKeyObj)) {
    return nameChildrenKeyObj.reduce((resultMapping, currNameChildrenKeyObj) => {
      const dataResChildren = keysChildrenObj.reduce((resultChildrenMapping, currKeyChildren) => {
        if (isEmpty(obj[currKeyChildren])) return resultChildrenMapping;

        const currentResultMapping = arrHelp.mappingChildrenKeyOnObject(
          obj[currKeyChildren],
          currNameChildrenKeyObj,
          isUseFiltering,
        );

        if (!isEmpty(currentResultMapping)) {
          return resultChildrenMapping.concat(currentResultMapping);
        }

        return resultChildrenMapping;
      }, []);

      if (!isEmpty(dataResChildren)) {
        return {
          ...resultMapping,
          [currNameChildrenKeyObj]: dataResChildren,
        };
      }

      return resultMapping;
    }, {});
  }

  return obj;
}

// https://gist.github.com/ghinda/8442a57f22099bdb2e34
/**
 *
 * @param { object }    obj                 object to convert to from data
 * @param { form }      form                current form data
 * @param { string }    namespace           parent name space or name space that used for data
 * @param { boolean }   isParentArray       indicate that parent data is array or not
 * @returns
 */
const objectToFormData = (obj, form, namespace, isParentArray = false) => {
  var fd = form || new FormData();
  var formKey;

  for (var property in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + '[' + property + ']';
      } else {
        formKey = property;
      }

      if (Array.isArray(obj[property]) && !(obj[property] instanceof File)) {
        objectToFormData(obj[property], fd, property, true);
      }

      // if the property is an object, but not a File,
      // use recursivity.
      else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        if (isParentArray) {
          objectToFormData(obj[property], fd, formKey, true);
        } else {
          objectToFormData(obj[property], fd, property);
        }
      } else {
        // if it's a string or a File object
        fd.append(formKey, obj[property]);
      }
    }
  }

  return fd;
};

/**
 *
 * @param { object } param0
 *
 * single params object that contained
 * @param   { object }      obj             target object that contained changes key name on correspondObj
 * @param   { object }      correspondObj   corresponding object keyname for obj
 *      @default    {}
 *
 * @example
 *  params ->
 *      obj = { hame: 'test', fnia: 'halo' }
 *      correspondObj   = { hame: 'hayu', fnia: 'future' }
 *
 * return ->
 *      { hayu: 'test', future: 'halo' }
 *
 * @returns
 *
 *      changes key name of obj (origin object) that changes to value on corresponding object (target object) that is
 *      respectively for sames key name
 */
const changeKeyNameWithCorrespondKeyTarget = ({ obj, correspondObj = {} }) => {
  if (
    isEmpty(obj) ||
    isEmpty(correspondObj) ||
    typeof obj !== 'object' ||
    typeof correspondObj !== 'object'
  )
    return obj;

  let resultObj = {};
  const keysCorrespondObj = Object.keys(correspondObj);

  if (!isEmpty(keysCorrespondObj)) {
    resultObj = omit(obj, keysCorrespondObj);
  }

  for (let singleKeyCorrespondObj of keysCorrespondObj) {
    const originCorrespondKeyName = correspondObj[singleKeyCorrespondObj];

    resultObj = {
      ...resultObj,
      [originCorrespondKeyName]: obj[singleKeyCorrespondObj],
    };
  }

  return resultObj;
};

/**
 * 
 * @param { object } param0 
 * 
 * 
 * @param   { object }      obj                         target object
 * @param   { string }      keyName                     keyname that for subtitue changing to object, make sure not colloison with obj keyname,
 *                                                      if not override keyname
 * @param   { bool }        isOverrideOldKeyName        determine for override old key name, 
 *      @default    false
 * 
 * @param   { object }      additionalProps             set additional props for each array items result
 * 
 * 
 * @example
 *      params      { object }      assume params for call function is object, each keys included:
 
 *      obj:        { '123': { name: 'test', scholl: 'not available' }}
 *      keyName:    'id'
 * 
 *  return
 *      [{ id: '123', name: 'test', scholl: 'not available'} ]
 * 
 * 
 * overview:
 *  changes object to array that based on key name that given
 * 
 * @returns 
 */
const changeObjTypeToArrBasedKeyName = ({
  obj,
  keyName = '',
  isOverrideOldKeyName = false,
  additionalProps = {},
}) => {
  if (isEmpty(obj) || !keyName || typeof keyName !== 'string') return obj;

  const allKeysObj = Object.keys(obj);

  if (Array.isArray(obj[allKeysObj[0]]) || typeof obj[allKeysObj[0]] !== 'object') return obj;

  return allKeysObj.map((singleKeyObj) => {
    const allKeysSingleObj = Object.keys(obj[singleKeyObj]);

    const isIncludedKeyName = allKeysSingleObj.includes(keyName);

    if (isIncludedKeyName && isOverrideOldKeyName) {
      return {
        ...obj[singleKeyObj],
        [keyName]: singleKeyObj,
      };
    }

    return {
      [keyName]: singleKeyObj,
      ...obj[singleKeyObj],
      ...additionalProps,
    };
  });
};

/**
 * 
 * @param { object } param0 
 * 
 * each keys contain:
 * @param   { object }              obj                         target changes object
 * @param   { array }               multipleKeyNameTarget       target for changes keyname
 * @param   { object || array }     additionalParamsChanges     params for changing on function, is recommended required
 *      minimum required is
 *      {
 *          keyName: 'your-keyname-changes',
 *      }
 * 
 * @example
 *      params      { object }      assume params for call function is object, each keys included:
 
 *      obj:                        first: { '123': { name: 'test', scholl: 'not available' }}, second: { '123': {name: 'halloe', scholl: 'none'}}
 *      multipleKeyNameTarget:      ['first', 'second']
 *      additionalParamsChanges:    { keyName: 'id' }
 * 
 *  return
 *      { first: [{ id: '123', name: 'test', scholl: 'not available' }], second: [{ id: '123', name: 'halloe', school: 'none'}]}
 * 
 * @returns 
 */
const changeMultipleObjToArray = ({
  obj,
  multipleKeyNameTarget = [],
  additionalParamsChanges = [],
}) => {
  if (isEmpty(obj) || !Array.isArray(multipleKeyNameTarget)) return obj;

  let resultObj = {};

  if (!isEmpty(multipleKeyNameTarget) && Array.isArray(multipleKeyNameTarget)) {
    resultObj = omit(obj, multipleKeyNameTarget);
  }

  for (let singleKeyNameTarget of multipleKeyNameTarget) {
    const indexTarget = multipleKeyNameTarget.indexOf(singleKeyNameTarget);
    const objTarget = obj[singleKeyNameTarget];

    let selectedAdditionalParamsChanges = {};

    if (
      !isEmpty(additionalParamsChanges) &&
      Array.isArray(additionalParamsChanges) &&
      !isEmpty(additionalParamsChanges[indexTarget])
    ) {
      selectedAdditionalParamsChanges = additionalParamsChanges[indexTarget];
    } else if (typeof additionalParamsChanges === 'object') {
      selectedAdditionalParamsChanges = additionalParamsChanges;
    } else if (
      !isEmpty(additionalParamsChanges) &&
      Array.isArray(additionalParamsChanges) &&
      isEmpty(additionalParamsChanges[indexTarget])
    ) {
      selectedAdditionalParamsChanges = additionalParamsChanges[0];
    }

    const resultObjTarget = changeObjTypeToArrBasedKeyName({
      obj: objTarget,
      ...selectedAdditionalParamsChanges,
    });

    resultObj = {
      ...resultObj,
      [singleKeyNameTarget]: resultObjTarget,
    };
  }

  return resultObj;
};

/**
 *
 * @param { object } param0
 *
 * each key includec for param
 * @param   { object }                  obj                                 object target
 * @param   { array }                   keyNamesTarget                      key names of target that instance from object target
 * @param   { array || string }         keyNameSumValue                     all keynames of object that contained by target keyname, each keynames is corresponding by index for each key names target
 * @param   { array || string }         keyNamesResult                      all keynames of result after calculation, corresponding by index for each key names target, or you can passing on subtutute key name result, for prefix
 * @param   { string }                  subtituteKeyNameSumValue            using this for getting around of calculation key name, or this is default when not found of keys name sum value
 * @param   { string }                  subtituteKeyNameResult              prefixed calculation for keyname target after calculation
 *
 *
 * @example
 *
 * obj      {test1: [{calculation: '1', id: 2,}, {calculation: 3, id: 1}], test2: [{amount: 1, id: 1}, {amount: 2, id: 2}]}
 * keyNamesTarget       ['test1']
 * keyNameSumValue      ['calculation']
 * keyNamesResult       ['result-test1']
 *
 * output:
 *  {test1: ...lasttest1, 'result-test1': 4, test2: ...lasttest2}
 *
 *
 * @returns
 */
const sumValueObjBasedKeyNames = ({
  obj,
  keyNamesTarget = [],
  keyNameSumValue = [],
  keyNamesResult = [],
  subtituteKeyNameSumValue = 'amount',
  subtituteKeyNameResult = 'result-',
}) => {
  if (
    isEmpty(obj) ||
    isEmpty(keyNamesTarget) ||
    typeof obj !== 'object' ||
    !Array.isArray(keyNamesTarget)
  )
    return obj;

  let resultObj = cloneDeep(obj);

  for (let indexKeyName = 0; indexKeyName < keyNamesTarget.length; indexKeyName++) {
    const keyNameTarget = keyNamesTarget[indexKeyName];
    const arrTarget = obj[keyNameTarget];

    const keyNameResult = keyNamesResult[indexKeyName] || subtituteKeyNameResult + keyNameTarget;

    if (isEmpty(arrTarget) || !Array.isArray(arrTarget)) {
      resultObj = {
        ...resultObj,
        [keyNameResult]: 0,
      };
    }

    let keyNameMappingValue =
      typeof keyNameSumValue !== 'string' ? keyNameSumValue[indexKeyName] : keyNameSumValue;

    if (
      !keyNameMappingValue &&
      !isEmpty(keyNameSumValue) &&
      Array.isArray(keyNameSumValue) &&
      !keyNameSumValue[indexKeyName]
    ) {
      keyNameMappingValue = keyNameSumValue[0];
    } else if (!keyNameMappingValue && typeof keyNameSumValue === 'string') {
      keyNameMappingValue = keyNameSumValue;
    } else if (!keyNameMappingValue) keyNameMappingValue = subtituteKeyNameSumValue;

    const mappingSumValue = arrHelp.mappingChildrenKeyOnObject(arrTarget, keyNameMappingValue);

    const resultSumValue = arrHelp.sumValueArrV2(mappingSumValue);

    resultObj = {
      ...resultObj,
      [keyNameResult]: resultSumValue,
    };
  }

  return resultObj;
};

/**
 *
 * @param { object } param0
 *
 * each key includec for param
 * @param   { object }                  obj                                 object target
 * @param   { array }                   keyNamesTarget                      key names of target that instance from object target
 * @param   { array || string }         keyNamesSumValue                    all keynames of object that contained by target keyname, each keynames is corresponding by index for each key names target
 * @param   { array || string }         keyNamesResult                      all keynames of result after calculation, corresponding by index for each key names target, or you can passing on subtutute key name result, for prefix
 * @param   { string }                  subtituteKeyNameSumValue            using this for getting around of calculation key name, or this is default when not found of keys name sum value
 * @param   { string }                  subtituteKeyNameResult              prefixed calculation for keyname target after calculation
 *
 *
 * @example
 *
 * obj      {test1: [{calculation: '1', id: 2,}, {calculation: 3, id: 1}], test2: [{calculation: 1, id: 1}, {calculation: 2, id: 2}]}
 * keyNamesTarget       ['test1', 'test2']
 * keyNamesSumValue     ['calculation']
 * keyNamesResult       ['result-calculation']
 *
 * output:
 *  {test1: ...lasttest1, 'result-calculation': 7, test2: ...lasttest2}
 *
 *
 * key idea:
 *  summing value on items array that contained on object item
 *
 *  where data structure of obj is
 *      {
 *          data: [{
 *              keynameSumValue: value,
 *          }]
 *      }
 *
 *      above structure data is summing based on keyname parent object 'data' and summing value by
 *          items of parent keyname that represent of array,
 *          and in array items must contained 'keynameSumValue'
 *
 * @returns
 */
const sumValueObjBasedKeyNamesV2 = ({
  obj,
  keyNamesTarget = [],
  keyNamesSumValue = [],
  keyNamesResult = [],
  subtituteKeyNameSumValue = 'amount',
  subtituteKeyNameResult = 'result-',
}) => {
  if (
    isEmpty(obj) ||
    isEmpty(keyNamesTarget) ||
    typeof obj !== 'object' ||
    !Array.isArray(keyNamesTarget)
  )
    return obj;

  let resultObj = cloneDeep(obj);

  for (let keyNameTarget of keyNamesTarget) {
    const indexKeyName = keyNamesTarget.indexOf(keyNameTarget);
    const arrTarget = obj[keyNameTarget];

    let keyNameResult = keyNamesResult[indexKeyName] || subtituteKeyNameResult + keyNameTarget;

    if (isEmpty(arrTarget) || !Array.isArray(arrTarget)) {
      resultObj = {
        ...resultObj,
        [keyNameResult]: resultObj[keyNameResult] || 0,
      };
    }

    // get keyname of values
    const keyNameMappingValue =
      typeof keyNamesSumValue !== 'string' && Array.isArray(keyNamesSumValue)
        ? keyNamesSumValue
        : [keyNamesSumValue];

    for (let keyNameValue of keyNameMappingValue) {
      const indexOfKeyNameValue = keyNameMappingValue.indexOf(keyNameValue);

      keyNameResult = keyNamesResult[indexOfKeyNameValue];
      if (!keyNameResult) keyNameResult = subtituteKeyNameSumValue;

      const mappingSumValue = arrHelp.mappingChildrenKeyOnObject(arrTarget, keyNameValue);

      let resultSumValue = arrHelp.sumValueArrV2(mappingSumValue);
      if (keyNameResult in resultObj) {
        resultSumValue += resultObj[keyNameResult];
      }

      resultObj = {
        ...resultObj,
        [keyNameResult]: resultSumValue,
      };
    }
  }

  return resultObj;
};

/**
 *
 * @param { object } param0         object keys that makes for params
 *
 * @param   { object }      obj                             grouping object target
 * @param   { object }      correspondObjWithKeyNames       correspoding each on keynames on target with target from value object
 *  data structure
 *      {
 *          [key]: [value],
 *      }
 *
 *   type data value is array or string, if string you only getting one, and multiple when value is array data type
 *   value is key name that came from object
 *      make sure that value from key name is calculation number
 *
 * @returns
 */
const sumValueObjBasedCorrespondKeyNames = ({ obj = {}, correspondObjWithKeyNames = {} }) => {
  if (
    isEmpty(obj) ||
    typeof obj !== 'object' ||
    isEmpty(correspondObjWithKeyNames) ||
    typeof correspondObjWithKeyNames !== 'object'
  )
    return obj;

  const keysCorrespondObj = Object.keys(correspondObjWithKeyNames);

  let resultSumObj = cloneDeep(obj);

  for (let singleKeyCorrespondObj of keysCorrespondObj) {
    let childrenValueTarget = correspondObjWithKeyNames[singleKeyCorrespondObj];

    if (
      !Array.isArray(childrenValueTarget) &&
      typeof childrenValueTarget !== 'string' &&
      typeof childrenValueTarget !== 'number'
    )
      continue;

    if (typeof childrenValueTarget === 'string' || typeof childrenValueTarget === 'number')
      childrenValueTarget = [childrenValueTarget];

    let selectedValueTarget = Object.values(pick(obj, childrenValueTarget));
    selectedValueTarget = arrHelp.sumValueArrV2(selectedValueTarget);

    if (Number.isNaN(Number(selectedValueTarget))) continue;

    resultSumObj = {
      ...resultSumObj,
      [singleKeyCorrespondObj]: selectedValueTarget,
    };
  }

  return resultSumObj;
};

/**
 *
 * @param { object } param0         object keys that makes for params
 *
 * @param   { object }      obj                             grouping object target
 * @param   { object }      correspondObjWithKeyNames       correspoding each on keynames on target with target from value object
 * @param   { array }       useRemainderKeyNameNotUsed      determine when using remainder for keyname that not used
 * @param   { array }       remainderKeyNameNotUsed         remainder for key name that carry again for object group
 *      @default        []
 *      special character       '%*'                        usage for getting all keynames but not in correspondObjWithKeyNames
 *
 *  data structure
 *      {
 *          [key]: [value]
 *      }
 *   type data value is array or string, if string you only getting one, and multiple when value is array data type
 *
 * @returns
 */
const groupingObjByCorrespondingKeyNames = ({
  obj,
  correspondObjWithKeyNames = {},
  useValuesOnly = true,
  useRemainderKeyNameNotUsed = false,
  remainderKeyNameNotUsed = [],
}) => {
  if (typeof obj !== 'object' || typeof correspondObjWithKeyNames !== 'object') return obj;

  const keysCorrespondObjWithKeyNames = Object.keys(correspondObjWithKeyNames);

  let resultGroupingObj = {};

  for (let keyCorrespondObj of keysCorrespondObjWithKeyNames) {
    let childrenGroupingObj = correspondObjWithKeyNames[keyCorrespondObj];

    if (!Array.isArray(childrenGroupingObj) && typeof childrenGroupingObj !== 'string') continue;

    if (typeof childrenGroupingObj === 'string') childrenGroupingObj = [childrenGroupingObj];

    let groupingObjTarget = pick(obj, childrenGroupingObj);
    if (useValuesOnly) {
      groupingObjTarget = concat(...Object.values(groupingObjTarget));
    }

    resultGroupingObj = {
      ...resultGroupingObj,
      [keyCorrespondObj]: groupingObjTarget,
    };
  }

  if (
    useRemainderKeyNameNotUsed &&
    (Array.isArray(remainderKeyNameNotUsed) || typeof remainderKeyNameNotUsed === 'string')
  ) {
    if (typeof remainderKeyNameNotUsed === 'string' && remainderKeyNameNotUsed === '%*') {
      remainderKeyNameNotUsed = Object.keys(obj).filter(
        (key) => !keysCorrespondObjWithKeyNames.includes(key),
      );
    }

    if (typeof remainderKeyNameNotUsed === 'string')
      remainderKeyNameNotUsed = [remainderKeyNameNotUsed];

    const remainderKeyNameNotUsedFromObj = pick(obj, remainderKeyNameNotUsed);

    if (!isEmpty(remainderKeyNameNotUsedFromObj)) {
      resultGroupingObj = {
        ...resultGroupingObj,
        ...remainderKeyNameNotUsedFromObj,
      };
    }
  }

  return resultGroupingObj;
};

/**
 *
 * @param { object }    obj                             object target
 * @param { array }     configurationKeyNamesNsign      configuration array that for getting value object from key name anad sign of calculation
 *      data structure
 *          [{ 'childrenKeyName': 'name', 'sign': ['+' | '-' ]
 *
 * @param { string }    childrenKeyName                 key name for mapping children of key name value
 * @param { string }    signKeyName                     key name for sign key name value, sign value is '-' or '+'
 * @returns
 *      calculation value on object target where only on keyname configuration and based on sign that change to negative '-'
 *
 */
const calculateObjValueBasedKeyNamesNsign = (
  obj,
  configurationKeyNamesNsign,
  childrenKeyName = 'name',
  signKeyName = 'sign',
) => {
  if (
    typeof obj !== 'object' ||
    (typeof configurationKeyNamesNsign !== 'object' && !Array.isArray(configurationKeyNamesNsign))
  )
    return 0;

  return configurationKeyNamesNsign.reduce((resultCalculation, currentSingleConfiguration) => {
    const currentSign = currentSingleConfiguration[signKeyName];
    const currentChildrenKeyName = currentSingleConfiguration[childrenKeyName];

    let currentValue = obj[currentChildrenKeyName];

    if (Number.isNaN(Number(currentValue))) return resultCalculation;
    else if (!Number.isNaN(Number(currentValue))) currentValue = Number(currentValue);

    if (currentSign === '-') return resultCalculation + Math.abs(currentValue) * -1;
    if (currentSign === '+') return resultCalculation + currentValue;
    if (currentSign === '*') return resultCalculation * currentValue;

    return resultCalculation;
  }, 0);
};

/**
 *
 * @param           { object }      param0
 * @param           { object }      obj                             target object
 * @param           { array }       parentKeynames                  target key name that on top object
 *          @default            []
 *
 * @param           { string }      separateSymbol                  represent symbol that split keyname
 *          @default            '_'
 *
 * @param           { string }      templateNewCombineKeyname       template for new keyname (parent and children)
 *                                                                  you need passing atleast number 0, 1, 2 on template
 *                                                                  0 is from parent keyname, 1 is separator, and 2 is children keyname
 *          @default            '{0}{1}{2}'
 *
 * @returns
 *          new keyname that inherit keyname from parent to children
 *
 *      example
 *        -input:
 *          obj: {id: 1, values: {hame: 'hayu', fnia: 'todo'}, school: {target: 'none', amount: 0}}
 *          parentKeynames: ['values']
 *
 *      return
 *        - output:
 *              {id: 1, values_hame: 'hayu', values_fnia: 'todo', school: {target: 'none', amount: 0}}
 *
 */
const inheritKeynameFromParentToChildren = ({
  obj,
  parentKeynames = [],
  separateSymbol = '_',
  templateNewCombineKeyname = '{0}{1}{2}',
}) => {
  if (isEmpty(obj)) return obj;

  let resultObj = omit(obj, parentKeynames);

  const candidateParentObjEntries = Object.entries(pick(obj, parentKeynames));

  for (let parentObjEntry of candidateParentObjEntries) {
    const keyParent = parentObjEntry[0];
    const valueParent = parentObjEntry[1];

    if (typeof valueParent !== 'object') {
      resultObj = {
        ...resultObj,
        ...Object.fromEntries([parentObjEntry]),
      };

      continue;
    }

    const childrenValueObjEntries = Object.entries(valueParent);
    for (let singleChildrenValue of childrenValueObjEntries) {
      const newCombineKeyname = strHelp.templateString(templateNewCombineKeyname, [
        keyParent,
        separateSymbol,
        singleChildrenValue[0],
      ]);
      const modifiedChildrenValue = [[newCombineKeyname, singleChildrenValue[1]]];

      resultObj = {
        ...resultObj,
        ...Object.fromEntries(modifiedChildrenValue),
      };
    }
  }

  return resultObj;
};

/**
 *
 * @param { object }        param0      params for object changes with correspond values
 *
 * @param   { object }      obj                     target object
 * @param   { object }      correspondValues        values for indicate changes with correspond values
 *
 *
 * @example
 *      input params
 *          {
 *              obj: { hame: 'hayu' },
 *              correspondValues: { hayu: 'fnia' }
 *          }
 *
 *      will return
 *          { hame: 'fnia' }
 *
 * @returns
 */
const changesObjectValuesWithCorrespondValues = ({ obj, correspondValues }) => {
  if (typeof obj !== 'object' || typeof correspondValues !== 'object') return obj;

  return Object.keys(obj).reduce((resultChangesValues, currKeyObj) => {
    const valueObj = resultChangesValues[currKeyObj];

    if (!(valueObj in correspondValues)) return resultChangesValues;

    const changesValue = correspondValues[valueObj];

    return {
      ...resultChangesValues,
      [currKeyObj]: changesValue,
    };
  }, obj);
};

/**
 * function for getting array on depth of object
 *
 * @param { object } param0
 *
 * @param   { object } obj      object target that search for array
 *
 * @returns
 */
const getArrayInDepthObject = ({ obj }) => {
  if (isEmpty(obj)) return [];
  if (Array.isArray(obj)) return obj;

  return Object.keys(obj).reduce((allArrays, currentKey) => {
    return allArrays.concat(
      getArrayInDepthObject({
        obj: obj[currentKey],
      }),
    );
  }, []);
};

/**
 *
 * usage for getting children object item,
 * that get by keyname on string
 *
 * @param { object } param0     passing params as object
 *
 * @param   { object }      obj         target object
 * @param   { string }      keyname     keyname accessing for object
 * @param   { string }      separator   separator for each keyname
 *
 * @returns
 *
 *      children object item that accessing by keyname string*
 *
 */
const getChildrenOfObjItemByKeynameStr = ({ obj, keyname, separator = '.' }) => {
  if (!separator || isEmpty(obj) || typeof obj !== 'object' || Array.isArray(obj)) return obj;

  const splittedKeyname = typeof keyname === 'string' && keyname.split(separator);

  if (isEmpty(splittedKeyname)) return obj;

  return splittedKeyname.reduce((lastChildren, currentKeyname) => {
    if (lastChildren === undefined) return lastChildren;

    return lastChildren[currentKeyname];
  }, obj);
};

/**
 *
 * usage for getting children object item, which usage default
 * when getting undefined or failed getting children
 *
 * @param       { object }  param0  params function as object
 *
 * @param       { object }  obj                 object target
 * @param       { string }  keynameTarget       string keyname target
 * @param       { number }  alternativeIndex    index number as alternative whenn not existed value search
 *
 * @returns
 */
const getDefaultChildrenObjItem = ({
  obj,
  keynameTarget,
  alternativeIndex = 0,
  alternativeKeyname = 'default',
  isIndexFirst = true,
} = {}) => {
  if (isEmpty(obj) || typeof obj !== 'object') return obj;

  const keysOnObj = Object.keys(obj);

  let childrenItemTarget = obj[keynameTarget];
  if (childrenItemTarget === undefined) {
    let priority = ['index', 'keyname'];

    if (!isIndexFirst) priority = priority.reverse();

    childrenItemTarget = priority.reduce((res, item) => {
      if (res !== undefined) return res;

      let keyname = alternativeKeyname;
      if (item === 'index') keyname = keysOnObj[alternativeIndex];

      return obj[keyname];
    }, childrenItemTarget);
  }

  return childrenItemTarget;
};

/**
 *
 * usage reduce values object that only mapping by keyname with original keys
 *
 * @param { object } param0             params function by object
 *
 * @param   { object }      obj                     object target
 * @param   { string }      keynameTarget           targetting keyname that get map
 *
 * @returns
 */
const reduceObjValuesByKeyname = ({ obj, keynameTarget = '' }) => {
  if (isEmpty(obj) || !keynameTarget || typeof obj !== 'object') return obj;

  return Object.keys(obj).reduce((res, currKeyname) => {
    if (!Object.keys(obj[currKeyname]).includes(keynameTarget)) return res;

    return {
      ...res,
      [currKeyname]: obj[currKeyname][keynameTarget],
    };
  }, {});
};

const objHelper = {
  isObjHasOwnProperty,
  filteringExistedValue,
  filteringExistedValueV2,
  filteringExistedValueWithAllowKeys,
  filterKeyObj,
  changeFormatValue,
  changeFormatValueV2,
  changeFormatKeyObj,
  withDefaultValue,
  separateSingleObjInArray,
  changeSuffixKey,
  replaceNameKeyPrefix,
  changeMultipleKeysObjWithFunc,
  combineWithID,
  changeMultipleNameKey,
  createEventData,
  encryptDecryptValues,
  findAllValuesByKey,
  creatorGenerateDataNValidation,
  creatorGenerateDataNValidationV2,
  creatorInitializingDataOrValidation,
  mappingMultipleChildrenKeyOnArray,
  objectToFormData,
  changeKeyNameWithCorrespondKeyTarget,
  changeObjTypeToArrBasedKeyName,
  changeMultipleObjToArray,
  sumValueObjBasedKeyNames,
  sumValueObjBasedKeyNamesV2,
  groupingObjByCorrespondingKeyNames,
  sumValueObjBasedCorrespondKeyNames,
  calculateObjValueBasedKeyNamesNsign,
  inheritKeynameFromParentToChildren,
  changesObjectValuesWithCorrespondValues,
  getArrayInDepthObject,
  getChildrenOfObjItemByKeynameStr,
  getDefaultChildrenObjItem,
  reduceObjValuesByKeyname,
};

export default objHelper;
